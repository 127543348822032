import * as helper from "@/helpers/index";

export const DOMAIN_SWITCHER_TYPES = {
  DESKTOP: "desktop",
  MOBILE: "mobile",
};

export const switchDomain = (domain) => {
  smartStorage.domain = domain.name;
  smartStorage.setItem("domainID", domain.id);
  smartStorage.plan = domain.plan;

  let redirectTo = location.origin;

  // in case of dev builds, builds are deployed at /dev_builds/branch_name
  // this code ensures that when switching domains, we don't simply reload
  // the app with the origin, but instead reload with the entire dev build URL

  if (location.href.includes("dev_build")) {
    const lastSlashIndex = location.href.lastIndexOf("/");
    redirectTo = location.href.slice(0, lastSlashIndex);
  }
  helper.hardRedirect(redirectTo);
};
