<template>
  <div class="table">
    <slot :columnWidths="columnWidths" name="header"></slot>
    <template v-for="(row, index) in rows">
      <slot :data="row" :columnWidths="columnWidths" :index="index">
        <row :key="index" :columnWidths="columnWidths" :data="row"></row>
      </slot>
    </template>
  </div>
</template>

<script>
/* How to use:
  <titan-table :rows="rows">
    <div slot="header">Header Text</div>
    <template slot-scope="{data}">
      <row :name="data.name" :age="data.age"></row>
    </template>
  </titan-table>
 */
import Row from "./TitanRow.vue";

export default {
  components: { Row },
  props: {
    rows: Array,
    columnWidths: Array,
  },
};
</script>
<style lang="scss" scoped>
.table {
  @include border();
  @include borderRadius();
  display: flex;
  line-height: 1.2;
  flex-direction: column;
  background: var(--tabBgColor);
  background: var(--tabBgCol);
}
</style>
