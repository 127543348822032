import { getAccountId, getLoggedInUserType, getUrl, smartStorage } from ".";
import { ADMIN_TYPE, ENTRI_SETUP_MODE } from "./const";

export const isSiteSetupMode = (setupMode) =>
  setupMode === ENTRI_SETUP_MODE.SITE;

export function getEntriSetupUrl({
  sourceHook,
  setupFor = ENTRI_SETUP_MODE.EMAIL,
  dnsSetupUrl,
  domainName,
  domainId,
} = {}) {
  // TODO: currently we are tracking accountId from local storage, need move this to store
  // problem here is account ID we get during login flows, and after login we don't get this info
  // from any API.
  const userType = getLoggedInUserType();
  const accountId = getAccountId();
  const isAccAdmin = userType === ADMIN_TYPE.ACCOUNT;

  if (!dnsSetupUrl || !domainName) {
    return "";
  }

  const otherParams = isSiteSetupMode(setupFor)
    ? {
        domainId,
        ...(isAccAdmin ? { accountId } : {}),
      }
    : {};

  return getUrl(dnsSetupUrl, {
    setupFor,
    domain: domainName,
    token: smartStorage.getItem("authToken"),
    sourceHook: sourceHook || "controlpanel",
    ...otherParams,
  });
}
