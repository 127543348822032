<template>
  <div class="input-wrapper">
    <label
      :class="[
        'switch',
        {
          marginFromLeft: view.showModalAsPage,
        },
      ]"
    >
      <input
        type="checkbox"
        v-model="$parent.autoGenerate"
        @click="generatePassword"
        autocomplete="new-password"
      />
      <span class="slider round"></span>
    </label>
    <span>{{ $t("utility.generatePassHeading") }}</span>
  </div>
</template>

<script>
import { getRandomGeneratedPassword } from "@/helpers";
import { AUTOGENERATED_PASSWORD_LENGTH } from "@/helpers/const";

export default {
  name: "GeneratePassword",
  props: {
    view: Object,
  },
  methods: {
    generatePassword(e) {
      if (e.target.checked) {
        this.$parent.autoGenerate = true;
        this.$parent.password = getRandomGeneratedPassword(
          AUTOGENERATED_PASSWORD_LENGTH
        );
      } else {
        this.$parent.autoGenerate = false;
        this.$parent.password = "";
      }
    },
  },
};
</script>
