<template>
  <div class="counter">
    <b-button-group>
      <b-button :disabled="value <= minCount" @click="updateCount(-1)"
        >-</b-button
      >
      <input
        type="number"
        :max="maxCount"
        v-model="value"
        @change="updateCount"
      />
      <b-button :disabled="value >= maxCount" @click="updateCount(1)"
        >+</b-button
      >
    </b-button-group>
  </div>
</template>

<script>
import { BButton, BButtonGroup } from "bootstrap-vue";

export default {
  name: "Counter",
  components: {
    BButton,
    BButtonGroup,
  },
  props: {
    initialValue: {
      type: Number,
    },
    maxCount: {
      type: Number,
    },
    minCount: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      value: this.initialValue || this.minCount,
    };
  },
  methods: {
    updateCount(ve) {
      let v = this.minCount;

      // handle input field change
      if (typeof ve === "object") {
        v = ve.target.value;
        if (v < this.minCount) {
          v = this.minCount;
        } else if (this.maxCount && v > this.maxCount) {
          v = this.maxCount;
        }
      } else {
        // handle button click
        v = parseInt(this.value, 10);
        v += ve;
        if (v < this.minCount || (this.maxCount && v > this.maxCount)) {
          return false;
        }
      }

      this.value = v;
      this.$emit("onChange", this.value);
    },
    resetCount() {
      this.value = this.initialValue || this.minCount;
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  button {
    height: 40px;
    width: 40px;
    padding: 0;
    outline: none !important;
    box-shadow: none !important;
    background: var(--tabBgCol);
    color: var(--primaryTextCol);
    border-color: var(--lineSepaCol);
    font-size: 20px;

    &:disabled {
      color: var(--lineSepaCol);
      cursor: not-allowed;
    }
  }

  .counter {
    display: inline-block;
    vertical-align: text-bottom;
  }

  input {
    height: 40px;
    width: var(--counterWidth);
    display: inline-block;
    outline: none;
    text-align: center;
    color: var(--primaryTextCol);
    border: none;
    border-top: solid 1px var(--lineSepaCol);
    border-bottom: solid 1px var(--lineSepaCol);
    font-size: 16px;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
</style>
