import Vue from "vue";
import Vuex from "vuex";
import modalModule from "./modules/modal.store.js";
import mailModule from "./modules/mail.store.js";
import siteModule from "./modules/site.store.js";
import domainModule from "./modules/domain.store.js";
import {
  getActiveProducts,
  getProductsFromCurrentPlan,
} from "@/helpers/product.js";

Vue.use(Vuex);

const getDefaultState = () => ({
  /** Configuration for the payment */
  externalPayment: null,
  theme: "defaultTheme",
  /** Configuration for default vs iframe(widget) views */
  view: {},
  locale: "en",
  partnerId: null,
  partnerName: null,
  isAppBannerVisible: false,
  currentDomainMailboxDetails: [],
  dkimUnverifiedKey: {},
  dkimVerifiedKeys: [],
  /** All products for which trials are enabled. */
  trials: {},

  brandDetails: {},
  currentPlanDetails: {},

  purchaseAndBillingDetails: {},
  domainList: [],
  showDomainAdminPopup: false,
  partnerInfo: {},
  paymentCardType: null,
  entriSetupPossible: false,
  entriDnsProvider: null,
  showAutoRenew: false,

  savedCard: null,
});

export default new Vuex.Store({
  modules: {
    modal: modalModule,
    mail: mailModule,
    site: siteModule,
    domain: domainModule,
  },
  /* Global State */
  state: () => getDefaultState(),
  /* Global Getters */
  getters: {
    externalPayment: (state) => state.externalPayment,
    theme: (state) => state.theme,
    trials: (state) => state.trials,
    view: (state) => state.view,
    locale: (state) => state.locale,
    paymentRoutingInfo({ externalPayment, trials, view }, getters) {
      return {
        externalPayment,
        hideBilling: getters.hideBilling,
        trials,
        view,
      };
    },
    hideBilling(state) {
      return (
        state.partnerInfo?.flags?.hideBillingPageOnPaymentViaPartner || false
      );
    },
    bundleId(state, getters) {
      return (
        getters["domain/currentDomainDetails"]?.bundleId ||
        getters["domain/currentDomainDetails"]?.id
      );
    },
    products(state, getters) {
      return getProductsFromCurrentPlan(getters.currentPlanDetails);
    },
    activeProducts(state, getters) {
      return getActiveProducts(getters.currentPlanDetails);
    },
    isAppBannerVisible: (state) => state.isAppBannerVisible,
    partnerId: (state) => state.partnerId,
    partnerName: (state) => state.partnerName,
    flags: (state) => state.partnerInfo?.flags,
    brandDetails: (state) => state.brandDetails,
    currentPlanDetails: (state) => state.currentPlanDetails,
    currency: (state, getters) => getters.currentPlanDetails?.currency,
    currentDomainMailboxDetails: (state) => state.currentDomainMailboxDetails,
    purchaseAndBillingDetails: (state) => state.purchaseAndBillingDetails,
    domainList: (state) => state.domainList,
    showDomainAdminPopup: (state) => state.showDomainAdminPopup,
    dkimUnverifiedKey: (state) => state.dkimUnverifiedKey,
    dkimVerifiedKeys: (state) => state.dkimVerifiedKeys,
    partnerInfo: (state) => state.partnerInfo,
    savedCard: (state) => state.savedCard,
    paymentCardType: (state, getters) =>
      state.paymentCardType || getters.savedCard?.funding,
    entriSetupPossible: (state) => state.entriSetupPossible,
    entriDnsProvider: (state) => state.entriDnsProvider,
    showAutoRenew: (state) => state.showAutoRenew,
  },
  /**
   * Global Actions:
   * Why are there so many actions for each property?
   * Each action can dispatch more actions, or just commit a mutation.
   * We could directly commit these into the store from the component too.
   * However, for cases where some value is dependent on another value, it makes sense to dispatch.
   */
  actions: {
    externalPayment({ commit }, payload) {
      commit("externalPayment", payload);
    },
    hideBilling({ commit }, payload) {
      commit("hideBilling", payload);
    },
    theme({ commit }, payload) {
      commit("theme", payload);
    },
    trials({ commit }, payload) {
      commit("trials", payload);
    },
    view({ commit }, payload) {
      commit("view", payload);
    },
    locale({ commit }, payload) {
      commit("locale", payload);
    },
    isAppBannerVisible({ commit }, payload) {
      commit("isAppBannerVisible", payload);
    },
    partnerId({ commit }, payload) {
      commit("partnerId", payload);
    },
    partnerName({ commit }, payload) {
      commit("partnerName", payload);
    },
    brandDetails({ commit }, payload) {
      commit("brandDetails", payload);
    },
    currentPlanDetails({ commit }, payload) {
      commit("currentPlanDetails", payload);
    },
    currentDomainMailboxDetails({ commit }, payload) {
      commit("currentDomainMailboxDetails", payload);
    },
    purchaseAndBillingDetails({ commit }, payload) {
      commit("purchaseAndBillingDetails", payload);
    },
    domainList({ commit }, payload) {
      commit("domainList", payload);
    },
    showDomainAdminPopup({ commit }, payload) {
      commit("showDomainAdminPopup", payload);
    },
    // action to handle unverified dkim key
    dkimUnverifiedKey({ commit }, payload) {
      commit("dkimUnverifiedKey", payload);
    },
    // action to handle verified dkim keys
    dkimVerifiedKeys({ commit }, payload) {
      commit(
        "dkimVerifiedKeys",
        // as we want to keep enabled key in top
        Array.isArray(payload)
          ? payload.sort((a, b) => (a.isEnabled && !b.isEnabled ? -1 : 0))
          : payload
      );
    },
    // action to add unverified key to verified array
    dkimAddToVerifiedKeyList({ commit, state }) {
      commit("dkimVerifiedKeys", [
        { ...state.dkimUnverifiedKey, isEnabled: true },
        ...state.dkimVerifiedKeys.map((d) => ({ ...d, isEnabled: false })),
      ]);
      commit("dkimUnverifiedKey", null);
    },
    // action to enable provided key
    // we also mark other keys as isEnable: false
    enableDkimKey({ dispatch, state }, payload) {
      dispatch(
        "dkimVerifiedKeys",
        state.dkimVerifiedKeys.map((k) => ({
          ...k,
          isEnabled: k.dkimId === payload,
        }))
      );
    },
    // action to delete dkim key from store
    deleteDkimKey({ dispatch, state }, payload) {
      dispatch(
        "dkimVerifiedKeys",
        state.dkimVerifiedKeys.filter((k) => k.dkimId !== payload)
      );
    },
    partnerInfo({ commit }, payload) {
      commit("partnerInfo", payload);
    },
    savedCard({ commit }, payload) {
      commit("savedCard", payload);
    },
    paymentCardType({ commit }, payload) {
      // FIXME: ideally shouldn't be needing this action.
      // see if we can safely remove
      commit("paymentCardType", payload);
    },
    entriSetupPossible({ commit }, payload) {
      commit("entriSetupPossible", payload);
    },
    entriDnsProvider({ commit }, payload) {
      commit("entriDnsProvider", payload);
    },
    showAutoRenew({ commit }, payload) {
      commit("showAutoRenew", payload);
    },
    resetState({ commit }) {
      commit("resetState");
    },
  },
  /* Global Mutations */
  mutations: {
    externalPayment(state, payload) {
      state.externalPayment = payload;
    },
    hideBilling(state, payload) {
      state.hideBilling = payload;
    },
    theme(state, payload) {
      if (payload) {
        state.theme = payload;
      }
    },
    trials(state, payload) {
      state.trials = {
        ...state.trials,
        ...payload,
      };
    },
    locale(state, payload) {
      state.locale = payload;
    },
    view(state, payload) {
      state.view = payload;
    },
    isAppBannerVisible(state, payload) {
      state.isAppBannerVisible = payload;
    },
    partnerId(state, payload) {
      state.partnerId = payload;
    },
    partnerName(state, payload) {
      state.partnerName = payload;
    },
    brandDetails(state, payload) {
      state.brandDetails = payload;
    },
    currentPlanDetails(state, payload) {
      state.currentPlanDetails = payload;
    },
    purchaseAndBillingDetails(state, payload) {
      state.purchaseAndBillingDetails = payload;
    },
    currentDomainMailboxDetails(state, payload) {
      state.currentDomainMailboxDetails = payload;
    },
    domainList(state, payload) {
      state.domainList = payload;
    },
    showDomainAdminPopup(state, payload) {
      state.showDomainAdminPopup = payload;
    },
    dkimUnverifiedKey(state, payload) {
      state.dkimUnverifiedKey = payload;
    },
    dkimVerifiedKeys(state, payload) {
      state.dkimVerifiedKeys = payload;
    },
    partnerInfo(state, payload) {
      state.partnerInfo = payload;
    },
    savedCard(state, payload) {
      state.savedCard = payload;
    },
    paymentCardType(state, payload) {
      state.paymentCardType = payload;
    },
    entriSetupPossible(state, payload) {
      state.entriSetupPossible = payload;
    },
    entriDnsProvider(state, payload) {
      state.entriDnsProvider = payload;
    },
    showAutoRenew(state, payload) {
      state.showAutoRenew = payload;
    },
    resetState(state) {
      Object.assign(state, getDefaultState(), {
        partnerInfo: state.partnerInfo,
      });
    },
  },
});
