var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.hasFetched)?_c('Loader'):_vm._e(),(_vm.hasFetched)?_c('div',{staticClass:"billingInfo"},[_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.$t("purchaseFlow.billingSection.header"))+" ")]),_c('div',{staticClass:"domain"},[_c('div',{staticClass:"domainNameKey"},[_vm._v(" "+_vm._s(_vm.$t("purchaseFlow.billingSection.domain"))+" ")]),_c('div',{staticClass:"domainNameValue"},[_vm._v(" "+_vm._s(_vm.purchaseFlowJWTPayload.domainName)+" ")])]),_c('div',{staticClass:"plan"},[_c('div',{staticClass:"planNameKey"},[_vm._v(" "+_vm._s(_vm.$t("purchaseFlow.billingSection.plan"))+" ")]),_c('div',{staticClass:"planNameValue"},[_vm._v(_vm._s(_vm.currentPlan.displayName)+" plan")])]),_c('div',{staticClass:"billingCycle"},[_c('div',{staticClass:"billingCycleKey"},[_vm._v(" "+_vm._s(_vm.$t("purchaseFlow.billingSection.billingCycle"))+" ")]),_c('div',{staticClass:"billingCycleValue"},_vm._l((_vm.sortedBillingCycles),function(planCycle,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(planCycle != 'id'),expression:"planCycle != 'id'"}],key:index},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedPlanCycle),expression:"selectedPlanCycle"}],attrs:{"type":"radio","id":planCycle.billingCycle,"name":"planCycle"},domProps:{"value":planCycle.billingCycle,"checked":_vm.selectedPlanCycle === planCycle.billingCycle,"checked":_vm._q(_vm.selectedPlanCycle,planCycle.billingCycle)},on:{"change":function($event){_vm.selectedPlanCycle=planCycle.billingCycle}}}),_c('label',{attrs:{"for":planCycle.billingCycle}},[_vm._v(_vm._s(_vm.$t(`billingCycles.${planCycle.billingCycle}`)))])])}),0)]),_c('div',{staticClass:"price"},[_c('div',{staticClass:"priceKey"},[_vm._v(" "+_vm._s(_vm.$t("purchaseFlow.billingSection.price"))+" ")]),_c('div',{staticClass:"priceValue",domProps:{"innerHTML":_vm._s(
          _vm.$t(`purchaseFlow.accountsPerMonth`, [
            _vm.cr +
              _vm.scaleUpToTwoDecimals(
                _vm.currPlanCyclesAndCharges[_vm.selectedPlanCycle]
              ),
          ])
        )}})]),_c('div',{staticClass:"noOfAcc"},[_c('div',{staticClass:"noOfAccKey"},[_vm._v(" "+_vm._s(_vm.$t("purchaseFlow.billingSection.no_of_acc"))+" ")]),_c('div',{staticClass:"noOfAccValue"},[_c('counter',{ref:"counter",attrs:{"minCount":1,"maxCount":9999,"initialValue":+_vm.noOfAccounts},on:{"onChange":_vm.updateNoOfAccounts}})],1)]),_c('div',{staticClass:"totalAmt"},[_c('div',{staticClass:"totalAmtKey"},[_vm._v(" "+_vm._s(_vm.$t("purchaseFlow.billingSection.totalAmount"))+" ")]),_c('div',{staticClass:"totalPayableAmount price-label"},[(!_vm.isTrailPlan)?_c('b',{domProps:{"innerHTML":_vm._s(_vm.cr + (_vm.totalAmount && _vm.scaleUpToTwoDecimals(_vm.totalAmount)))}}):_c('span',[_c('b',{domProps:{"innerHTML":_vm._s(_vm.cr + '0')}}),_c('span',{staticClass:"text-line-through",domProps:{"innerHTML":_vm._s(_vm.cr + (_vm.totalAmount && _vm.scaleUpToTwoDecimals(_vm.totalAmount)))}})])])]),_c('button',{staticClass:"nextBtn",on:{"click":function($event){return _vm.goToBillingForm()}}},[_vm._v(" "+_vm._s(_vm.$t("purchaseFlow.billingSection.next"))+" ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }