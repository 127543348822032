<template>
  <div class="plan-card-container">
    <div class="plan-card">
      <h6 class="plan-card-title">
        <div v-if="hasMailSuiteProduct(products)">
          {{ $t("mail") }}
          {{ $t("billingAndUpgrade.orderSummarySection.plan") }}:
          {{ currentMailPlanDetails.planDetails.displayName }} (
          {{ mailboxesDetails.limit }}
          <span v-if="mailboxesDetails.isPlural">{{
            $t("mobile.planCard.planDetails.mailBoxes")
          }}</span>
          <span v-else>{{ $t("mobile.planCard.planDetails.mailbox") }}</span>
          )
        </div>
        <div v-if="hasSiteProduct(products)">
          {{ $t("site") }}
          {{ $t("billingAndUpgrade.orderSummarySection.plan") }}:
          {{ currentSitePlanDetails.planDetails.displayName }}
        </div>
      </h6>
      <span class="divider"></span>
      <ul class="item-list">
        <li>
          {{ $t("purchaseFlow.billingSection.billingCycle") }}:
          {{ $t("billingCycles." + currentPlanDetails.billingCycle) }}
        </li>
        <li v-if="currentPlanDetails.autoRenew">
          {{ $t("mobile.planCard.planDetails.renewsOn") }}:
          {{ formatDate(currentPlanDetails.expiresOn) }}
        </li>
        <li v-else>
          {{ $t("mobile.planCard.planDetails.expiresOn") }}:
          {{ formatDate(currentPlanDetails.expiresOn) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate, hasMailSuiteProduct, hasSiteProduct } from "../../helpers";

export default {
  methods: { hasSiteProduct, hasMailSuiteProduct },
  data() {
    return {
      formatDate,
    };
  },
  computed: {
    ...mapGetters(["currentPlanDetails", "products"]),
    ...mapGetters({
      currentDomainDetails: "domain/currentDomainDetails",
      currentMailPlanDetails: "mail/currentProductDetails",
      currentSitePlanDetails: "site/currentProductDetails",
    }),
    mailboxesDetails() {
      return {
        limit: this.currentMailPlanDetails.noOfAccounts.limit,
        isPlural: this.currentMailPlanDetails.noOfAccounts.limit > 1,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
/* TODO: replace with css variables */

.plan-card {
  padding: 12px 16px;
  border-radius: 4px;
  background-color: #474977;

  * {
    color: white;
  }

  h6 {
    div:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }

  h3 {
    margin-bottom: 12px;
  }
}

.divider {
  height: 1px;
  display: block;
  background-color: rgba(255, 255, 255, 0.3);
}

.item-list {
  list-style-type: none;
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px 0 0;
}
</style>
