var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:[
      _vm.config.showModal ? 'overlay' : 'hide',
      { 'widget-modal-background': _vm.view.showModalAsPage },
    ]}),_c('div',{class:[
      'modal-container',
      _vm.config.className,
      {
        hide: !_vm.config.showModal,
        fixed: _vm.config.keepFixed,
        leftAlignItems: _vm.view.showModalAsPage,
        removeTopPadding: _vm.view.showModalAsPage,
      },
    ]},[(_vm.view.showModalAsPage)?_c('widget-breadcrumb-nav',{attrs:{"isModal":true,"modalName":_vm.config.action || 'action'},on:{"onNavClick":_vm.hide}}):_vm._e(),(!_vm.view.showModalAsPage)?_c('img',{staticClass:"closeBtnImg",attrs:{"src":_vm.closeBtnImg},on:{"click":_vm.hide}}):_vm._e(),_c('img',{attrs:{"width":_vm.config.imgWidth || 'auto',"height":_vm.config.imgHeight || 'auto',"src":_vm.getImageUrl()}}),_c('h3',[_vm._v(_vm._s(_vm.config.modalHeading))]),(_vm.config.subHeading)?_c('span',{staticClass:"subHeading",domProps:{"innerHTML":_vm._s(_vm.config.subHeading)}}):_vm._e(),(_vm.config.btnTxt && !this.flags.hideIFrameBillingSection)?_c('span',{class:['btn'],on:{"click":function($event){return _vm.config.btnCB(_vm.hide)}}},[_vm._v(_vm._s(_vm.config.btnTxt))]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }