import { clearQueryParam } from "@/helpers";

class QueryParamStore {
  constructor() {
    this.params = {};
  }

  get(key) {
    return this.params[key];
  }

  set(key, value) {
    this.params[key] = value;
  }

  clear(key) {
    delete this.params[key];
  }

  clearParamFromUrl(key) {
    clearQueryParam(key);
  }

  setAndClear(key, value) {
    this.set(key, value);
    this.clearParamFromUrl(key);
  }
}

export default new QueryParamStore();
