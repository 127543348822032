<template>
  <div class="loader-parent-container">
    <div class="loading">
      <div class="loading-text" v-if="showLoadingTextWithLoader">
        {{ $t("widgetApp.loading") }}...
      </div>
    </div>
  </div>
</template>

<script>
import viewTypes from "../../views";
import * as helper from "../../helpers";
export default {
  name: "Loader",
  data: function () {
    return {
      showLoadingTextWithLoader:
        viewTypes[document.documentElement.getAttribute("view")] &&
        viewTypes[document.documentElement.getAttribute("view")]
          .showLoadingTextWithLoader,
    };
  },
  mounted() {
    this.showLoadingTextWithLoader =
      this.showLoadingTextWithLoader || this.$route.meta.view === "widget";
    if (helper.isAppLoadedInsideIframe())
      helper.sendPostMessageToParentWindow(helper.LOADER_DID_APPEAR, {});
  },
};
</script>

<style lang="scss">
.loading {
  @include page-loader();
  .loading-text {
    padding-top: 50px;
    display: flex;
    justify-content: center;
  }
}
</style>
