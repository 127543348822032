import {
  hasDomainProduct,
  hasMailSuiteProduct,
  hasSiteProduct,
  toEpochMillis,
} from "@/helpers";
import store from "@/store/store";

export const getProductAttrs = () => {
  const attrs = {};
  const products = store.getters.products;

  if (hasMailSuiteProduct(products)) {
    attrs.mail_order_id = +store.getters["mail/orderId"];
    attrs.mail_status = store.getters["mail/currentProductDetails"].status;
    attrs.mail_expiry = toEpochMillis(
      store.getters["mail/currentProductDetails"].expiry
    );
  }

  if (hasSiteProduct(products)) {
    attrs.site_order_id = +store.getters["site/orderId"];
    attrs.site_status = store.getters["site/currentProductDetails"].status;
    attrs.site_expiry = toEpochMillis(
      store.getters["site/currentProductDetails"].expiry
    );
  }

  if (hasDomainProduct(products)) {
    attrs.neo_domain_order_id = +store.getters["domain/orderId"];
    attrs.neo_domain_status =
      store.getters["domain/currentDomainDetails"].status;
    attrs.neo_domain_expiry = toEpochMillis(
      store.getters["domain/currentDomainDetails"].expiry
    );
  }

  return attrs;
};
