import defaultView from "./default";
export default {
  ...defaultView,
  name: "mobile",
  hideSideBar: true,
  hideAppHeader: false,
  showBackButton: true,
  hideScrollbar: false,
  home: "mobile-home",
  // For titan max addon in widget view, we use fakedoor for now
  //   addonsWithFakedoor: ["titan_max"],
  backButtonForNavigation: true,
  //   showPoweredByTitan: true,
  openInternalURLSInSameTab: true,
  hideBrandInfoInTooltip: true,
  openPaymentURLInDiffTab: true,
  showLoadingTextWithLoader: true,
  showModalAsPage: false,
  initialRoute: "mobile-home",
  showBannerOnHomePage: false,
  flags: {
    hideDNSBanner: true,
    hideDomainAdminWelcomeScreen: true,
    hideBreadcrumb: true,
  },
};
