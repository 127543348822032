<template>
  <div class="table-header-container">
    <div class="table-header" :class="{ flexStart }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    flexStart: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.table-header-container {
  padding: 0.575em 1.7em;
  background-color: var(--readOnlyTxtBgCol);
  color: var(--secondaryTextCol);
  .table-header {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    &.flexStart {
      justify-content: flex-start;
    }
  }
}
</style>
