var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn",class:[
    _vm.type,
    _vm.size,
    _vm.shape,
    {
      invert: _vm.invert,
      small: _vm.small,
      loader: _vm.loading,
      'link-button-loader': _vm.linkButtonLoader,
    },
    _vm.className,
  ],style:(_vm.computedStyles),attrs:{"disabled":_vm.disabled,"type":_vm.buttonType,"tabindex":"0"},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default",function(){return [_vm._v("Submit")]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }