<template>
  <transition name="modal-fade">
    <div class="modal-overlay">
      <div
        class="modal-body-container alt-user-email-modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="get user email"
      >
        <div class="modal-content-wrapper">
          <h3>
            {{ $t("altUserEmailModal.heading", [brandDetails.name]) }}
          </h3>
          <img src="../../assets/empty-state-alt-email.svg" />
          <p>{{ $t("altUserEmailModal.subHead1") }}</p>
          <p>
            {{ $t("altUserEmailModal.subHead2_1", [domain]) }}
            {{ $t("altUserEmailModal.subHead2_2", [brandDetails.name]) }}
          </p>
          <input
            type="text"
            v-model="email"
            :placeholder="$t('altUserEmailModal.placeholder')"
            @focus="error = ''"
            :class="{ 'has-error': !!error }"
          />
          <span class="error" v-if="error">{{ error }}</span>
          <button @click="submit" class="button">
            {{ $t("altUserEmailModal.continue") }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
import { REGX } from "../../helpers/const";
import brand from "../../helpers/brand";
import { mapGetters } from "vuex";

export default {
  name: "AltUserEmailModal",
  mixins: [clickaway],
  data() {
    const brandDetails = brand.getDetails();
    return {
      email: "",
      error: "",
      brandDetails,
    };
  },
  ...mapGetters({
    domain: "domain/domainName",
  }),
  props: {
    heading: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
    submit() {
      if (!this.email) {
        this.error = this.$t("altUserEmailModal.required");
        return false;
      }

      if (this.email && !REGX.email.test(this.email)) {
        this.error = this.$t("altUserEmailModal.invalid");
        return false;
      }

      this.http.postData(
        "panel/user/update-config",
        {
          userEmail: this.email,
          domainName: this.domain,
        },
        this.$root.headers,
        () => {
          smartStorage.removeItem("showAltUserEmail");
          this.close();
          this.medusaEvents.send(
            this.medusaEvents.createEventObj(
              {
                eventName: "customer_email_screen_filled",
                customer_email: this.email,
              },
              this.domain,
              "flock_order"
            )
          );
        },
        (err) => {
          this.error =
            (err && err.data && err.data.desc) ||
            this.$t(
              "billingAndUpgrade.addonPurchase.accountSelectSection.commonError"
            );
        }
      );
    },
  },
  created() {
    this.medusaEvents.send(
      this.medusaEvents.createEventObj(
        {
          eventName: "customer_email_screen_shown",
        },
        smartStorage.getItem("domain"),
        "flock_order"
      )
    );
  },
};
</script>
<style lang="scss">
.alt-user-email-modal {
  text-align: center;
  color: var(--primaryTextCol);
  width: 500px;
  min-width: 500px;

  h3 {
    font-size: 24px;
    font-weight: normal !important;
    text-align: center;
  }

  img {
    margin: 40px auto;
    height: 160px;
    width: 281px;
    display: inline-block;
  }

  p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;

    & + p {
      font-size: 14px;
      font-weight: normal;
      color: var(--secondaryTextCol);
      margin: 8px 0 20px;
    }
  }

  input {
    width: 100%;
    border-radius: 4px;
    border: solid 1px var(--inputBorderCol);
    background-color: var(--tabBgCol);
    display: block;
    padding: 8px 20px;
    margin: 0 auto;
    color: var(--primaryTextCol);

    &.has-error {
      border-color: var(--errorPrimaryTextCol);
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }

  .error {
    display: block;
    text-align: left;
  }

  button {
    display: inline-block;
    margin-top: 18px !important;
  }
}
</style>
