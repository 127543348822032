<template>
  <div class="page-not-found-wrapper">
    <div class="page-not-found">
      <h1>404</h1>
      <h2>Page Not Found.</h2>
      <router-link to="/">Go to Login</router-link>
    </div>
  </div>
</template>
<script>
import { sentryCaptureException } from "@/helpers/sentry";
export default {
  name: "PageNotFound",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from?.name;
    });
  },
  mounted() {
    sentryCaptureException(
      new Error("PageNotFound"),
      { logType: "routing_issue" },
      {
        prevRoute: this.prevRoute,
      }
    );
  },
};
</script>
<style lang="scss">
.page-not-found-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/app-login-bg.png") no-repeat center center;
  .page-not-found {
    width: 500px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
