<template>
  <Loader v-if="showLoader" />
</template>

<script>
import brand from "../../helpers/brand";
import * as helpers from "../../helpers";
import smartStorage from "../../helpers/smart-storage";
import Loader from "../Utility/Loader";
import { measurePagePerformance } from "@/analytics/performance/pageTimings";
import { getLastQueryParamValue } from "@/helpers/queryParameterHelper";
import { sentryCaptureException } from "@/helpers/sentry";
import authService from "@/services/auth/auth.service";

/**
 * component responsible for auto login with control panel token
 * required query params
 * - token: control panel token (SLT)
 * - domain: order domain name
 * other query params
 * - email (launchEmail): used in `launchEmailCreation` action
 * - customerEmail (loginEmail): an email for which login is done
 *  (customerEmail incase of customer login or else mailbox email)
 * - section: after login where to redirect
 * - action: after login what action to take (check App.vue for action and section)
 * - pluginThemeID, cpThemeId: used in widget mode to set the CP theme
 * - mode: dark | light
 * - locale: lang
 */
export default {
  name: "LoginWithToken",
  data() {
    return {
      isTitanMail: null,
      brandDetails: {},
      showLoader: false,
      queryParams: {},
    };
  },
  components: { Loader },
  props: {
    setOfacParams: Function,
  },
  mounted() {
    measurePagePerformance();
  },
  created() {
    let partnerId = this.$route.query.partnerId;
    this.queryParams = this.$route.query;
    if (this.$route.meta.initialiseTheme) {
      // checking theme before using loader
      this.getPartnerInfo({
        headers: { "X-Auth": "noAuth" },
        params: {
          partnerId,
        },
      }).then((data) => {
        const PLUGIN_THEME_ID = this.$route.query.pluginThemeID;
        const CP_THEME_ID = data?.resources?.cpThemeId;
        const MODE = this.$route.query.theme;
        this.$store.dispatch("theme", CP_THEME_ID);
        document.documentElement.setAttribute(
          "theme",
          this.getThemeInfo(PLUGIN_THEME_ID, CP_THEME_ID, MODE)
        );
        helpers.setViewAndTheme();
        this.initAutoLogin();
      });
    } else {
      this.initAutoLogin();
    }
  },
  methods: {
    getThemeInfo(PLUGIN_THEME_ID, CP_THEME_ID, MODE) {
      const DEFAULT_THEME = "defaultTheme";
      return (
        PLUGIN_THEME_ID ||
        (CP_THEME_ID
          ? MODE
            ? `${CP_THEME_ID}_${MODE}`
            : CP_THEME_ID
          : DEFAULT_THEME)
      );
    },
    showLoginErrorScreen(err) {
      console.error("auto login failed with error: ", err);
      this.showLoader = false;
      sentryCaptureException(
        err,
        { loginFailure: "cpToken" },
        {
          query: JSON.stringify({
            domain: this.queryParams?.domain,
            partnerId: this.queryParams?.partnerId,
          }),
        }
      );
      this.$router.push({ name: "LoginFailureScreen" });
    },
    initAutoLogin() {
      this.showLoader = true;
      this.setBrandDetails();
      const token = this.$route.query.token;
      const domain = this.$route.query.domain;
      if (this.$route.query.section)
        smartStorage.setItem(
          "section",
          getLastQueryParamValue(this.$route.query, "section")
        );
      // used in `launchEmailCreation` action
      if (this.$route.query.email)
        smartStorage.setItem(
          "launchEmail",
          getLastQueryParamValue(this.$route.query, "email")
        );
      if (this.$route.query.action)
        smartStorage.setItem(
          "action",
          getLastQueryParamValue(this.$route.query, "action")
        );

      try {
        this.handleTokenLogin(token, domain);
      } catch (err) {
        this.showLoginErrorScreen(err);
        return;
      }
      let query = Object.assign({}, this.$route.query);
      delete query.token;
      delete query.domain;
      this.$router.replace({ query });
    },
    setBrandDetails() {
      this.isTitanMail =
        this.isTitanMail === null
          ? brand.deriveBrandFromURL() === brand.NAMES.TITAN
          : this.isTitanMail;
      this.brandDetails = brand.getDetails();
    },
    handleTokenLogin(token, domain) {
      return authService
        .loginWithToken(token, domain)
        .then(this.onSuccess)
        .catch(this.onError);
    },
    onSuccess(data) {
      if (data) {
        this.$emit("onLoginSuccess", "autoLogin");
      }
    },
    onError(err) {
      if (err?.data?.code === "OFAC_SANCTIONED_COUNTRY") {
        this.setOfacParams({
          isOfacFlag: true,
          ofacDocumentation: this.brandDetails.ofacDocumentation,
          supportEmail: this.brandDetails.supportEmail,
          brandLogo: this.brandDetails.logo,
        });
        this.medusaEvents.send(
          this.medusaEvents.createEventObj(
            {
              eventName: "ofac_login_denied",
              ofac_country: err.data.attrs.countryName,
              // is_first_login
            },
            smartStorage.getItem("domain"),
            "flock_order"
          )
        );
      } else {
        this.showLoginErrorScreen(err);
      }
    },
  },
};
</script>
