<template>
  <h4 :class="['titan-header', { 'no-margin': !margin }]">
    <slot></slot>
  </h4>
</template>

<script>
export default {
  props: {
    margin: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.titan-header {
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 32px;
  &.no-margin {
    margin-bottom: 0;
  }
}
</style>
