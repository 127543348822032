import { FLOW_TYPES, ROUTE_ACTIONS } from "@/helpers/const";
import {
  openNeoSignupAppSitePurchaseFlow,
  openNeoSite,
} from "@/helpers/neoWebsiteHelpers";
import { hasMailSuiteProduct, hasSiteProduct } from "@/helpers/product";
import router from "@/router";
import {
  canShowRenewOption,
  getNeoSignupAppUrl,
  isNeoBrand,
  redirectToSameORDifferentTab,
} from "@/helpers";

const prefix = "mobile.navbar.sections.";
const redirectTo = (path) => {
  router.push(path);
};

const getIconYPosition = (index, offset = 0, iconHeight = 42) => {
  const position = 0 - (index * iconHeight + offset);
  return {
    "background-position-y": `${position}px`,
  };
};

const { RENEW_BUNDLE, CHANGE_BILLING_CYCLE } = FLOW_TYPES;

export const getSections = (products, domainName, domainId, trialConfig) => {
  const _hasMailSuiteProduct = hasMailSuiteProduct(products);
  const _hasSiteProduct = hasSiteProduct(products);

  return [
    {
      name: "site",
      titleKey: prefix + "site.title",
      items: [
        {
          titleKey: prefix + "site.items.neoSite.title",
          subtitleKey: prefix + "site.items.neoSite.subTitle",
          style: getIconYPosition(0),
          isExternalLink: true,
          handler: () => openNeoSite(domainName, domainId),
          isVisible: () => _hasSiteProduct,
        },
      ],
    },
    {
      name: "buy or upgrade",
      titleKey: prefix + "buyOrUpgrade.title",
      items: [
        {
          titleKey: prefix + "buyOrUpgrade.items.buyMailBoxes.title",
          subtitleKey: prefix + "buyOrUpgrade.items.buyMailBoxes.subTitle",
          style: getIconYPosition(2),
          handler: () => redirectTo("/buy-email-account"),
          isVisible: () => _hasMailSuiteProduct && !trialConfig?.mail,
        },
        {
          titleKey: prefix + "buyOrUpgrade.items.upgradePlan.title",
          subtitleKey: prefix + "buyOrUpgrade.items.upgradePlan.subTitle",
          style: getIconYPosition(1),
          handler: () => redirectTo("/billing-and-upgrade"),
          isVisible: () => _hasMailSuiteProduct,
        },
        {
          titleKey: prefix + "buyOrUpgrade.items.upgradeSitePlan.title",
          subtitleKey: prefix + "buyOrUpgrade.items.upgradeSitePlan.subTitle",
          style: getIconYPosition(1),
          handler: () => redirectTo("/site-billing-and-upgrade"),
          isVisible: () => _hasSiteProduct,
        },
        {
          titleKey: prefix + "buyOrUpgrade.items.getNeoMail.title",
          subtitleKey: prefix + "buyOrUpgrade.items.getNeoMail.subTitle",
          style: getIconYPosition(2),
          isExternalLink: true,
          handler: () => redirectTo("/get-mail"),
          isVisible: () => isNeoBrand() && !_hasMailSuiteProduct,
        },
        {
          titleKey: prefix + "buyOrUpgrade.items.getNeoSite.title",
          subtitleKey: prefix + "buyOrUpgrade.items.getNeoSite.subTitle",
          isExternalLink: _hasSiteProduct,
          style: getIconYPosition(0),
          handler: () => {
            redirectTo({
              name: "SiteBillingAndUpgrade",
              query: { action: ROUTE_ACTIONS.GET_SITE },
            });
          },
          isVisible: () => isNeoBrand() && !_hasSiteProduct,
        },
      ],
    },
    {
      name: "get more",
      titleKey: prefix + "getMore.title",
      items: [
        {
          titleKey: prefix + "buyOrUpgrade.items.emailForNewDomain.title",
          subtitleKey: prefix + "buyOrUpgrade.items.emailForNewDomain.subTitle",
          isExternalLink: true,
          style: getIconYPosition(4),
          handler: () =>
            redirectToSameORDifferentTab(getNeoSignupAppUrl(), true),
          isVisible: () => isNeoBrand() && _hasMailSuiteProduct,
        },
        {
          titleKey: prefix + "buyOrUpgrade.items.siteForNewDomain.title",
          subtitleKey: prefix + "buyOrUpgrade.items.siteForNewDomain.subTitle",
          isExternalLink: true,
          style: getIconYPosition(5),
          handler: openNeoSignupAppSitePurchaseFlow,
          isVisible: () => isNeoBrand() && _hasSiteProduct,
        },
      ],
    },
    {
      name: "billing",
      titleKey: prefix + "billing.title",
      items: [
        {
          titleKey: prefix + "billing.items.renewSubscription.title",
          subtitleKey: prefix + "billing.items.renewSubscription.subTitle",
          style: getIconYPosition(3),
          handler: () =>
            redirectTo(`/billing-and-upgrade?${RENEW_BUNDLE}=true`),
          isVisible: () => canShowRenewOption(),
        },
        {
          titleKey: prefix + "billing.items.changeBillingCycle.title",
          subtitleKey: prefix + "billing.items.changeBillingCycle.subTitle",
          style: getIconYPosition(3),
          handler: () =>
            redirectTo(`/mobile-home?${CHANGE_BILLING_CYCLE}=true`),
          isVisible: () => true,
        },
      ],
    },
    {
      name: "more features",
      titleKey: prefix + "moreFeatures.title",
      items: [
        {
          titleKey: prefix + "moreFeatures.items.moreFeatureOnDesktop.title",
          subtitleKey:
            prefix + "moreFeatures.items.moreFeatureOnDesktop.subTitle",
          style: getIconYPosition(5),
          hideRedirectIcon: true,
          isVisible: () => true,
        },
      ],
    },
  ];
};
