var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-fade"}},[_c('div',{staticClass:"modal-overlay"},[_c('div',{class:[
        'modal-body-container',
        {
          modalPage: _vm.view.showModalAsPage,
        },
      ],attrs:{"role":"dialog","aria-labelledby":"modalTitle","aria-describedby":"modalDescription"}},[(!_vm.view.showModalAsPage)?_c('button',{staticClass:"btn-close",attrs:{"type":"button","aria-label":"Close modal"},on:{"click":_vm.close}},[_vm._v(" × ")]):_vm._e(),(_vm.view.showModalAsPage)?_c('widget-breadcrumb-nav',{attrs:{"isModal":true,"modalName":"last-delete-account"},on:{"onNavClick":_vm.close}}):_vm._e(),_c('div',{staticClass:"modal-content-wrapper"},[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t(`emailAccounts.deleteAndCreateNew.heading`)))]),_c('p',{staticClass:"email"},[_vm._v(_vm._s(_vm.user.email))]),_c('p',{staticClass:"color-grey custom-margin",domProps:{"innerHTML":_vm._s(
              _vm.$t(`emailAccounts.deleteAndCreateNew.deleteAccountQuestion`, [
                _vm.user.email,
              ])
            )}}),_c('div',{staticClass:"input-wrapper"},[_c('button',{staticClass:"button",on:{"click":function($event){return _vm.onConfirmCreateAndDeleteEmail()}}},[_vm._v(" "+_vm._s(_vm.$t(`emailAccounts.deleteAndCreateNew.confirmBtn`))+" ")]),_c('button',{staticClass:"button button__line-blue",on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t(`emailAccounts.deleteAndCreateNew.cancelBtn`))+" ")])])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }