import bundleEvents from "@/telemetry/bundleEvents";
import { MEDUSA_EVENTS_MAP } from "@/telemetry/events";
import {
  getCommonMedusaParams,
  getCommonMedusaParamsForUpgradePlan,
  getProductToPurchaseAttr,
} from "@/telemetry/medusaUtils";

// mixin to manage common medusa events
export default {
  methods: {
    // event to fire when user visits buy email account screen
    reportBuyEmailAccountsStarted() {
      const redirectionSource =
        this.$route.query?.redirectionSource || "unknown";

      this.medusaEvents.send(
        this.medusaEvents.createEventObj(
          {
            eventName: "buy_email_accounts_started",
            source_hook: redirectionSource,
            ...getCommonMedusaParams(),
          },
          smartStorage.getItem("domain"),
          "flock_order"
        )
      );

      this.removeRedirectionSource();
    },
    // event to fire when user visits upgrade plan screen
    reportUpgradeStarted(eventObj) {
      this.medusaEvents.send(
        this.medusaEvents.createEventObj(
          {
            eventName: MEDUSA_EVENTS_MAP.UPGRADE_STARTED,
            ...getCommonMedusaParams(),
            ...eventObj,
          },
          smartStorage.getItem("domain"),
          "flock_order"
        )
      );

      this.removeRedirectionSource();
    },

    medusaBillingCycleClicked(eventObj) {
      const medusaPayload = {
        ...getCommonMedusaParams(),
        ...eventObj,
      };
      this.medusaEvents.send(
        this.medusaEvents.createEventObj(
          {
            eventName: MEDUSA_EVENTS_MAP.CHANGE_BILLING_CYCLE_CLICKED,
            ...medusaPayload,
          },
          smartStorage.getItem("domain"),
          "flock_order"
        )
      );
    },

    reportPurchaseStarted(eventObj, record) {
      const medusaPayload = {
        ...getCommonMedusaParams(),
        ...getProductToPurchaseAttr(record),
        ...eventObj,
      };
      this.medusaEvents.send(
        this.medusaEvents.createEventObj(
          {
            eventName: MEDUSA_EVENTS_MAP.PURCHASE_STARTED,
            ...(record ? getCommonMedusaParamsForUpgradePlan(record) : {}),
            ...medusaPayload,
          },
          smartStorage.getItem("domain"),
          "flock_order"
        )
      );

      bundleEvents.logPurchaseStarted(medusaPayload);
    },

    // method to remove `redirectionSource` from query param
    removeRedirectionSource() {
      if (this.$route.query.redirectionSource) {
        const query = Object.assign({}, this.$route.query);
        delete query.redirectionSource;
        this.$router.replace({ query }).catch((err) => {
          // Handle the error here if it's not a NavigationDuplicated error
          if (err.name !== "NavigationDuplicated") {
            console.error("Navigation Error occurred:", err);
            throw err;
          }
        });
      }
    },
  },
};
