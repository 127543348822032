<script>
import eventBus from "../../helpers/event-bus";
import viewTypes from "../../views";

export default {
  name: "WidgetToastScreen",
  data() {
    const view = document.documentElement.getAttribute("view");
    return {
      showAsPage: viewTypes[view].showModalAsPage,
      imageType: "",
      widgetToastInfo: {},
    };
  },
  created() {
    eventBus.$on("handleWidgetToast", (responseObj) => {
      this.widgetToastInfo = { ...responseObj };
      this.imageType =
        this.widgetToastInfo.type === "success"
          ? "successful-deletion-tick.svg"
          : "error-image.svg";
    });
  },
  methods: {
    close() {
      this.widgetToastInfo = {};
    },
  },
};
</script>
<template>
  <div class="widgetToastContainer" v-if="widgetToastInfo.type">
    <widget-breadcrumb-nav
      :isModal="true"
      :modalName="widgetToastInfo.modalName"
      @onNavClick="close()"
    />
    <div class="successfulInfoContainer">
      <img class="additionalImage" :src="require(`@/assets/${imageType}`)" />
      <div class="message">
        <!-- header -->
        <div class="header">
          {{ widgetToastInfo.header }}
        </div>
        <!-- Ok button -->
        <div class="input-wrapper">
          <button class="button confirmAction" @click="close">
            {{
              widgetToastInfo.type === "success"
                ? $t("utility.ok")
                : $t("utility.goBack")
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.widgetToastContainer {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  background: var(--primaryBgCol);
  .illustration {
    padding-bottom: 30px;
  }
  .successfulInfoContainer {
    display: flex;
    align-items: flex-start;
    .additionalImage {
      padding-right: 15px;
      position: relative;
      top: 5px;
    }
    .message {
      display: flex;
      flex-direction: column;
      .header {
        padding-bottom: 32px;
        font-weight: 600;
        font-size: 16px;
      }
      .confirmAction {
        padding: 10px 50px !important;
      }
    }
  }
}
</style>
