<template>
  <div ref="click-outside-component">
    <slot />
  </div>
</template>

<script>
export default {
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.$emit("clicked-outside");
      }
    },
  },
};
</script>
