import store from "@/store";

/**
 * @param {function} requireFileContext require.context
 * @param {string[]} fileList list of files to loop though
 * @param {number} index
 * @returns file | error
 */
export const gracefullyLoadFile = (requireFileContext, fileList) => {
  const checkRecursively = (index = 0) => {
    try {
      const fileToLoad = fileList[index];
      return requireFileContext(fileToLoad);
    } catch (e) {
      const next = index + 1;
      if (next >= fileList.length || !fileList[next]) throw e;

      return checkRecursively(next);
    }
  };

  return checkRecursively();
};

// function to recursively check theme image
export const gracefullyLoadImage = (fileNameAndExt) => {
  const theme = store.getters.theme || "defaultTheme";
  const context = require.context("../assets/");
  return gracefullyLoadFile(context, [
    `./widget/${theme}/${fileNameAndExt}`,
    `./widget/defaultTheme/${fileNameAndExt}`,
    `./${fileNameAndExt}`,
  ]);
};
