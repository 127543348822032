<template>
  <click-outside @clicked-outside="handleCloseDropdown">
    <div class="custom-dropdown" :class="[className]">
      <slot name="trigger">
        <div class="default-trigger" @click="toggleDropdown">
          <input
            type="text"
            class="input dropdown-input"
            :value="value || selectedOption?.value"
            :placeholder="$t(`placeholders.select`)"
            :style="{ width: dropdownInputWidth }"
            :disabled="disabled"
            readonly
          />
          <i class="dropdown-icon" :class="{ active: isOpen, disabled }"></i>
        </div>
      </slot>
      <ul class="menu" v-if="isOpen">
        <slot name="option" v-for="option in options">
          <li
            @click="selectOption(option.value)"
            :key="option.value"
            class="option"
            :class="{
              selected: (value || selectedOption?.value) === option.value,
            }"
          >
            {{ option.label }}
          </li>
        </slot>
      </ul>
    </div>
  </click-outside>
</template>
<script>
import ClickOutside from "../Utility/ClickOutside.vue";

export default {
  name: "TitanDropDown",
  props: {
    className: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    dropdownInputWidth: {
      type: Number,
    },
    value: String,
    disabled: Boolean,
  },
  components: {
    ClickOutside,
  },
  data() {
    return {
      isOpen: false,
      selectedOption: null,
    };
  },
  methods: {
    toggleDropdown() {
      if (this.disabled) {
        return;
      }
      this.isOpen = !this.isOpen;
      this.$emit("onDropdownClick", this.isOpen);
    },
    handleCloseDropdown() {
      this.isOpen = false;
    },
    selectOption(value) {
      this.isOpen = false; // Close dropdown on selection
      const selectedOption = this.options.find(
        (option) => option.value === value
      );
      if (selectedOption !== this.selectedOption) {
        this.selectedOption = selectedOption;
        // Emit an event to notify parent component (optional)
        this.$emit("onOptionSelected", this.selectedOption);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.custom-dropdown {
  position: relative;

  .default-trigger {
    width: 100%;
    position: relative;
    cursor: pointer;

    .dropdown-icon {
      background: url("../../assets/dropdown.svg");
      width: 24px;
      height: 24px;
      position: absolute;
      right: 8px;
      top: 8px;
      &.active {
        transform: rotate(180deg);
      }

      &.disabled {
        cursor: not-allowed;
      }
    }

    .dropdown-input {
      &[readonly="readonly"] {
        background: initial;
        color: initial;
        cursor: pointer;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background: var(--disabledInputBg);
      }
    }
  }

  .menu {
    padding: 4px 0px;
    border-radius: 4px;
    background: var(--tabBgCol);
    box-shadow: 0px 0px 8px -1px var(--boxShadowCol7);
    width: 100%;
    position: absolute;
    z-index: 999;

    .option {
      padding: 8px 12px;
      font-size: 14px;
      cursor: pointer;
      list-style-type: none;

      &:hover,
      &.selected {
        background: var(--dropdownItemBgCol);
      }
    }
  }
}
</style>
