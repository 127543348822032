import $http from "@/helpers/http";
import Logger from "@/helpers/logger";

class PartnerApi {
  getPartnerInfo(config) {
    try {
      return $http.get("/internal/getPartnerInfo", config);
    } catch (e) {
      Logger.error("`getPartnerInfo` api", e);
      return Promise.reject(e);
    }
  }
}

const partnerApi = new PartnerApi();

export default partnerApi;
