import Vue from "vue";

import App from "./App.vue";
import { i18n } from "./i18n";
import router from "./router";
import store from "./store";
import { initGlobal } from "./global";

// Register all plugins/core components
import "./components/Utility/index.js";
import "./core-components";
import "./plugins";

/**
 * function to set global props
 * e.g. necessary flags, filters, middleware, mixins, etc
 */
initGlobal();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
