var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardContainer"},[(_vm.showTransactionFailedModal)?_c('transaction-failed-modal',{on:{"close":_vm.closeModal}}):_vm._e(),(_vm.showTransactionSuccessModal)?_c('success-screen',{attrs:{"email":_vm.email,"purchasedPlanDetails":_vm.purchasedPlanDetails}}):_vm._e(),_c('div',{staticClass:"cardDetails"},[_c('button',{staticClass:"backBtn",on:{"click":() => _vm.changeIndex(-1)}},[_c('img',{attrs:{"src":require("../../assets/back-arrow.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t("purchaseFlow.goBack"))+" ")]),_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.$t("purchaseFlow.cardDetails.header"))+" ")]),_c('StripePaymentCard',{attrs:{"shouldStartPayment":_vm.onBillingInfoSubmit,"currency":_vm.currencyCode,"paymentPayload":_vm.paymentPayload,"helperText":_vm.$t(
          _vm.isTrailPlan
            ? 'purchaseFlow.cardDetails.trailPlanHelperText'
            : 'purchaseFlow.cardDetails.paidPlanHelperText',
          [_vm.cr(_vm.scaleUpToTwoDecimals(this.chargeableAmount)), _vm.cr(0)] // 0 only applicable to trail plan
        ),"helperSubText":_vm.isTrailPlan
          ? _vm.$t('purchaseFlow.cardDetails.trailPlanHelperSubText', [
              _vm.cr(_vm.scaleUpToTwoDecimals(this.costPerAccount)),
              _vm.$t(
                `billingCycles.${_vm.purchasedPlanDetails.billingCycle}`
              ).toLowerCase(),
              _vm.purchasedPlanDetails.defaultTrialDays,
            ])
          : '',"billingCycle":_vm.purchasedPlanDetails?.billingCycle,"amountToBePaid":this.chargeableAmount,"showCheckbox":true,"checkboxText":_vm.$t('purchaseFlow.cardDetails.checkboxText'),"processPaymentAPI":"/panel/purchase/complete-purchase","initPaymentAPI":"/panel/purchase/initiate-purchase","setAmountIncTax":_vm.setAmountIncTax,"authorizationHeader":_vm.purchaseAuthData.params.token,"hideUpdateCard":true,"domainProp":_vm.purchaseFlowJWTPayload.domainName,"loginEmailProp":_vm.purchaseFlowJWTPayload.customerEmailAddress,"showAutoRenew":!_vm.isTrailPlan,"isTrailPlan":_vm.isTrailPlan},on:{"toggleChecked":_vm.toggleChecked,"onPaymentSuccess":_vm.onPaymentSuccess,"onPaymentFailed":_vm.onPaymentFailed}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }