/**
 * The Control Panel iframe sends this event when it shows a loader.
 */
export const LOADER_DID_APPEAR = "LOADER_DID_APPEAR";
/**
 * The Control Panel iframe sends this event when it wants to communicate changes in it's internal dimensions.
 */
export const CONTENT_DIMENSION_DID_UPDATE = "CONTENT_DIMENSION_DID_UPDATE";
/**
 * The Control Panel iframe sends this event when there's a change in number of purchased/active mailboxes.
 */
export const EMAIL_ACCOUNTS_CHANGED = "EMAIL_ACCOUNTS_CHANGED";
/**
 * The Control Panel iframe sends this event when the iframe has completed a page navigation.
 */
export const PAGE_DID_LOAD = "PAGE_DID_LOAD";
/**
 * The Control Panel iframe sends this event to enable a payment action to be taken by the parent of the iframe.
 */
export const PAYMENT_ACTION = "PAYMENT_ACTION";

/**
 * The parent window sends this message whenever the iframe width is changed.
 */
export const IFRAME_WIDTH_CHANGED = "IFRAME_WIDTH_CHANGED";
