<template>
  <div class="purchase-flow-container">
    <Loader v-if="!hasFetched" />
    <div v-if="purchaseDenied && hasFetched">
      <Header />
      <ErrorScreen :domainName="domainName" :errorType="errorType" />
    </div>
    <div v-else-if="!purchaseDenied && hasFetched">
      <Header />
      <step-progress-bar :progressBarData="progressBarData"></step-progress-bar>
      <component
        :is="currentComp[currentIndex]"
        :changeIndex="changeIndex"
        :purchaseAuthData="purchaseAuthData"
        :setPurchasedPlanDetails="setPurchasedPlanDetails"
        :purchaseFlowJWTPayload="purchaseFlowJWTPayload"
        :purchasedPlanDetails="purchasedPlanDetails"
        :partnerId="partnerId"
        @handleAPIFailure="handleAPIFailure"
      ></component>
      <div
        v-if="isLastPage"
        class="tNc"
        v-html="$t(`purchaseFlow.tNc`, [termsOfUse, privacyPolicy])"
      ></div>
    </div>
  </div>
</template>

<script>
import stepProgressBar from "../Utility/StepProgressBar";
import CycleAndAccounts from "./CycleAndAccounts";
import BillingForm from "./BillingForm";
import CardDetails from "./CardDetails";
import Header from "./Header";
import ErrorScreen from "./ErrorScreen";
import * as helper from "../../helpers";
import { clearStoredData } from "./storedData";
import brand from "../../helpers/brand";
import Loader from "../Utility/Loader";
import { measurePagePerformance } from "@/analytics/performance/pageTimings";

export default {
  name: "PurchaseFlow",
  components: {
    stepProgressBar,
    CycleAndAccounts,
    BillingForm,
    CardDetails,
    Header,
    ErrorScreen,
    Loader,
  },
  data() {
    return {
      hasFetched: false,
      purchaseAuthData: {},
      domainName: "",
      jwt: "",
      partnerId: "",
      purchaseDenied: false,
      onLine: navigator.onLine,
      errorType: null,
      progressBarData: [
        {
          label: `${this.$t("purchaseFlow.progressBar.label1")}`,
          classType: "circleStep_inProgress",
        },
        {
          label: `${this.$t("purchaseFlow.progressBar.label2")}`,
          classType: "circleStep_untouched",
        },
        {
          label: `${this.$t("purchaseFlow.progressBar.label3")}`,
          classType: "circleStep_untouched",
        },
      ],
      purchaseFlowJWTPayload: null,
      purchasedPlanDetails: null,
      currentIndex: 0,
      currentComp: [CycleAndAccounts, BillingForm, CardDetails], // Will Change It later
      termsOfUse: brand.getDetails("titan").billing.termsOfUse,
      privacyPolicy: brand.getDetails("titan").billing.privacyPolicy,
    };
  },
  computed: {
    isLastPage() {
      return this.currentIndex === this.currentComp.length - 1;
    },
  },
  created() {
    this.initialiseFlow();
    this.http.postData(
      "/panel/purchase/purchase-auth",
      {
        jwt: this.jwt,
        partnerId: this.partnerId,
      },
      {},
      this.onPurchaseAuthSuccess,
      this.onPurchaseAuthFailure
    );
  },
  watch: {
    onLine(v) {
      if (!v) {
        const message = this.$t("purchaseFlow.offline");
        this.showToast(message);
      }
    },
  },
  mounted() {
    measurePagePerformance();
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  methods: {
    handleAPIFailure(type) {
      const message = this.$t("purchaseFlow.APIFailure");
      this.showToast(message);
      if (type === "cycleAndAccountsError") {
        this.errorType = "cycleAndAccountsError";
        this.purchaseDenied = true;
      }
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
    initialiseFlow() {
      this.isTitanMail = true;
      helper.setWebsiteMetaData.call(this);
      smartStorage.removeItem("authToken"); // logging out previous user;
      this.jwt = this.$route.query.jwt;
      this.partnerId = this.$route.query.partnerId;
      this.purchaseFlowJWTPayload = helper.getJWTpayloadData(this.jwt);
      clearStoredData();
      helper.setViewAndTheme(
        this.$route.query.view,
        this.$route.meta.view,
        this.$route.query.theme,
        this.$route.meta.theme
      );
      // this.$router.replace({ query: null });
    },
    setPurchasedPlanDetails(data) {
      this.purchasedPlanDetails = data;
    },
    onPurchaseAuthFailure() {
      this.purchaseDenied = true;
      this.hasFetched = true;
      this.errorType = "invalidParams";
      this.handleAPIFailure();
    },
    onPurchaseAuthSuccess(data) {
      this.domainName = this.purchaseFlowJWTPayload.domainName;
      smartStorage.setItem("lang", this.purchaseFlowJWTPayload.locale);
      switch (data.flow) {
        case "panel_login":
          // Directly login to control panel : auto login
          smartStorage.setItem(
            "config",
            this.$route.meta.config === "cpWidget" ? "cpWidget" : "generic"
          );
          if (data.params) {
            helper.proceedForAutoLogin(data.params);
            if (smartStorage.getItem("authToken")) {
              this.$root.headers = {
                headers: {
                  Authorization: smartStorage.getItem("authToken"),
                  "X-Auth": "controlPanel",
                },
              };
              helper.hardRedirect("/");
            }
          }
          return;
        case "purchase_denied":
          // "Domain already provisioned with another customer."
          this.purchaseDenied = true;
          this.hasFetched = true;
          this.errorType = "purchaseDenied";
          return;
        case "purchase":
          // Proceed for purchase Flow
          this.purchaseDenied = false;
          this.purchaseAuthData = data;
          this.hasFetched = true;
          return;
        default:
          this.purchaseDenied = true;
          return;
      }
    },
    changeIndex(count) {
      this.progressBarData[this.currentIndex].classType =
        count === 1 ? "circleStep_completed" : "circleStep_untouched";
      this.currentIndex += count;
      this.progressBarData[this.currentIndex].classType =
        "circleStep_inProgress";
    },
  },
};
</script>
<style lang="scss">
.purchase-flow-container {
  width: 100vw;
}
div.tNc {
  width: 540px;
  margin: 0 auto;
  padding-top: 40px;
  a {
    color: var(--linkBtnCol) !important;
  }
}
</style>
