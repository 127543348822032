import Logger from "@/helpers/logger";
import entriApi from "./entri.api";
import { ENTRI_SETUP_TYPE, getEntriConfig } from "@/helpers/entriConfig";
import store from "@/store";

class EntriService {
  getEntriToken() {
    try {
      return entriApi.getEntriToken();
    } catch (e) {
      Logger.error("`getEntriToken` service", e);
      return Promise.reject(e);
    }
  }

  async checkDomain(domain, records) {
    try {
      const data = await entriApi.getEntriToken();
      const config = getEntriConfig(data.auth_token, records);
      const response = await entriApi.checkDomain(domain, config);
      switch (response.setupType) {
        case ENTRI_SETUP_TYPE.AUTOMATIC:
          store.dispatch("entriSetupPossible", true);
          break;
        case ENTRI_SETUP_TYPE.MANUAL:
          store.dispatch("entriSetupPossible", false);
          break;
        default:
          break;
      }

      return response;
    } catch (e) {
      Logger.error("`checkDomain` service", e);
      return Promise.reject(e);
    }
  }
}

const entriService = new EntriService();

export default entriService;
