<script>
import { mapGetters } from "vuex";
export default {
  name: "SuspendAccount",
  props: {
    user: Object,
    view: Object,
  },
  data: function () {
    return {
      isSent: false,
      suspensionReason: "",
    };
  },
  computed: {
    ...mapGetters({
      domainName: "domain/domainName",
    }),
  },
  methods: {
    close() {
      this.suspensionReason = "";
      this.$emit("close");
    },
    suspendAccount() {
      if (!this.isSent) {
        this.isSent = true;
        this.http.postData(
          "panel/account/email/suspend",
          {
            email: this.user.email,
            domainName: this.domainName,
            suspensionType: this.suspensionReason,
          },
          this.$root.headers,
          this.onSuspendSuccess,
          this.onSuspendError
        );
      }
    },
    onSuspendSuccess(data) {
      if (data) {
        this.close();
        this.user.status = "suspended";
        const message = this.user.email + " " + this.$t("toast.isSuspended");
        this.showToast(
          message,
          {
            type: "success",
            modalName: "suspend-account",
          },
          2000
        );
        this.isSent = false;
        var eventObj = {
          eventName: "mail_account_suspended",
          type: "pop",
        };
        this.medusaEvents.send(
          this.medusaEvents.createEventObj(
            eventObj,
            this.user.email,
            "flock_account"
          )
        );
      }
    },
    onSuspendError(response) {
      if (response && response.data && response.data.desc) {
        this.close();
        this.showToast(
          response.data.desc,
          {
            type: "error",
            modalName: "suspend-account",
          },
          2000
        );
        this.isSent = false;
      }
    },
  },
};
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-overlay">
      <div
        :class="[
          'modal-body-container',
          'suspend-account-modal',
          {
            modalPage: view.showModalAsPage,
          },
        ]"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <button
          type="button"
          class="btn-close"
          @click="close"
          aria-label="Close modal"
          v-if="!view.showModalAsPage"
        >
          &times;
        </button>
        <widget-breadcrumb-nav
          :isModal="true"
          modalName="suspend-account"
          v-if="view.showModalAsPage"
          @onNavClick="close"
        />
        <div class="modal-content-wrapper">
          <div>
            <h4>
              {{ $t("emailAccounts.suspendAccount.heading") }}
            </h4>
            <p class="email">{{ user.email }}</p>
            <div>
              {{ $t("emailAccounts.suspendAccount.reasonHeader") }}
            </div>
            <input
              class="reason-input"
              type="text"
              :placeholder="
                this.$t(`emailAccounts.suspendAccount.reasonPlaceholder`)
              "
              v-model="suspensionReason"
            />
            <p class="color-grey">
              {{ $t("emailAccounts.suspendAccount.onceSuspend") }}
              <br />
              • {{ $t("emailAccounts.suspendAccount.firstPoint") }}<br />
              • {{ $t("emailAccounts.suspendAccount.secondPoint") }}<br />
              •
              {{ $t("emailAccounts.suspendAccount.thirdPoint") }}
            </p>
            <div class="input-wrapper">
              <button class="button" @click="suspendAccount">
                {{ $t("emailAccounts.deleteAccount.confirmBtn") }}
              </button>
              <button class="button button__line-blue" @click="close">
                {{ $t("emailAccounts.deleteAccount.cancelBtn") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.suspend-account-modal {
  p {
    line-height: 22px;
  }
  .reason-input {
    margin: 5px 0;
    width: 420px;
    padding: 9px;
    border: 1px solid var(--inputBorderCol);
    &:focus {
      outline: none;
      border: solid 1px var(--inputFocusBorderCol) !important;
    }
  }
  .button {
    border: 1px solid var(--secondaryBtnCol);
    background: var(--secondaryBtnCol);
  }
  .button__line-blue {
    color: var(--secondaryBtnCol);
    background-color: var(--tabBgCol);
  }
}
</style>
