<template>
  <titan-modal
    :closeBtnClass="'m0-auto mt-24'"
    :closeBtnText="$t('neoWelcomeScreen.footer')"
    :showClose="false"
    :handleClose="closeWelcomeScreen"
    :styles="{ 'padding-top': 0, 'text-align': 'center' }"
  >
    <template #header>
      <img
        src="@/assets/neo-welcome-screen-icon.svg"
        alt="welcome-logo"
        height="260"
        width="270"
      />
      <h2
        class="weclome-neo-header"
        v-html="
          $t('neoWelcomeScreen.header', [
            brandDetails.name,
            $t(brandDetails.panelName),
          ])
        "
      ></h2>
    </template>
    <template #content>
      <div class="mt-4">
        {{ $t("neoWelcomeScreen.content") }}
      </div>
    </template>
    <template #close
      ><TitanButton
        @click="handleContinue"
        :minWidth="210"
        class="continue-btn"
        >{{ $t("neoWelcomeScreen.footer") }}</TitanButton
      ></template
    >
  </titan-modal>
</template>
<script>
import { mapGetters } from "vuex";
import TitanButton from "../core/TitanButton.vue";
export default {
  components: { TitanButton },
  computed: {
    ...mapGetters(["brandDetails"]),
    ...mapGetters({
      domainConnectData: "domain/domainConnectData",
    }),
    mxVerified() {
      return this.domainConnectData.mxVerified;
    },
  },
  methods: {
    closeWelcomeScreen() {
      this.$emit("onClosePopup");
    },

    handleContinue() {
      this.closeWelcomeScreen();
      if (this.mxVerified) {
        this.$router.push({ name: "EmailAccounts" });
      } else {
        this.$router.push({ name: "DomainVerification" });
      }
    },
  },
};
</script>
<style lang="scss">
.weclome-neo-header {
  margin-top: -30px;
}
.continue-btn {
  margin: 24px auto 0;
}
</style>
