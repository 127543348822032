import $http from "@/helpers/http";
import Logger from "@/helpers/logger";

class AuthApi {
  async authenticateExternalToken(domainName, externalToken) {
    try {
      return $http.post("panel/authenticate", {
        domainName,
        externalToken,
      });
    } catch (e) {
      Logger.error("`authenticateExternalToken` api", e);
      return Promise.reject(e);
    }
  }

  login(email, password, customerId) {
    try {
      const payload = {
        email,
        password,
      };
      if (customerId) payload.userId = customerId;

      return $http.post("panel/login", payload);
    } catch (e) {
      Logger.error("`login` api", e);
      return Promise.reject(e);
    }
  }

  logout() {
    try {
      return $http.post("panel/logout");
    } catch (e) {
      Logger.error("`logout` api", e);
      return Promise.reject(e);
    }
  }

  loginWithJWT(jwt, partnerId, installId) {
    try {
      const payload = { jwt };
      if (installId) {
        payload.serverId = installId;
      } else {
        payload.partnerId = partnerId;
      }

      return $http.post("panel/authenticate/jwt", payload);
    } catch (e) {
      Logger.error("`loginWithJWT` api", e);
      return Promise.reject(e);
    }
  }

  loginWithWebmail(email, webmailToken) {
    try {
      const payload = { email, webmailToken };
      return $http.post("panel/authenticate/webmail", payload);
    } catch (e) {
      Logger.error("`loginWithWebmail` api", e);
      return Promise.reject(e);
    }
  }

  loginWithToken(token) {
    try {
      const headers = {
        Authorization: token,
        "X-Auth": "controlPanel",
      };

      return $http.get("internal/domain/validate-admin-panel-token", {
        headers,
      });
    } catch (e) {
      Logger.error("`loginWithToken` api", e);
      return Promise.reject(e);
    }
  }
}

const authApi = new AuthApi();

export default authApi;
