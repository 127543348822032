<script>
import { REGX } from "@/helpers/const";
import { mapGetters } from "vuex";

export default {
  name: "ResetPassword",
  data() {
    return {
      password: "",
      recipient: "",
      serverError: "",
      emailError: "",
      autoGenerate: false,
      showPassword: false,
      theme: null,
      alternateEmail: "",
    };
  },
  props: {
    email: String,
    mailboxAge: Number,
    view: Object,
  },
  created() {
    this.theme = document.documentElement.getAttribute("theme");
  },
  computed: {
    ...mapGetters({
      domainName: "domain/domainName",
    }),
  },
  methods: {
    checkForEmailError() {
      if (!this.alternateEmail.match(REGX.email)) {
        this.emailError = this.$t("emailAccounts.createEmail.invalidEmail");
      } else {
        this.emailError = "";
      }
    },
    close() {
      this.emailError = "";
      this.password = "";
      this.recipient = "";
      this.autoGenerate = false;
      this.showPassword = false;
      this.alternateEmail = "";
      this.$emit("close");
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    sendResetPasswordLink() {
      if (this.alternateEmail.match(REGX.email)) {
        this.http.postData(
          "panel/account/email/reset-password",
          {
            email: this.email,
            recipient: this.alternateEmail,
            domainName: this.domainName,
            source: "Reset password email",
          },
          this.$root.headers,
          (data) => {
            if (data) {
              const message = this.$t(
                `emailAccounts.resetPassword.passwordResetSuccessSentToEmail`,
                [this.alternateEmail]
              );
              this.showToast(
                message,
                {
                  type: "success",
                  modalName: "reset-password",
                },
                2000
              );
              const eventObj = {
                alternate_email_id: this.alternateEmail,
                email: this.email,
                eventName: "reset_password_link_sent",
                mailbox_age_in_days: this.mailboxAge,
                source_hook: "Reset password popup",
              };
              this.medusaEvents.send(
                this.medusaEvents.createEventObj(
                  eventObj,
                  this.email,
                  "flock_account"
                )
              );
              this.close();
            }
          },
          (response) => {
            if (response && response.data) {
              const message = this.$t(
                "emailAccounts.resetPassword.somethingWentWrong"
              );
              this.showToast(
                message,
                {
                  type: "error",
                  modalName: "reset-password",
                },
                2000
              );
              this.serverError = message;
            }
          }
        );
      }
    },
  },
};
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-overlay">
      <div
        :class="['modal-body-container', { modalPage: view.showModalAsPage }]"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <button
          type="button"
          class="btn-close"
          @click="close"
          aria-label="Close modal"
          v-if="!view.showModalAsPage"
        >
          &times;
        </button>
        <widget-breadcrumb-nav
          :isModal="true"
          modalName="reset-password"
          v-if="view.showModalAsPage"
          @onNavClick="close"
        />
        <div class="modal-content-wrapper">
          <div class="reset-password-modal">
            <h4>
              {{ $t("emailAccounts.emailList.resetPassword") }}
            </h4>
            <p class="email">{{ email }}</p>
            <div class="input-wrapper">
              <label>{{ $t(`emailAccounts.resetPassword.linkSent`) }}</label>
              <div class="input-addon-wrapper">
                <input
                  type="email"
                  class="input password-input"
                  :class="{ 'b-red': emailError || serverError }"
                  v-bind:placeholder="
                    $t('emailAccounts.resetPassword.placeholder')
                  "
                  v-model="alternateEmail"
                  @focusout="checkForEmailError()"
                  autocomplete="new-password"
                />
                <span
                  :class="showPassword ? 'password-hidden' : 'password-shown'"
                  @click="togglePassword"
                  v-show="password.length > 0"
                ></span>
              </div>
              <div class="error">
                {{ $t(emailError) || serverError }}
              </div>
            </div>
            <div class="resetInfo">
              {{ $t("emailAccounts.resetPassword.info") }}
            </div>
            <div class="input-wrapper">
              <button
                class="button"
                v-bind:class="{
                  invalid: emailError || !alternateEmail,
                }"
                @click="sendResetPasswordLink"
              >
                {{ $t("emailAccounts.resetPassword.sendLink") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
p.email {
  margin-bottom: 32px;
  word-break: break-all;
}
.resetInfo {
  margin-bottom: 20px;
}
</style>
