<template>
  <div class="modalContainer">
    <b-modal
      size="550"
      id="successModal"
      centered
      header-border-variant="white"
      footer-border-variant="white"
      hide-footer
      no-close-on-backdrop
      @hide="confirmAction"
      v-model="showModal"
    >
      <img
        src="../../assets/billingandUpgradeToPro.svg"
        class="successImg"
        alt=""
        width="155px"
        height="180px"
      />
      <div class="modalContent">
        <div class="line-1">
          {{ $t(`purchaseFlow.successPayment.line1`, [planType]) }}
        </div>
        <div class="line-2">
          {{ $t(`purchaseFlow.successPayment.line2`, [email]) }}
        </div>
      </div>
      <button class="confirmBtn" @click="confirmAction">
        {{ $t("purchaseFlow.successPayment.gotIt") }}
      </button>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import { clearStoredData } from "./storedData";
import * as helper from "../../helpers";

export default {
  name: "SuccessScreen",
  props: {
    email: String,
    purchasedPlanDetails: Object,
  },
  mounted() {
    this.planType = this.purchasedPlanDetails.planType.toUpperCase();
  },
  components: {
    BModal,
  },
  data() {
    return {
      planType: null,
      showModal: true,
    };
  },
  methods: {
    confirmAction() {
      clearStoredData();
      this.showModal = false;
      helper.hardRedirect("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.successImg {
  position: relative;
  display: block;
  text-align: center;
  margin: 0 auto;
  top: -16px;
}
.modalContent {
  .line-1,
  .line-2 {
    text-align: center;
  }
  .line-1 {
    font-size: 20px;
  }
  .line-2 {
    opacity: 0.5;
  }
}
.confirmBtn {
  display: block;
  margin: 40px auto;
  border: none;
  border-radius: 4px;
  padding: 12px 66px;
  background-color: var(--tertiaryBtnCol);
  color: var(--tabBgCol);
  font-size: 14px;
}
</style>
