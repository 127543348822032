import { getPartnerId, PRODUCTS } from "@/helpers";
import $http, { buildQueryParamsFromList } from "@/helpers/http";
import Logger from "@/helpers/logger";

class BillingApi {
  getCurrentPlan() {
    try {
      return $http.get("panel/billing/get-current-plan");
    } catch (e) {
      Logger.error("`getCurrentPlan` api", e);
      return Promise.reject(e);
    }
  }

  getAllPlans(currency) {
    try {
      const products = Object.values(PRODUCTS);
      const partnerId = getPartnerId();
      return $http.get(
        `panel/billing/get-all-set-plans?${buildQueryParamsFromList(
          products,
          "orderTypes"
        )}&partnerId=${partnerId}&currency=${currency}`
      );
    } catch (e) {
      Logger.error("`getAllPlans` api", e);
      return Promise.reject(e);
    }
  }

  getAllAddons() {
    try {
      return $http.get("panel/billing/get-all-addons");
    } catch (e) {
      Logger.error("`getAllAddons` api", e);
      return Promise.reject(e);
    }
  }

  getCardDetails() {
    try {
      return $http.get("panel/billing/get-card-details");
    } catch (e) {
      Logger.error("`getCardDetails` api", e);
      return Promise.reject(e);
    }
  }

  checkPaymentType(action) {
    try {
      return $http.post("panel/payment/initPayment", { action });
    } catch (e) {
      Logger.error("`checkPaymentType` api", e);
      return Promise.reject(e);
    }
  }

  calculateCharge(paymentPayload) {
    try {
      return $http.post("panel/billing/calculate-charge", paymentPayload);
    } catch (e) {
      Logger.error("`calculateCharge` api", e);
      return Promise.reject(e);
    }
  }

  planProvisionCalculateCharge(paymentPayload) {
    try {
      return $http.post("internal/neo/calculate-charge", paymentPayload);
    } catch (e) {
      Logger.error("`calculateCharge` api", e);
      return Promise.reject(e);
    }
  }

  renewPlan(requestPayload) {
    try {
      return $http.post("/panel/billing/update-config", requestPayload);
    } catch (e) {
      Logger.error("`updateConfig - renewPlan` api", e);
      return Promise.reject(e);
    }
  }

  changeBillingCycle(requestPayload) {
    try {
      return $http.post("/panel/billing/update-config", requestPayload);
    } catch (e) {
      Logger.error("`updateConfig - changeBillingCycle` api", e);
      return Promise.reject(e);
    }
  }

  getAddCardIntent() {
    try {
      return $http.post("panel/billing/init-add-card");
    } catch (e) {
      Logger.error("`getAddCardIntent` api", e);
      return Promise.reject(e);
    }
  }
}

const billingApi = new BillingApi();

export default billingApi;
