import { getProductDefaultRoute } from "@/helpers";
import Logger from "@/helpers/logger";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const allowedProducts = to.meta?.products || []; // List of allowed products from route meta
      const products = vm.$store?.getters?.products || [];

      if (
        !allowedProducts?.length || // unprotected routes wont have products
        !products?.length // incase products is empty in store then proceed to next with warn msg
      ) {
        !products.length &&
          Logger.warn("Current plan product list is empty/no-set");
        return next();
      }

      if (
        // pass if current plan has support for product mentioned in route config
        allowedProducts.some((product) => products.includes(product))
      ) {
        next();
      } else {
        // Redirect if user doesn't have access to this route
        next({ path: getProductDefaultRoute(products) });
      }
    });
  },
};
