import defaultView from "./default";
export default {
  ...defaultView,
  name: "widget",
  hideSideBar: true,
  hideAppHeader: true,
  showBackButton: true,
  hideScrollbar: true,
  home: "ControlPanelWidget",
  // For titan max addon in widget view, we use fakedoor for now
  addonsWithFakedoor: ["titan_max"],
  backButtonForNavigation: true,
  showPoweredByTitan: true,
  openInternalURLSInSameTab: true,
  hideBrandInfoInTooltip: true,
  openPaymentURLInDiffTab: true,
  showLoadingTextWithLoader: true,
  showModalAsPage: true,
  initialRoute: "/partner/cpWidget",
  showBannerOnHomePage: true,
};
