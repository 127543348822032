import {
  getCommonMedusaParamsForBundleBillingEvents,
  sendBundleEvent,
} from "@/telemetry/medusaUtils";

const BUNDLE_EVENTS_MAP = {
  B_CONTROL_PANEL_LOGIN_BUTTON_CLICKED: "b_control_panel_login_button_clicked",
  B_CONTROL_PANEL_LOGGED_IN: "b_control_panel_logged_in",
  B_CONTROL_PANEL_LOGGED_OUT: "b_control_panel_logged_out",
  B_DOMAIN_SWITCHER_CLICKED: "b_domain_switcher_clicked",
  B_DOMAIN_SWITCHED: "b_domain_switched",
  B_CHANGE_DOMAIN_CLICKED: "b_change_domain_clicked",
  B_DOMAIN_AVAILABILITY_CHECKED: "b_domain_availability_checked",
  B_CHANGE_DOMAIN_REQUESTED: "b_change_domain_requested",
  B_ACCESS_APP_CLICKED: "b_access_app_clicked",
  B_SUPPORT_HOOK_CLICKED: "b_support_hook_clicked",
  B_PURCHASE_STARTED: "b_purchase_started",
  B_PURCHASE_DETAILS_ENTERED: "b_purchase_details_entered",
  B_ORDER_SUMMARY_VIEWED: "b_order_summary_viewed",
  B_PROCEED_PAYMENT_CLICKED: "b_proceed_payment_clicked",
  B_BILLING_DETAILS_SAVED: "b_billing_details_saved",
  B_UPDATE_CARD_CLICKED: "b_update_card_clicked",
  B_PAYMENT_CARD_SUBMITTED: "b_payment_card_submitted",
  B_PAYMENT_CARD_VERIFICATION_FAILED: "b_payment_card_verification_failed",
  B_PAYMENT_CARD_VERIFIED: "b_payment_card_verified",
  B_PAY_NOW_CLICKED: "b_pay_now_clicked",
  B_PAYMENT_FAILED: "b_payment_failed",
  B_PURCHASE_COMPLETED: "b_purchase_completed",
  B_PAYMENT_COMPLETED: "b_payment_completed",
  B_CHANGE_BILLING_CYCLE_CLICKED: "b_change_billing_cycle_clicked",
  B_BILLING_CYCLE_UPDATED: "b_billing_cycle_updated",
  B_REMOVE_CARD_CLICKED: "b_remove_card_clicked",
  B_AUTO_RENEW_CHANGED: "b_auto_renew_changed",
  B_BUY_MAILBOX_TAB_VIEWED: "b_buy_mailbox_tab_viewed",
  B_UPGRADE_TAB_VIEWED: "b_upgrade_tab_viewed",
  B_BILLING_TAB_VIEWED: "b_billing_tab_viewed",
  B_UPSELL_TAB_VIEWED: "b_upsell_tab_viewed",
  B_UPSELL_BUTTON_CLICKED: "b_upsell_button_clicked",
};

class BundleEvents {
  logCPLoginBtnClickedEvent(payload) {
    const eventObj = {
      ...payload,
      eventName: BUNDLE_EVENTS_MAP.B_CONTROL_PANEL_LOGIN_BUTTON_CLICKED,
    };
    sendBundleEvent(eventObj, false);
  }

  logCPLoggedInEvent(payload) {
    const eventObj = {
      ...payload,
      eventName: BUNDLE_EVENTS_MAP.B_CONTROL_PANEL_LOGGED_IN,
    };
    sendBundleEvent(eventObj);
  }

  logCPLoggedOutEvent(payload) {
    const eventObj = {
      ...payload,
      eventName: BUNDLE_EVENTS_MAP.B_CONTROL_PANEL_LOGGED_OUT,
    };
    sendBundleEvent(eventObj);
  }

  logDomainSwitcherClicked(payload) {
    const eventObj = {
      ...payload,
      eventName: BUNDLE_EVENTS_MAP.B_DOMAIN_SWITCHER_CLICKED,
    };
    sendBundleEvent(eventObj);
  }

  async logDomainSwitched(payload) {
    const eventObj = {
      ...payload,
      eventName: BUNDLE_EVENTS_MAP.B_DOMAIN_SWITCHED,
    };
    await sendBundleEvent(eventObj);
  }

  logChangeDomainClicked(payload) {
    const eventObj = {
      ...payload,
      eventName: BUNDLE_EVENTS_MAP.B_CHANGE_DOMAIN_CLICKED,
    };
    sendBundleEvent(eventObj);
  }

  logDomainAvailabilityChecked(payload) {
    const eventObj = {
      ...payload,
      eventName: BUNDLE_EVENTS_MAP.B_DOMAIN_AVAILABILITY_CHECKED,
    };
    sendBundleEvent(eventObj);
  }

  logChangeDomainRequested(payload) {
    const eventObj = {
      ...payload,
      eventName: BUNDLE_EVENTS_MAP.B_CHANGE_DOMAIN_REQUESTED,
    };
    sendBundleEvent(eventObj);
  }

  logAccessAppButtonClicked(payload) {
    const eventObj = {
      ...payload,
      eventName: BUNDLE_EVENTS_MAP.B_ACCESS_APP_CLICKED,
    };
    sendBundleEvent(eventObj);
  }

  logSupportHookClicked(payload) {
    const eventObj = {
      ...payload,
      eventName: BUNDLE_EVENTS_MAP.B_SUPPORT_HOOK_CLICKED,
    };
    sendBundleEvent(eventObj);
  }

  logPurchaseStarted(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_PURCHASE_STARTED,
    };
    sendBundleEvent(eventObj);
  }

  logAutoRenewChanged(payload) {
    const eventObj = {
      ...payload,
      eventName: BUNDLE_EVENTS_MAP.B_AUTO_RENEW_CHANGED,
    };
    sendBundleEvent(eventObj);
  }

  logPurchaseDetailsEntered(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_PURCHASE_DETAILS_ENTERED,
    };
    sendBundleEvent(eventObj);
  }

  logBuyMailboxTabViewed(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_BUY_MAILBOX_TAB_VIEWED,
    };
    sendBundleEvent(eventObj);
  }

  logOrderSummaryViewed(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_ORDER_SUMMARY_VIEWED,
    };
    sendBundleEvent(eventObj);
  }

  logProceedPaymentClicked(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_PROCEED_PAYMENT_CLICKED,
    };
    sendBundleEvent(eventObj);
  }

  logBillingDetailsSaved(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_BILLING_DETAILS_SAVED,
    };
    sendBundleEvent(eventObj);
  }

  logUpdateCardClicked(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_UPDATE_CARD_CLICKED,
    };
    sendBundleEvent(eventObj);
  }

  logRemoveCardClicked(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_REMOVE_CARD_CLICKED,
    };
    sendBundleEvent(eventObj);
  }

  logPaymentCardSubmitted(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_PAYMENT_CARD_SUBMITTED,
    };
    sendBundleEvent(eventObj);
  }

  logPaymentCardVerifiedFailed(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_PAYMENT_CARD_VERIFICATION_FAILED,
    };
    sendBundleEvent(eventObj);
  }

  logPaymentCardVerified(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_PAYMENT_CARD_VERIFIED,
    };
    sendBundleEvent(eventObj);
  }

  logPayNowClicked(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_PAY_NOW_CLICKED,
    };
    sendBundleEvent(eventObj);
  }

  logPaymentFailed(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_PAYMENT_FAILED,
    };
    sendBundleEvent(eventObj);
  }

  logPurchaseComplete(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_PURCHASE_COMPLETED,
    };
    sendBundleEvent(eventObj);
  }

  logPaymentComplete(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_PAYMENT_COMPLETED,
    };
    sendBundleEvent(eventObj);
  }

  logChangeBillingCycleClicked(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_CHANGE_BILLING_CYCLE_CLICKED,
    };
    sendBundleEvent(eventObj);
  }

  logBillingCycleUpdated(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_BILLING_CYCLE_UPDATED,
    };
    sendBundleEvent(eventObj);
  }

  logUpgradeTabViewed(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_UPGRADE_TAB_VIEWED,
    };
    sendBundleEvent(eventObj);
  }

  logBillingTabViewed() {
    const eventObj = {
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_BILLING_TAB_VIEWED,
    };
    sendBundleEvent(eventObj);
  }

  logUpsellTabViewed(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_UPSELL_TAB_VIEWED,
    };
    sendBundleEvent(eventObj);
  }

  logUpsellButtonClicked(payload) {
    const eventObj = {
      ...payload,
      ...getCommonMedusaParamsForBundleBillingEvents(),
      eventName: BUNDLE_EVENTS_MAP.B_UPSELL_BUTTON_CLICKED,
    };
    sendBundleEvent(eventObj);
  }
}

export default new BundleEvents();
