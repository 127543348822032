import { getPriceKeyParams, isMobile, planPricingKey } from "@/helpers/index";
import { isFreeBetaPlan } from "@/helpers/plansHelpers";
import { SUBSCRIPTION_KEYS, DOWNGRADE_PLAN_FORM_LINK } from "./const";

export const PRODUCTS = {
  MAIL_SUITE: "MailSuite",
  SITE: "Site",
  DOMAIN: "NeoDomain",
};

export const getProductDefaultRoute = (products) => {
  if (hasMailSuiteProduct(products)) return "/email-accounts";

  if (hasSiteProduct(products)) return "/manage-site";

  return "/product-not-found";
};

export const hasMailSuiteProduct = (products) => {
  return products.indexOf(PRODUCTS.MAIL_SUITE) > -1;
};

export const hasSiteProduct = (products) => {
  return products.indexOf(PRODUCTS.SITE) > -1;
};

export const hasDomainProduct = (products) => {
  return products.indexOf(PRODUCTS.DOMAIN) > -1;
};

export const isMailSuiteProduct = (product) => {
  return product === PRODUCTS.MAIL_SUITE;
};

export const isSiteProduct = (product) => {
  return product === PRODUCTS.SITE;
};

export const isDomainProduct = (product) => {
  return product === PRODUCTS.DOMAIN;
};

export const getProductsFromCurrentPlan = (currentPlanDetails) => {
  const products = [];

  if (currentPlanDetails?.mailSuiteDetails?.planId) {
    products.push(PRODUCTS.MAIL_SUITE);
  }

  if (currentPlanDetails?.siteDetails?.planId) {
    products.push(PRODUCTS.SITE);
  }

  if (currentPlanDetails?.domainDetails?.planId) {
    products.push(PRODUCTS.DOMAIN);
  }

  return products;
};

// FIXME: as per the function context, it also should return domain details
// check the impact and return domain details here
export const getAllProductsDetails = (currentPlanDetails) => {
  const productsDetails = [];

  if (currentPlanDetails?.mailSuiteDetails?.planId) {
    productsDetails.push(currentPlanDetails?.mailSuiteDetails);
  }

  if (currentPlanDetails?.siteDetails?.planId) {
    productsDetails.push(currentPlanDetails?.siteDetails);
  }

  return productsDetails;
};

/**
 * Transforms and combines product details, plan details, and trial information
 * into a single object for further use.
 * If the plan is a trial, additional paid plan info is included.
 */
export const formatProductDetails = (
  paidPlan,
  productPlanDetails,
  productDetails
) => {
  return {
    ...productDetails,
    status: deriveSubStatusFromFlags(productDetails.flags),
    planDetails: productPlanDetails,
    isTrial: productPlanDetails.isTrial,
    isPaid: productPlanDetails.isPaid,
    isFreeBetaPlan: isFreeBetaPlan(productPlanDetails),
    isProductSuspended: productDetails.isProductSuspended,
    ...(productPlanDetails.isTrial
      ? {
          paidPlanId: productPlanDetails.paidPlanId,
          paidPlan,
        }
      : {}),
  };
};

export const formatDomainDetails = (
  paidPlan,
  productPlanDetails,
  productDetails,
  currentDomain
) => {
  return {
    ...currentDomain,
    ...formatProductDetails(paidPlan, productPlanDetails, productDetails),
    planPricing:
      // domainPricing for backward compatibility
      productPlanDetails?.planPricing || productPlanDetails?.domainPricing,
  };
};

/**
 *
 * @param {array} flags - The Flags for current plan.
 * @returns {boolean} Returns true if current plan has subscription cancelled key includes in array; otherwise false
 */
export const isCancelSubFlagPresent = (flags) => {
  return flags?.includes(SUBSCRIPTION_KEYS.CANCEL_SUBSCRIPTION);
};

export const isReactiveSubFlagPresent = (domainFlags) => {
  return domainFlags?.includes(SUBSCRIPTION_KEYS.REACTIVATE_SUBSCRIPTION);
};

/**
 *
 * @param {array} flags - The Flags for current plan.
 * @returns {boolean} Returns true if current plan has expiry extension key includes i array, otherwise false
 */
export const isExpiryExtensionSubFlagPresent = (flags) => {
  return flags?.includes(SUBSCRIPTION_KEYS.EXPIRY_EXTENSION);
};

/**
 * Determines the subscription status based on specific flags
 * @param {Array} flags - SUBSCRIPTION_KEYS
 * @returns {String} SUBSCRIPTION_KEYS[key]
 */
export const deriveSubStatusFromFlags = (flags) => {
  if (isCancelSubFlagPresent(flags))
    return SUBSCRIPTION_KEYS.CANCEL_SUBSCRIPTION;

  return SUBSCRIPTION_KEYS.ACTIVE;
};

export const getActiveProducts = (currentPlanDetails) => {
  const activeProducts = [];
  const productDetails = [
    { key: "mailSuiteDetails", product: PRODUCTS.MAIL_SUITE },
    { key: "siteDetails", product: PRODUCTS.SITE },
    { key: "domainDetails", product: PRODUCTS.DOMAIN },
  ];

  productDetails.forEach(({ key, product }) => {
    if (
      currentPlanDetails?.[key]?.planId &&
      !currentPlanDetails?.[key]?.flags?.includes(
        SUBSCRIPTION_KEYS.CANCEL_SUBSCRIPTION
      ) &&
      !currentPlanDetails?.[key]?.isProductSuspended
    ) {
      activeProducts.push(product);
    }
  });

  return activeProducts;
};

/**
 * Checks if the product to cancel is the last cancellable product.
 *
 * @param {Array<string>} activeProducts - The list of active products.
 * @param {string} productToCancel - The product to cancel.
 * @param isSiteFreeBeta
 * @returns {boolean} - Returns true if, after removing the product to cancel and PRODUCTS.DOMAIN, the length of active products is 0.
 */
export const isLastCancellableProduct = (
  activeProducts,
  productToCancel,
  isSiteFreeBeta
) => {
  const filteredProducts = activeProducts.filter(
    (product) => product !== productToCancel && product !== PRODUCTS.DOMAIN
  );

  if (isSiteFreeBeta) {
    return (
      filteredProducts.filter((product) => product !== PRODUCTS.SITE).length ===
      0
    );
  }
  return filteredProducts.length === 0;
};

export const isProductCancelled = (productPurchaseStatus) => {
  return productPurchaseStatus === SUBSCRIPTION_KEYS.CANCEL_SUBSCRIPTION;
};

export const areAllProductsCancelled = (products) => {
  return products
    .filter((p) => p?.planId)
    .every((product) => isProductCancelled(product.status));
};

/**
 * Generates an order configuration object for flags.
 *
 * @param {Array<string>} products - The list of products.
 * @param {'set' | 'unset'} action - The action to perform on the flags, either 'set' or 'unset'.
 * @param {Array} keysArray - The array of keys to set or unset.
 * @returns {Object} - The order configuration object.
 */
export const getOrderConfigForProducts = (products, action, keysArray) => {
  if (action !== "set" && action !== "unset") {
    throw new Error("Invalid orderConfig action provided.");
  }

  const orderConfig = {};

  const actionsMap = {
    set: "setFlags",
    unset: "unsetFlags",
  };

  products.forEach((product) => {
    orderConfig[product] = {
      [actionsMap[action]]: keysArray,
    };
  });

  return orderConfig;
};

/**
 * Generates a URL for downgrading a plan with the specified parameters.
 *
 * @param {Object} params - Parameters for generating the URL.
 * @param {string} params.domainName - The domain name to include in the URL.
 * @param {string} params.product - The product to include in the URL.
 * @param {'cancel' | 'downgrade'} params.flowType - The flow type.
 * @param {string} params.billingCycle - The billing cycle of the product.
 * @param {number} params.planId - The plan ID of the product.
 * @param {number} params.price - The current renewal price of the product.
 * @returns {string} The generated URL with the provided parameters.
 */
export const getDowngradePlanLink = (params) => {
  const baseUrl = DOWNGRADE_PLAN_FORM_LINK;
  const device = isMobile() ? "mobile" : "desktop";

  // Mapping from params keys to query parameter keys
  const keyMapping = {
    domainName: "domain",
    product: "order",
    flowType: "flow_type",
    billingCycle: "billing_cycle",
    planId: "plan_id",
    price: "price",
    currentPrice: "current_price",
    renewalPrice: "renewal_price",
    // Add more mappings if more params are added
  };

  // Create an object to hold the query parameters
  const queryParams = {};

  // Map the params to query parameters using the keyMapping
  for (const [paramKey, paramValue] of Object.entries(params)) {
    if (paramValue) {
      // Use the mapped key if it exists, otherwise use the original key
      const queryKey = keyMapping[paramKey] || paramKey;
      queryParams[queryKey] = paramValue;
    }
  }

  // Add the device parameter
  queryParams.device = device;

  // Use URLSearchParams to construct the query string
  const urlParams = new URLSearchParams(queryParams);

  // Return the final URL
  return `${baseUrl}?${urlParams.toString()}`;
};

export const isBundleSubscriptionCancelled = (productDetailsArr) => {
  return productDetailsArr
    .filter((p) => p?.planId)
    .every((product) => isCancelSubFlagPresent(product.flags));
};

/**
 * Retrieves the current price for a given product based on the current plan details.
 *
 * @param {Object} productDetails - The details of the product.
 * @param {Object} currentPlanDetails - The details of the current plan.
 * @returns {number} - The current price of the product.
 */
export const getCurrentPriceForProduct = (
  productDetails,
  currentPlanDetails
) => {
  const pricingKey = planPricingKey(
    getPriceKeyParams(currentPlanDetails, productDetails)
  );

  return productDetails.planDetails.planPricing[
    currentPlanDetails.billingCycle
  ]?.[pricingKey];
};
