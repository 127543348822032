import Vue from "vue";
import Vuelidate from "vuelidate";

import { gracefullyLoadImage } from "@/helpers";
import { toast } from "../mixins";

export const loadMiddlewares = () => {
  Vue.use(Vuelidate);

  Vue.mixin(toast);

  Vue.filter("themeImage", function themeImage(filename, extension = "svg") {
    const fileNameAndExt = `${filename}.${extension}`;
    try {
      return gracefullyLoadImage(fileNameAndExt);
    } catch (e) {
      console.error(`Failed to find ${fileNameAndExt}`, e);
      return "#not-found";
    }
  });
};
