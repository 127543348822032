<template>
  <transition name="modal-fade">
    <div class="modal-overlay">
      <div
        :class="[
          'modal-body-container',
          {
            modalPage: view.showModalAsPage,
          },
        ]"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="manage admin rights"
      >
        <widget-breadcrumb-nav
          modalName="manage-admin-rights"
          :isModal="true"
          v-if="view.showModalAsPage"
          @onNavClick="close"
        />
        <button
          type="button"
          class="btn-close"
          @click="close"
          v-if="!view.showModalAsPage"
          aria-label="Close modal"
        >
          &times;
        </button>
        <div class="modal-content-wrapper">
          <div>
            <h4>
              {{
                $t(
                  `emailAccounts.manageAdminRights.${
                    user.isAdmin ? "disable" : "add"
                  }.heading`
                )
              }}
            </h4>
            <p class="email">{{ user.email }}</p>
            <p class="color-grey">
              {{
                $t(`emailAccounts.manageAdminRights.${actionType}.description`)
              }}
            </p>
            <div class="button-wrapper">
              <button
                ref="button"
                class="button confirmBtn"
                @click="updateAccount"
              >
                {{ $t(`emailAccounts.manageAdminRights.confirm`) }}
              </button>
              <button class="button button__line-blue cancelBtn" @click="close">
                {{ $t(`emailAccounts.manageAdminRights.cancel`) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManageAdminRights",
  props: {
    user: Object,
    view: Object,
  },
  computed: {
    ...mapGetters({
      domainName: "domain/domainName",
    }),
    actionType() {
      return this.user.isAdmin ? "disable" : "add";
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    updateAccount() {
      return this.http.postData(
        "panel/account/email/update/config",
        {
          accountId: this.user.id,
          domainName: this.domainName,
          accountConfig: {
            isAdmin: !this.user.isAdmin,
          },
        },
        this.$root.headers,
        this.onSuccess,
        this.onError
      );
    },
    onSuccess(data) {
      if (data) {
        this.close();
        this.$parent.getEmailList();
        const message = this.$t(
          `emailAccounts.manageAdminRights.${this.actionType}.message`,
          [this.user.email]
        );
        this.showToast(message, {
          type: "success",
          modalName: "manage-admin-rights",
        });
      }
    },
    onError(response) {
      if (response && response.data && response.data.desc) {
        this.close();
        this.showToast(response.data.desc, {
          type: "error",
          modalName: "manage-admin-rights",
        });
      }
    },
  },
  mounted() {
    if (this.$refs.button) {
      this.$refs.button.focus();
    }
  },
};
</script>
<style lang="scss">
.button-wrapper {
  .confirmBtn {
    background-color: var(--secondaryBtnCol);
  }
  .cancelBtn {
    border: 1px solid var(--secondaryBtnCol);
    color: var(--secondaryBtnCol);
  }
}
</style>
