import $http from "@/helpers/http";
import Logger from "@/helpers/logger";

class EmailApi {
  getEmailList() {
    try {
      return $http.get("panel/account/email/list");
    } catch (e) {
      Logger.error("`getEmailList` api", e);
      return Promise.reject(e);
    }
  }

  createEmail(payload) {
    try {
      return $http.post("panel/account/email/create", payload);
    } catch (e) {
      Logger.error("`createEmail` api", e);
      return Promise.reject(e);
    }
  }
}

const emailApi = new EmailApi();

export default emailApi;
