import { buildLookupQueryParams } from "@/helpers";
import $http from "@/helpers/http";
import Logger from "@/helpers/logger";

class DomainApi {
  getDomainList() {
    try {
      return $http.get("panel/domain/list");
    } catch (e) {
      Logger.error("`getDomainList` api", e);
      return Promise.reject(e);
    }
  }

  getDnsConfig({ lookups, ...params }) {
    try {
      const url = `/internal/domain/dns-banner-config?${buildLookupQueryParams(
        lookups
      )}`;
      return $http.get(url, { params });
    } catch (e) {
      Logger.error("`getDnsConfig` api", e);
      return Promise.reject(e);
    }
  }

  verifyDkim(params) {
    try {
      return $http.post("/panel/domain/dkim/enable", params);
    } catch (e) {
      Logger.error("`verifyDkim` api", e);
      return Promise.reject(e);
    }
  }
}

const domainApi = new DomainApi();

export default domainApi;
