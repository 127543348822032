// logger.js
const Logger = (() => {
  const levels = {
    info: "INFO",
    warn: "WARN",
    error: "ERROR",
  };

  const formatMessage = (message, level) => {
    const timestamp = new Date().toISOString();
    return `[${timestamp}] [${levels[level]}]: ${message}`;
  };

  const log = (level, ...logDetails) => {
    const [message, ...restLogDetails] = logDetails;
    const formattedMsg = formatMessage(message, level);
    console.log(
      ...(restLogDetails?.length
        ? [formattedMsg, ...restLogDetails]
        : [formattedMsg])
    );
  };

  return {
    info: (...logDetails) => log("info", ...logDetails),
    warn: (...logDetails) => log("warn", ...logDetails),
    error: (...logDetails) => log("error", ...logDetails),
    formatMessage, // Expose formatMessage in case you need it
  };
})();

export default Logger;
