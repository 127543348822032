<template>
  <div>
    <div class="main-wrapper-forgot">
      <div class="content-wrapper">
        <div class="logo-div">
          <h1>
            <img v-if="brandDetails.logo" :src="brandDetails.logo" alt="logo" />
          </h1>
        </div>
        <div class="forgetPass-box" v-if="!showNewPass">
          <h2 v-if="!showNewPass">
            {{ $t("resetPassword.enterUserName") }}
          </h2>
          <h2 v-if="showNewPass">
            {{ $t("resetPassword.resetPassLabel") }}
          </h2>
          <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
          <form
            id="forgotPass"
            @submit="submitFormEmail"
            method="post"
            novalidate="true"
          >
            <div>
              <label for="email">{{ $t("resetPassword.emailLabel") }}</label>
              <input
                type="text"
                name="email"
                id="email"
                v-model="email"
                placeholder="e.g. john.doe@example.com"
                :class="errors.email ? 'border-red' : ''"
              />
              <p v-if="errors.email" class="error">
                {{ errors.email }}
              </p>
            </div>
            <div v-if="iscustomerIdNeeded">
              <div class="customer-id-wrapper">
                <label for="customerId">{{
                  $t("resetPassword.customerId")
                }}</label>
                <a
                  class="contact-support"
                  :href="`mailto:${brandDetails.supportEmail}`"
                  >{{ $t("login.contactSupport") }}</a
                >
              </div>
              <input
                type="text"
                name="customerId"
                id="customerId"
                v-model="customerId"
                :class="errors.customerId ? 'border-red' : ''"
              />
              <p v-if="errors.customerId" class="error">
                {{ errors.customerId }}
              </p>
            </div>
            <div class="submit-btn-container">
              <titan-button
                class="submit-btn"
                :disabled="!email"
                buttonType="submit"
              >
                {{ $t("resetPassword.proceed") }}
              </titan-button>
            </div>
          </form>
        </div>
        <div class="newPass-box" v-if="showNewPass">
          <h2>
            {{
              $t(
                isResetPassword
                  ? "resetPassword.resetPassLabel"
                  : "resetPassword.setPassLabel"
              )
            }}
            <p class="mt-1">
              {{ $t("emailAccounts.resetPassword.for") }}
              <b>{{ serverEmail }}</b>
            </p>
          </h2>
          <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
          <form
            id="newPass"
            @submit="submitFormNewPass"
            method="post"
            novalidate="true"
          >
            <div class="password-wrapper">
              <label for="password" class="password-label">{{
                $t("resetPassword.newPass")
              }}</label>
              <div class="input-wrapper">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  id="newPassword"
                  v-model="password"
                  :class="newPassErrors.password ? 'border-red' : ''"
                />
                <span
                  :class="showPassword ? 'password-hidden' : 'password-shown'"
                  @click="togglePassword"
                  v-show="password.length > 0"
                ></span>
              </div>

              <p v-if="newPassErrors.password" class="error">
                {{ newPassErrors.password }}
              </p>
            </div>
            <div class="password-wrapper">
              <label for="password" class="password-label">{{
                $t("resetPassword.confirmPass")
              }}</label>

              <div class="input-wrapper">
                <input
                  :type="showconfirmPass ? 'text' : 'password'"
                  name="confirmPass"
                  id="confirmPassword"
                  v-model="confirmPass"
                  :class="newPassErrors.confirmPass ? 'border-red' : ''"
                />
                <span
                  :class="
                    showconfirmPass ? 'password-hidden' : 'password-shown'
                  "
                  @click="togglePassword('true')"
                  v-show="confirmPass.length > 0"
                />
              </div>

              <p v-if="newPassErrors.confirmPass" class="error">
                {{ newPassErrors.confirmPass }}
              </p>
            </div>
            <div class="submit-btn-container">
              <titan-button class="submit-btn" buttonType="submit">
                {{ $t("resetPassword.update") }}
              </titan-button>
            </div>
          </form>
        </div>
        <a
          class="contact-support"
          :href="`mailto:${brandDetails.supportEmail}`"
          >{{ $t("resetPassword.contactSupport") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getControlPanelSource, setWebsiteMetaData } from "@/helpers";
import brands from "@/helpers/brand";
import { measurePagePerformance } from "@/analytics/performance/pageTimings";

export default {
  name: "ResetPassword",
  computed: {
    ...mapGetters(["brandDetails"]),
  },
  data() {
    const pathname = window.location.pathname;
    const isResetPassword =
      pathname.indexOf("resetPassword") !== -1 ||
      pathname.indexOf("reset-password") !== -1;
    return {
      isResetPassword,
      errors: {},
      newPassErrors: {},
      email: "",
      logoUrl: null,
      errorMessage: "",
      showNewPass: false,
      password: "",
      confirmPass: "",
      showPassword: false,
      showconfirmPass: false,
      customerId: "",
      iscustomerIdNeeded: false,
      token: "",
      serverEmail: "",
      serverUsername: "",
    };
  },
  methods: {
    ...mapActions({
      storeBrandDetails: "brandDetails",
    }),
    submitFormEmail(e) {
      this.errors = {};
      e.preventDefault();
      if (!this.email) {
        this.errors.email = this.$i18n.t("resetPassword.emailError1");
      } else if (!this.validation.validateEmail(this.email)) {
        this.errors.email = this.$i18n.t("resetPassword.emailError2");
      } else if (
        this.validation.validateEmail(this.email) &&
        !this.iscustomerIdNeeded
      ) {
        this.http.postData(
          "panel/forgot-password",
          {
            email: this.email,
            source: getControlPanelSource(),
          },
          {},
          this.onSuccess,
          this.onError
        );
      } else {
        if (this.customerId.length === 0) {
          this.errors.customerId = this.$i18n.t("login.custIdError");
        }
        if (
          this.validation.validateEmail(this.email) &&
          this.iscustomerIdNeeded &&
          this.customerId
        ) {
          this.http.postData(
            "panel/forgot-password",
            {
              email: this.email,
              username: this.customerId,
              source: getControlPanelSource(),
            },
            {},
            this.onSuccess,
            (e) => {
              this.errorMessage = e.data.desc;
            }
          );
        }
      }
    },
    onSuccess() {
      this.showToast(this.$i18n.t("resetPassword.mailSuccessMsg"));
      this.$router.push({ name: "Login" });
    },
    onError(err) {
      if (err.data.code === "AMBIGUOUS_CREDENTIALS") {
        this.iscustomerIdNeeded = true;
        this.errorMessage = this.$i18n.t("resetPassword.ambiguous_cred");
      } else if (err.data.code === "OFAC_SANCTIONED_COUNTRY") {
        this.setOfacParams({
          isOfacFlag: true,
          ofacDocumentation: this.brandDetails.ofacDocumentation,
          supportEmail: this.brandDetails.supportEmail,
          brandLogo: this.logoUrl || this.brandDetails.logo,
        });
        this.medusaEvents.send(
          this.medusaEvents.createEventObj(
            {
              eventName: "ofac_login_denied",
              ofac_country: err.data.attrs.countryName,
              // is_first_login
            },
            smartStorage.getItem("domain"),
            "flock_order"
          )
        );
        this.errorMessage = err.data.desc;
      } else {
        this.errorMessage = err.data.desc;
      }
    },
    submitFormNewPass(e) {
      this.newPassErrors = {};
      e.preventDefault();
      if (!this.password) {
        this.newPassErrors.password = this.$i18n.t("resetPassword.passError1");
      } else if (this.password.length < 6) {
        this.newPassErrors.password = this.$i18n.t("resetPassword.passError2");
      } else if (
        this.password.length >= 6 &&
        this.password !== this.confirmPass
      ) {
        this.newPassErrors.confirmPass = this.$i18n.t(
          "resetPassword.passError3"
        );
      } else {
        let reqObj = {
          token: this.token,
          password: this.confirmPass,
          email: this.serverEmail,
        };
        if (this.serverUsername) {
          reqObj.username = this.serverUsername;
        }
        this.http.postData(
          "panel/reset-password",
          reqObj,
          {},
          this.onResetPassSuccess,
          () => {
            this.errorMessage = this.$i18n.t("resetPassword.tokenExpiredMsg");
          }
        );
      }
    },
    togglePassword(flag) {
      if (flag === "true") {
        this.showconfirmPass = !this.showconfirmPass;
      } else {
        this.showPassword = !this.showPassword;
      }
    },
    onResetPassSuccess() {
      this.showToast(this.$i18n.t("resetPassword.resetPassSuccess"));
      this.$router.push("/?email=" + this.serverEmail);
    },
    setBrandDetails(data) {
      this.storeBrandDetails({
        ...this.brandDetails,
        logo: data?.resources?.controlPanelLogoUrl || this.brandDetails.logo,
        supportEmail:
          data?.emails?.supportEmail || this.brandDetails.supportEmail,
      });
      setWebsiteMetaData.call(this);
    },
  },
  mounted() {
    measurePagePerformance();
  },
  created() {
    this.storeBrandDetails(brands.getDetails());
    this.getPartnerInfo({ headers: { "X-Auth": "noAuth" } })
      .then((data) => this.setBrandDetails(data))
      .finally(this.setBrandDetails);
    if (this.$route.query.token) {
      (this.showNewPass = true), (this.token = this.$route.query.token);
      this.serverEmail = this.$route.query.email;
      this.serverUsername = this.$route.query.username;
      let query = Object.assign({}, this.$route.query);
      delete query.token;
      delete query.email;
      delete query.username;
      this.$router.replace({ query });
      this.http.getData(
        "/panel/token-details?token=" + this.token,
        {},
        () => {},
        () => {
          if (this.isResetPassword) {
            this.showToast(this.$i18n.t("resetPassword.tokenExpiredMsg"));
          }
          this.$router.push({ name: "Login" });
        }
      );
    }
  },
};
</script>
<style lang="scss" scoped>
.main-wrapper-forgot {
  text-align: center;
  background: var(--primaryBgCol);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  background: url("../../assets/app-login-bg.png") no-repeat center center;
  .content-wrapper {
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .logo-div {
      margin-bottom: 25px;

      img {
        width: 157px;
        height: auto;
      }
    }
    .customer-id-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .contact-support {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: var(--fadedTextCol);
      text-decoration: underline;
    }
    .forgetPass-box,
    .newPass-box {
      padding: 46px 50px 50px;
      border-radius: 4px;
      margin-bottom: 40px;
      box-shadow: 0 2px 4px 0 var(--boxShadowCol3);
      background-color: var(--boxShadowCol8);
      text-align: left;

      div {
        position: relative;
      }
      h2 {
        font-size: 28px;
        font-weight: 400 !important;
        letter-spacing: normal;
        color: var(--header2Col);
        margin-bottom: 25px;
      }

      label {
        display: block;
        font-size: 14px;
        color: var(--primaryTextCol);
        text-align: left;
        margin-top: 16px;
      }

      .input-wrapper {
        margin-bottom: 0;
        position: relative;

        .password-shown,
        .password-hidden {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
        }
      }

      .error {
        margin-bottom: 0;
        text-align: left;
      }

      input {
        border-radius: 4px;
        width: 100%;
        margin-top: 2px;
        border: solid 1px var(--inputBorderCol);
        font-size: 14px;
        line-height: 20px;
        padding: 9px 15px;
        outline: none;
      }

      .border-red {
        border-color: var(--errorPrimaryTextCol);
      }
      .submit-btn-container {
        text-align: left;
        margin-top: 20px;
        input[type="submit"] {
          width: auto;
          display: inline-block;
          background: var(--disableCol);
          color: var(--tabBgCol);
          text-align: left;
        }
      }
      .active {
        background-color: var(--primaryBtnCol) !important;
        border-color: var(--primaryBtnCol);
      }
      .password-shown,
      .password-hidden {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 55px;
        right: 15px;
      }
      .border-red {
        border-color: var(--errorPrimaryTextCol);
      }
    }
  }
}

@import "../../styles/variables";
@media screen and (max-width: $responsive-breakpoint) {
  .main-wrapper-forgot {
    background: var(--tabBgCol) none;

    .content-wrapper {
      width: 100%;
      max-width: 400px;

      .logo-div {
        img {
          width: 120px;
        }
      }

      .forgetPass-box,
      .newPass-box {
        padding: 32px;
        text-align: center;
        box-shadow: none;

        h2 {
          font-size: 24px;
          font-weight: 600 !important;
        }

        form {
          label {
            font-size: 16px;
            font-weight: 600;
            color: var(--tertiaryTextCol);
            margin: 24px 0 8px;
          }

          input {
            padding: 15px;
            margin: 0;
          }

          .submit-btn {
            width: 100%;
            max-width: 500px;
            padding: 15px;
            margin-top: 24px;
          }
        }
      }
    }
  }
}
</style>
