import Vue from "vue";
import Toasted from "vue-toasted";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(Toasted, {
  singleton: true,
  duration: 4000,
  position: "bottom-center",
  action: {
    text: "×",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
});
