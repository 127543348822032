<template>
  <div
    class="font-600 font-14px card-container p-24"
    @click="isToggleTypeCard && $emit('toggle')"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    isToggleTypeCard: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
