import widget from "./widget";
import defaultView from "./default";
import mobile from "./mobile";
const viewTypes = {
  widget,
  mobile,
  defaultView,
};

export default viewTypes;
