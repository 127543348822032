<template>
  <div>
    <table class="vertical-table">
      <slot></slot>
    </table>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
table.vertical-table {
  align-self: flex-start;
  border-collapse: collapse;
  border-radius: 8px;
  border-style: hidden;
  width: 100%;
  box-shadow: 0 0 0 1px var(--lineSepaCol);

  td {
    word-break: break-word;
    border: 1px solid var(--lineSepaCol);
    @extend .p-12;
    padding-right: 17px;
    span {
      font-size: 12px;
    }
  }
  th {
    border: 1px solid var(--lineSepaCol);
    @extend .dark-row;
    @extend .p-12;
    @extend .pl-24;
    @extend .font-600;
    padding-right: 63px;
    width: 50%;
    span {
      font-size: 12px;
    }
  }
}
</style>
