// reference http://cactus.io/resources/toolbox/html-currency-symbol-codes
// https://html-css-js.com/html/character-codes/currency/
export const DEFAULT_CURRENCY = "USD";
export const CURRENCY_ENTITY_CODE = {
  USD: "&#36;",
  INR: "&#x20B9;",
  GBP: "&#163;",
  EUR: "&#128;",
  BRL: "&#82;&#36;",
  PEN: "&#83;&#47;",
};

export const getDefaultCurrency = () => DEFAULT_CURRENCY;

export const getAllCurrencies = () => CURRENCY_ENTITY_CODE;

/**
 * function to return currency symbol
 * if symbol is not present then just retun currency code
 */
export const getCurrencySymbol = (code) => {
  const CODE = (code || "").toUpperCase();
  return CURRENCY_ENTITY_CODE[CODE] || CODE;
};

/**
 * function to return amount with currency symbol
 * imp - always bind function to `this` before calling,
 */
export function bindCurrencySymbol(amount) {
  return `${this.currency}${amount === null ? "" : amount}`;
}
