<template>
  <div class="share-login-info-modal">
    <div class="modal-heading">
      <h4>{{ $t("shareLoginInfoModal.heading") }}</h4>
      <p>{{ email }}</p>
    </div>
    <div class="content">
      <h6>{{ $t("shareLoginInfoModal.content.line1") }}</h6>
      <p>{{ $t("shareLoginInfoModal.content.line2") }}</p>

      <div class="input-wrapper">
        <input
          type="text"
          class="input"
          :class="{ 'b-red': errorMsg }"
          v-bind:placeholder="$t('emailAccounts.resetPassword.placeholder')"
          v-model="alternateEmail"
          :disabled="processing"
          ref="emailInput"
        />
        <div class="error" v-if="errorMsg">{{ errorMsg }}</div>
      </div>

      <div class="button-wrapper">
        <button
          class="button"
          :disabled="!alternateEmail || processing"
          :class="{ processing: processing }"
          @click="handleSendEmailClick"
        >
          {{ $t("shareLoginInfoModal.button") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { COMMON_MODAL_EMITTERS, REGX } from "@/helpers/const";
import eventBus from "@/helpers/event-bus";
import { mapGetters } from "vuex";

export default {
  name: "ShareLoginInfoModal",
  data() {
    return {
      alternateEmail: "",
      errorMsg: "",
      processing: false,
    };
  },
  props: {
    email: String,
    mailboxAge: Number,
  },
  computed: {
    ...mapGetters({
      domainName: "domain/domainName",
    }),
  },
  mounted() {
    this.$refs.emailInput.focus();
  },
  methods: {
    setError(message) {
      this.errorMsg = message;
    },
    resetError() {
      this.errorMsg = "";
    },
    isValidEmail() {
      return this.alternateEmail.match(REGX.email);
    },
    validateForm() {
      if (!this.isValidEmail()) {
        const message = this.$t("emailAccounts.createEmail.invalidEmail");
        this.setError(message);
        return false;
      }
      return true;
    },
    handleSendEmailClick() {
      this.processing = true;
      this.resetError();

      if (!this.validateForm()) {
        this.processing = false;
        return;
      }

      // send login info email
      this.http.postData(
        "panel/account/email/reset-password",
        {
          email: this.email,
          recipient: this.alternateEmail,
          domainName: this.domainName,
          source: "Set password email",
        },
        this.$root.headers,
        (data) => {
          if (data) {
            const message = this.$t(
              `emailAccounts.resetPassword.passwordResetSuccessSentToEmail`,
              [this.alternateEmail]
            );
            this.showToast(message, {
              type: "success",
              modalName: "share-login-modal",
            });
            const eventObj = {
              eventName: "reset_password_link_sent",
              email: this.email,
              alternate_email_id: this.alternateEmail,
              source_hook: "Share login info popup",
              mailbox_age_in_days: this.mailboxAge,
            };
            this.medusaEvents.send(
              this.medusaEvents.createEventObj(
                eventObj,
                this.email,
                "flock_account"
              )
            );
            //close modal
            eventBus.$emit(COMMON_MODAL_EMITTERS.CLOSE_MODAL, {
              closeAllModals: true,
            });
          }
        },
        (response) => {
          if (response && response.data) {
            const message = this.$t(
              "emailAccounts.resetPassword.somethingWentWrong"
            );
            this.showToast(message, {
              type: "error",
              modalName: "share-login-modal",
            });
            this.processing = false;
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.share-login-info-modal {
  padding: 24px;

  .modal-heading {
    margin-bottom: 30px;

    p {
      font-size: 16px;
    }
  }

  .content {
    h6 {
      font-size: 14px;
    }

    p {
      color: var(--tertiaryTextCol);
      margin-bottom: 12px;
    }

    .button-wrapper {
      margin-top: 40px;
      .button {
        width: 100%;
        max-width: 170px;

        &:disabled {
          opacity: 0.5;
        }

        &.processing {
          background: var(--primaryBtnCol)
            url("../../assets/loading-spinner-white.svg") no-repeat 15px center /
            20px;
        }
      }
    }
  }
}
</style>
