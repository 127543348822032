import Logger from "@/helpers/logger";
import billingApi from "./billing.api";
import store from "@/store";
import { PRODUCTS } from "@/helpers/product";
import {
  transformToPlanProvisionRequest,
  transformPlanProvisionResponse,
  getDomainDetailsFromCurrentPlan,
  getMailSuiteDetailsFromCurrentPlan,
  getSiteDetailsFromCurrentPlan,
  transformOrderTransactionToCalculate,
} from "@/helpers/billingHelpers";

class BillingService {
  async getCurrentPlan() {
    try {
      const data = await billingApi.getCurrentPlan();

      const payload = {
        ...data,
        ...getMailSuiteDetailsFromCurrentPlan(data),
        ...getSiteDetailsFromCurrentPlan(data),
        ...getDomainDetailsFromCurrentPlan(data),
      };

      return Promise.resolve(payload);
    } catch (e) {
      Logger.error("`getCurrentPlan` service", e);
      return Promise.reject(e);
    }
  }

  async getAllPlans(currency) {
    try {
      const data = await billingApi.getAllPlans(currency);
      const plansGroupedByOrderType = data.plans.reduce((acc, order) => {
        // incase `orderType` missing (specifically for old data) then consider that as mail plan
        const { orderType = PRODUCTS.MAIL_SUITE } = order;
        acc[orderType] = acc[orderType] || [];
        acc[orderType].push(order);
        return acc;
      }, {});
      const mailPlanList = plansGroupedByOrderType[PRODUCTS.MAIL_SUITE] || [];
      const sitePlanList = plansGroupedByOrderType[PRODUCTS.SITE] || [];
      const domainPlanList = plansGroupedByOrderType[PRODUCTS.DOMAIN] || [];

      store.dispatch("mail/plans", mailPlanList);
      store.dispatch("site/plans", sitePlanList);
      store.dispatch("domain/plans", domainPlanList);

      return {
        [PRODUCTS.MAIL_SUITE]: mailPlanList,
        [PRODUCTS.SITE]: sitePlanList,
      };
    } catch (e) {
      Logger.error("`getAllPlans` service", e);
      return Promise.reject(e);
    }
  }

  async getAllAddons() {
    try {
      const data = await billingApi.getAllAddons();
      return data;
    } catch (e) {
      Logger.error("`getAllAddons` service", e);
      return Promise.reject(e);
    }
  }

  async getCardDetails() {
    try {
      const data = await billingApi.getCardDetails();
      store.dispatch("savedCard", data);
      return data;
    } catch (err) {
      if (err.data?.code === "CARD_NOT_FOUND") {
        store.dispatch("savedCard", null);
      }

      Logger.error("`getCardDetails` service", err);
      return Promise.resolve(null);
    }
  }

  checkPaymentType(action) {
    return billingApi.checkPaymentType(action);
  }

  async calculateCharge(reqPayload) {
    try {
      const data = await billingApi.calculateCharge(reqPayload);
      const resp = transformOrderTransactionToCalculate(data);
      return resp;
    } catch (e) {
      Logger.error("`calculateCharge` service", e);
      return Promise.reject(e);
    }
  }

  async planProvisionCalculateCharge(paymentPayload) {
    try {
      const { product, ...payload } = paymentPayload;
      const requestPayload = transformToPlanProvisionRequest(product, payload);
      const data = await billingApi.planProvisionCalculateCharge(
        requestPayload
      );
      return transformPlanProvisionResponse(
        product,
        payload,
        data,
        requestPayload.orderItems
      );
    } catch (e) {
      Logger.error("`planProvisionCalculateCharge` service", e);
      return Promise.reject(e);
    }
  }

  renewPlan(autoRenew, otherParams = {}) {
    try {
      const domainName = store.getters["domain/domainName"];
      return billingApi.renewPlan({
        domainName,
        autoRenew,
        ...otherParams,
      });
    } catch (e) {
      Logger.error("`updateConfig - renewPlan` service", e);
      return Promise.reject(e);
    }
  }

  changeBillingCycle(renewalBillingCycle) {
    try {
      const domainName = store.getters["domain/domainName"];
      return billingApi.changeBillingCycle({
        domainName,
        renewalBillingCycle,
      });
    } catch (e) {
      Logger.error("`updateConfig - changeBillingCycle` service", e);
      return Promise.reject(e);
    }
  }

  getAddCardIntent() {
    try {
      return billingApi.getAddCardIntent();
    } catch (e) {
      Logger.error("`getAddCardIntent` service", e);
      return Promise.reject(e);
    }
  }
}

const billingService = new BillingService();

export default billingService;
