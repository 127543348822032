export const LAMBDA_FUNCTION_NAMES = {
  CHECK_MAIL_ORDER_AVAILABILITY: "checkMailOrderAvailability",
  CHECK_DOMAIN_REGISTRATION: "checkDomainRegistration",
  CHECK_EMAIL_AVAILABILITY: "checkNeoEmailAvailability",
  GET_ENTRI_TOKEN: "getEntriToken",
};

export const LAMBDA_ENTITIES = {
  NEO: "neo",
  CONTROL_PANEL: "controlPanel",
};

export const getLambdaUrl = (entity, functionName) => {
  if (!functionName || !entity) {
    console.error("Entity or function name missing");
    return;
  }
  return `${process.env.VUE_APP_LAMBDA_BASE_URL}&entity=${entity}&functionName=${functionName}`;
};
