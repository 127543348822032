<template>
  <div class="container">
    <div class="progressBar">
      <div class="progressBarSteps" v-for="item in progressBarData">
        <div class="circleStepWithLabel">
          <div :class="item.classType"></div>
          <div :class="`${item.classType}_label`">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "stepProgressBar",
  props: {
    progressBarData: Array,
  },
};
</script>
<style lang="scss" scoped>
.container {
  align-items: center;
  margin: 40px auto;
  //   position: fixed;
  .progressBar {
    width: 660px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .progressBarSteps {
      display: flex;
      //   width: 220px;
      .circleStepWithLabel {
        display: flex;
        width: 220px;
        flex-direction: column;
        align-items: center;
        .circleStep_inProgress,
        .circleStep_completed,
        .circleStep_untouched {
          display: inline-block;
          background-color: var(--primaryBtnCol);
          width: 20px;
          height: 20px;
          border-radius: 50%;
          box-shadow: inset 0 0 0 3px var(--primaryBtnCol),
            inset 0 0 0 6px var(--tabBgCol);
          left: 240px;
          z-index: 1;
          top: -8px;
          &::before {
            top: 8px;
            display: block;
            position: relative;
            content: "";
            height: 4px;
            width: 10px;
            background: var(--primaryBtnCol);
            left: 20px;
          }
          &::after {
            display: block;
            position: relative;
            content: "";
            height: 4px;
            width: 210px;
            background-color: var(--lineSepaCol);
            left: 30px;
            top: 4px;
          }
        }
        .circleStep_completed {
          box-shadow: none;
          &::after {
            width: 210px;
            height: 4px;
            background: var(--primaryBtnCol);
            top: 8px;
            left: 13px;
          }
          &::before {
            content: none;
          }
        }
        .circleStep_untouched {
          box-shadow: none;
          background-color: var(--lineSepaCol);
          &::after {
            width: 210px;
            height: 4px;
            background: var(--lineSepaCol);
            top: 8px;
            left: 13px;
          }
          &::before {
            content: none;
          }
        }
        .circleStep_completed_label,
        .circleStep_completed_label,
        .circleStep_untouched_label {
          text-align: center;
        }
        .circleStep_untouched_label {
          color: var(--secondaryTextCol);
        }
      }
      .lineStrip {
        height: 4px;
        width: 210px;
        display: inline-block;
        background-color: var(--lineSepaCol);
      }
      &:last-child {
        .circleStepWithLabel {
          .circleStep_inProgress::after,
          .circleStep_inProgress::before,
          .circleStep_completed::after,
          .circleStep_completed::before,
          .circleStep_untouched::after,
          .circleStep_untouched::before {
            content: none;
          }
        }
      }
    }
  }
}
</style>
