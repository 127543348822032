import * as Sentry from "@sentry/vue";
import { getJWTpayloadData, replaceQueryParam, smartStorage } from ".";

export const getDefaultSentryExtraParams = () => {
  return {
    domain: smartStorage.getItem("domain") || "empty",
    partnerId: smartStorage.getItem("partnerId") || "empty",
    accountId: smartStorage.getItem("accountId") || "empty",
    loginEmail: smartStorage.getItem("loginEmail") || "empty",
    adminType: smartStorage.getItem("adminType") || "empty",
  };
};

export const sentryCaptureException = (err, tags = {}, extra = {}) => {
  Sentry.captureException(err, {
    tags: {
      ...tags,
    },
    extra: {
      ...extra,
      ...getDefaultSentryExtraParams(),
    },
  });
};

/**
 * do not pass jwt token in breadcrumbs
 * if jwt is present then replace with `dummy` keyword
 * added memoization to avoid repeated processing of same url (that includes searching and replacing jwt token)
 */
export const filterSentryBreadcrumb = (() => {
  const memoizedUrls = {};

  return (breadcrumb) => {
    if (breadcrumb?.category !== "navigation") return breadcrumb;

    const from = breadcrumb?.data?.from;
    const to = breadcrumb?.data?.to;

    if (from) {
      if (memoizedUrls[from]) {
        breadcrumb.data.from = memoizedUrls[from];
      }

      if (from?.indexOf("jwt=") > -1) {
        breadcrumb.data.from = replaceQueryParam(
          breadcrumb.data.from,
          "jwt",
          "dummy"
        );

        memoizedUrls[from] = breadcrumb.data.from;
      }
    }

    if (to) {
      if (memoizedUrls[to]) {
        breadcrumb.data.to = memoizedUrls[to];
      }

      if (to?.indexOf("jwt=") > -1) {
        breadcrumb.data.to = replaceQueryParam(
          breadcrumb.data.to,
          "jwt",
          "dummy"
        );

        memoizedUrls[to] = breadcrumb.data.to;
      }
    }

    return breadcrumb;
  };
})();

// function to remove sensitive data from sentry event
// mainly used to remove jwt token from sentry event (from breadcrumbs and trace data)
export function removeSensitiveDataFromSentryEvent(event) {
  if (Array.isArray(event?.breadcrumbs)) {
    event.breadcrumbs = event.breadcrumbs.map(filterSentryBreadcrumb);
  }

  const traceData = event?.contexts?.trace?.data;

  if (traceData?.query?.jwt) {
    traceData.query.jwt = "dummy";
  }

  return event;
}

export function getJWTPayloadToLogInSentry(jwt) {
  if (!jwt) return "invalid jwt";

  try {
    return getJWTpayloadData(jwt);
  } catch (e) {
    return {};
  }
}
