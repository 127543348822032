<template>
  <TitanToggle>
    <template v-slot="{ on, setOff, toggle }">
      <div class="titan-context-menu" v-on-clickaway="setOff">
        <slot :toggle="toggle" :on="on" :setOff="setOff"></slot>
        <ul v-if="on" :class="position" :style="menuStyles" @click="setOff">
          <template v-for="(item, index) in options">
            <li :key="index" @click="onItemClick(item, toggle)">
              <slot name="item" :index="index" :item="item">
                {{ item }}
              </slot>
            </li>
          </template>
          <slot name="content" :toggle="toggle" />
        </ul>
      </div>
    </template>
  </TitanToggle>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import TitanToggle from "./TitanToggle";

export default {
  props: {
    position: {
      default: "bottom-right",
      validator(value) {
        return [
          "bottom-right",
          "bottom-left",
          "top-right",
          "top-left",
        ].includes(value);
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  components: {
    TitanToggle,
  },
  computed: {
    menuStyles() {
      if (this.width) {
        return {
          width: this.width,
        };
      }
      return {};
    },
  },
  mixins: [clickaway],
  methods: {
    onItemClick(item, toggle) {
      this.$emit("click", item, toggle);
    },
  },
};
</script>

<style lang="scss" scoped>
.titan-context-menu {
  position: relative;
  display: inline-block;
}
ul {
  text-align: left;
  box-sizing: border-box;
  padding: 0.5em 0;
  position: absolute;
  z-index: 1;
  background: var(--tabBgCol) !important;
  box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.26);
  margin-top: 0.5em;
  &.bottom-right {
    left: 0;
  }
  &.bottom-left {
    right: 0;
  }
  @include borderRadius();
  /deep/ li {
    padding: 8px 22px;
    list-style: none;
    cursor: pointer;
    color: var(--primaryTextCol) !important;
    &:hover {
      background: var(--dropdownHoverCol) !important;
      color: var(--primaryTextCol) !important;
    }
  }
}
</style>
