var render = function render(){var _vm=this,_c=_vm._self._c;return _c('titan-modal',{attrs:{"closeBtnClass":'full mt-24',"closeBtnText":_vm.$t('welcomeScreen.continue')},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"welcome-header font-24px"},[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('welcomeScreen.header', [
            _vm.$t(`${_vm.brandDetails.panelName}`),
            _vm.domainName,
          ])
        )}}),(_vm.remainingDomains.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("welcomeScreen.and"))+" "),_c('span',{staticClass:"font-400 pointer",attrs:{"id":"other-domains"}},[_vm._v(_vm._s(_vm.remainingDomains.length)+" "+_vm._s(_vm.$t("welcomeScreen.other")))]),_c('b-popover',{attrs:{"target":"other-domains","triggers":"hover","placement":"bottom"}},[_c('ul',{staticClass:"p-0 list-style-none m-0"},_vm._l((_vm.remainingDomains),function(domain){return _c('li',{key:domain.id},[_vm._v(" "+_vm._s(domain.name)+" ")])}),0)])],1):_vm._e()])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"welcome-content b-grey br-8 mt-24"},[_c('div',[_vm._v(_vm._s(_vm.$t("welcomeScreen.content1", [_vm.loggedInUserDomain])))]),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('welcomeScreen.content2', [
            _vm.webMailUrl,
            _vm.loggedInUserEmail,
            `${_vm.brandDetails.name} ${_vm.$t(_vm.brandDetails.panelName)}`,
          ])
        )}})])]},proxy:true},{key:"close",fn:function(){return undefined},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }