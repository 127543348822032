import eventBus from "../helpers/event-bus";
import viewTypes from "../views";

export default {
  methods: {
    showToast(message, options = {}, delay = 0) {
      const view = viewTypes[document.documentElement.getAttribute("view")];
      if (view.showModalAsPage) {
        const { callback, keepModalOpen, modalName, type } = options;
        eventBus.$emit("handleWidgetToast", {
          callback,
          header: message,
          keepModalOpen,
          modalName,
          showWidgetToast: true,
          type,
        });
      } else {
        this.$toasted.clear();
        /* We have already specified the default options while using the Toasted plugin. */
        setTimeout(() => this.$toasted.show(message, options), delay);
      }
    },
  },
};
