// these events will be prefixed with "mail_", "site_" or "neo_domain_" depending on which product the event will be fired for
import { PRODUCTS } from "@/helpers";
import { DOMAIN_ORDER, ORDER, SITE_ORDER } from "@/helpers/const";
import store from "@/store";
import { sendOrderEvent } from "@/telemetry/medusaUtils";

const ORDER_EVENTS_MAP = {
  CANCEL_SUBSCRIPTION_CLICKED: "cancel_subscription_clicked",
  CANCEL_REASON_SELECTED: "cancel_reason_selected",
  CANCEL_REASON_DETAILS_ADDED: "cancel_reason_details_added",
  CANCEL_DISMISSED: "cancel_dismissed",
  CANCEL_SUBSCRIPTION_REQUESTED: "cancel_subscription_requested",
  DOWNGRADE_PLAN_CLICKED: "downgrade_plan_clicked",
  REACTIVATE_SUBSCRIPTION_CLICKED: "reactivate_subscription_clicked",
  SUBSCRIPTION_REACTIVATED: "subscription_reactivated",
  CONNECT_DOMAIN_TAB_VIEWED: "connect_domain_tab_viewed",
  CONNECT_DOMAIN_BANNER_VIEWED: "connect_domain_banner_viewed",
  CONNECT_DOMAIN_BANNER_CLICKED: "connect_domain_banner_clicked",
  CONNECT_DOMAIN_AUTOMATICALLY_CLICKED: "connect_domain_automatically_clicked",
  CONNECT_DOMAIN_PROVIDER_CLICKED: "connect_domain_provider_clicked",
  CONNECT_DOMAIN_STATUS_CHECKED: "connect_domain_status_checked",
  CONNECT_DOMAIN_COMPLETED: "connect_domain_completed",
  CONNECT_DOMAIN_FAILED: "connect_domain_failed",
};

class OrderEvents {
  logCancelSubscriptionClicked(product, payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(
        product,
        ORDER_EVENTS_MAP.CANCEL_SUBSCRIPTION_CLICKED
      ),
    };
    sendOrderEvent(eventObj);
  }

  logCancelReasonSelected(product, payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(product, ORDER_EVENTS_MAP.CANCEL_REASON_SELECTED),
    };
    sendOrderEvent(eventObj);
  }

  logCancelReasonDetailsAdded(product, payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(
        product,
        ORDER_EVENTS_MAP.CANCEL_REASON_DETAILS_ADDED
      ),
    };
    sendOrderEvent(eventObj);
  }

  logCancelDismissed(product, payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(product, ORDER_EVENTS_MAP.CANCEL_DISMISSED),
    };
    sendOrderEvent(eventObj);
  }

  logCancelSubscriptionRequested(product, payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(
        product,
        ORDER_EVENTS_MAP.CANCEL_SUBSCRIPTION_REQUESTED
      ),
    };
    sendOrderEvent(eventObj);
  }

  logDowngradePlanClicked(product, payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(product, ORDER_EVENTS_MAP.DOWNGRADE_PLAN_CLICKED),
    };
    sendOrderEvent(eventObj);
  }

  logReactivateSubscriptionClicked(product, payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(
        product,
        ORDER_EVENTS_MAP.REACTIVATE_SUBSCRIPTION_CLICKED
      ),
    };
    sendOrderEvent(eventObj);
  }

  logSubscriptionReactivated(product, payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(
        product,
        ORDER_EVENTS_MAP.SUBSCRIPTION_REACTIVATED
      ),
    };
    sendOrderEvent(eventObj);
  }

  logConnectDomainTabViewed(product, payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(
        product,
        ORDER_EVENTS_MAP.CONNECT_DOMAIN_TAB_VIEWED
      ),
    };
    sendOrderEvent(eventObj);
  }

  logMailConnectDomainBannerViewed(payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(
        PRODUCTS.MAIL_SUITE,
        ORDER_EVENTS_MAP.CONNECT_DOMAIN_BANNER_VIEWED
      ),
    };
    sendOrderEvent(eventObj);
  }

  logMailConnectDomainBannerClicked(payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(
        PRODUCTS.MAIL_SUITE,
        ORDER_EVENTS_MAP.CONNECT_DOMAIN_BANNER_CLICKED
      ),
    };
    sendOrderEvent(eventObj);
  }

  logSiteConnectDomainAutomaticallyClicked(payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(
        PRODUCTS.SITE,
        ORDER_EVENTS_MAP.CONNECT_DOMAIN_AUTOMATICALLY_CLICKED
      ),
    };
    sendOrderEvent(eventObj);
  }

  logConnectDomainProviderClicked(product, payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(
        product,
        ORDER_EVENTS_MAP.CONNECT_DOMAIN_PROVIDER_CLICKED
      ),
    };
    sendOrderEvent(eventObj);
  }

  logConnectDomainStatusChecked(product, payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(
        product,
        ORDER_EVENTS_MAP.CONNECT_DOMAIN_STATUS_CHECKED
      ),
    };
    sendOrderEvent(eventObj);
  }

  logConnectDomainCompleted(product, payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(
        product,
        ORDER_EVENTS_MAP.CONNECT_DOMAIN_COMPLETED
      ),
    };
    sendOrderEvent(eventObj);
  }

  logConnectDomainFailed(product, payload) {
    const eventObj = {
      ...payload,
      ...decorateOrderEvents(product, ORDER_EVENTS_MAP.CONNECT_DOMAIN_FAILED),
    };
    sendOrderEvent(eventObj);
  }
}

const decorateOrderEvents = (product, eventName) => {
  let plans, planId, orderId, productPrefix, userType, orderStatus;

  switch (product) {
    case PRODUCTS.MAIL_SUITE:
      plans = store.getters["mail/plans"];
      planId = +store.getters["mail/planId"];
      orderId = +store.getters["mail/orderId"];
      orderStatus = store.getters["mail/currentProductDetails"]?.status;
      productPrefix = "mail_";
      userType = ORDER;
      break;

    case PRODUCTS.SITE:
      plans = store.getters["site/plans"];
      planId = +store.getters["site/planId"];
      orderId = +store.getters["site/orderId"];
      orderStatus = store.getters["site/currentProductDetails"]?.status;
      productPrefix = "site_";
      userType = SITE_ORDER;
      break;

    case PRODUCTS.DOMAIN:
      plans = store.getters["domain/plans"];
      planId = +store.getters["domain/planId"];
      orderId = +store.getters["domain/domainID"];
      orderStatus = store.getters["domain/currentProductDetails"]?.status;
      productPrefix = "neo_domain_";
      userType = DOMAIN_ORDER;
      break;
  }

  return {
    eventName: `${productPrefix}${eventName}`,
    user_type: userType,
    plan_id: planId || null,
    plan_name: plans.filter((plan) => plan.id === planId)[0]?.displayName || "",
    order_id: orderId || null,
    order_status: orderStatus || "",
  };
};

export default new OrderEvents();
