import store from "@/store";
import domainApi from "./domain.api";
import Logger from "@/helpers/logger";

class DomainService {
  async getDomainList() {
    try {
      const data = await domainApi.getDomainList();
      store.dispatch("domainList", data.domains || []);
      return data;
    } catch (e) {
      Logger.error("`getDomainList` service", e);
      return Promise.reject(e);
    }
  }

  async getDnsConfig(params) {
    try {
      const data = await domainApi.getDnsConfig(params);
      store.dispatch("domain/domainConnectData", data);
      return data;
    } catch (e) {
      Logger.error("`getDnsConfig` service", e);
      return Promise.reject(e);
    }
  }

  async verifyDkim(params) {
    try {
      const data = await domainApi.verifyDkim(params);
      return data;
    } catch (e) {
      Logger.error("`verifyDkim` service", e);
      return Promise.reject(e);
    }
  }
}

const domainService = new DomainService();

export default domainService;
