<template>
  <div class="cardContainer">
    <transaction-failed-modal
      v-if="showTransactionFailedModal"
      @close="closeModal"
    />
    <success-screen
      :email="email"
      :purchasedPlanDetails="purchasedPlanDetails"
      v-if="showTransactionSuccessModal"
    />
    <div class="cardDetails">
      <button class="backBtn" @click="() => changeIndex(-1)">
        <img src="../../assets/back-arrow.svg" alt="" />
        {{ $t("purchaseFlow.goBack") }}
      </button>
      <div class="header">
        {{ $t("purchaseFlow.cardDetails.header") }}
      </div>
      <StripePaymentCard
        :shouldStartPayment="onBillingInfoSubmit"
        :currency="currencyCode"
        :paymentPayload="paymentPayload"
        :helperText="
          $t(
            isTrailPlan
              ? 'purchaseFlow.cardDetails.trailPlanHelperText'
              : 'purchaseFlow.cardDetails.paidPlanHelperText',
            [cr(scaleUpToTwoDecimals(this.chargeableAmount)), cr(0)] // 0 only applicable to trail plan
          )
        "
        :helperSubText="
          isTrailPlan
            ? $t('purchaseFlow.cardDetails.trailPlanHelperSubText', [
                cr(scaleUpToTwoDecimals(this.costPerAccount)),
                $t(
                  `billingCycles.${purchasedPlanDetails.billingCycle}`
                ).toLowerCase(),
                purchasedPlanDetails.defaultTrialDays,
              ])
            : ''
        "
        :billingCycle="purchasedPlanDetails?.billingCycle"
        :amountToBePaid="this.chargeableAmount"
        @toggleChecked="toggleChecked"
        :showCheckbox="true"
        :checkboxText="$t('purchaseFlow.cardDetails.checkboxText')"
        processPaymentAPI="/panel/purchase/complete-purchase"
        initPaymentAPI="/panel/purchase/initiate-purchase"
        :setAmountIncTax="setAmountIncTax"
        :authorizationHeader="purchaseAuthData.params.token"
        @onPaymentSuccess="onPaymentSuccess"
        @onPaymentFailed="onPaymentFailed"
        :hideUpdateCard="true"
        :domainProp="purchaseFlowJWTPayload.domainName"
        :loginEmailProp="purchaseFlowJWTPayload.customerEmailAddress"
        :showAutoRenew="!isTrailPlan"
        :isTrailPlan="isTrailPlan"
      />
    </div>
  </div>
</template>

<script>
import StripePaymentCard from "../Utility/StripePaymentCard";
import * as helper from "../../helpers";
import SuccessScreen from "./SuccessScreen";
import TransactionFailedModal from "../BillingAndUpgrade/TransactionFailedModal";
import { sendOrderAndCustomerEvent } from "@/telemetry/medusaUtils";
import { mapGetters } from "vuex";
import bundleEvents from "@/telemetry/bundleEvents";

export default {
  name: "CardDetails",
  components: {
    StripePaymentCard,
    SuccessScreen,
    TransactionFailedModal,
  },
  created() {
    this.preloadImage();
    if (this.purchaseAuthData.params.cardExists) {
      this.$store.dispatch("savedCard", {
        ...this.purchaseAuthData.params.cardDetails,
      });
    }
    this.paymentPayload.autoRenew = this.isChecked;
    if (
      this.purchaseFlowJWTPayload &&
      this.purchaseFlowJWTPayload.source &&
      this.purchaseFlowJWTPayload.sourceContext
    ) {
      this.paymentPayload.source = this.purchaseFlowJWTPayload.source;
      this.paymentPayload.sourceContext = this.purchaseFlowJWTPayload.sourceContext;
    }
    this.currency = helper.getCurrencySymbol(this.billingDetails.currency);
  },
  props: {
    changeIndex: Function,
    purchaseAuthData: Object,
    purchaseFlowJWTPayload: Object,
    partnerId: String,
    purchasedPlanDetails: Object,
  },
  data() {
    const { billingAddress } =
      JSON.parse(smartStorage.getItem("billingAddress")) || {};
    const { chargeableAmount, noOfAccounts } = this.purchasedPlanDetails;
    return {
      isChecked: true,
      manageCardModalType: null,
      showManageCardModal: false,
      showAddCardWarning: false,
      cr: helper.bindCurrencySymbol.bind(this),
      currency: null,
      email: billingAddress.email,
      billingDetails: this.purchasedPlanDetails,
      paymentPayload: this.purchasedPlanDetails,
      chargeableAmount: chargeableAmount,
      costPerAccount: this.scaleUpToTwoDecimals(
        chargeableAmount / noOfAccounts
      ),
      isTrailPlan: this.purchasedPlanDetails.isTrailPlan,
      currencyCode: this.purchasedPlanDetails.currency,
      showTransactionSuccessModal: false,
      showTransactionFailedModal: false,
    };
  },
  computed: {
    ...mapGetters(["savedCard", "paymentCardType"]),
  },
  methods: {
    scaleUpToTwoDecimals(num) {
      return helper.scaleUpToTwoDecimals(num);
    },
    preloadImage() {
      // eslint-disable-next-line no-undef
      const logo = require("../../assets/billingandUpgradeToPro.svg");
      let img = new Image();
      img.onload = function () {
        console.log("Loaded::::");
      };
      img.onerror = (err) => {
        console.log("Failed to downlaod", err);
      };
      img.src = logo;
    },
    toggleChecked() {
      this.isChecked = !this.isChecked;
      this.paymentPayload["autoRenew"] = this.isChecked;
    },
    closeModal() {
      this.showTransactionFailedModal = false;
    },
    setAmountIncTax(amount) {
      this.billingDetails.chargeableAmount = amount;
    },
    onBillingInfoSubmit() {
      return Promise.resolve();
    },
    onPaymentSuccess(res) {
      this.showTransactionSuccessModal = true;
      helper.proceedForAutoLogin(res);
      if (smartStorage.getItem("authToken")) {
        this.$root.headers = {
          headers: {
            Authorization: smartStorage.getItem("authToken"),
            "X-Auth": "controlPanel",
          },
        };
      }
      // Add Medusa events Here
    },
    onPaymentFailed(err) {
      this.showTransactionFailedModal = true;
      const medusaObject = {
        partner_id: this.purchaseAuthData.partnerInternalId,
        domain_name: this.purchaseFlowJWTPayload.domainName,
        customer_name: this.purchaseFlowJWTPayload.customerName,
        customer_email_address: this.purchaseFlowJWTPayload
          .customerEmailAddress,
        customer_country: this.purchaseFlowJWTPayload.customerCountry,
        Plan_selected: this.purchaseFlowJWTPayload.planType,
        number_of_accounts: this.purchasedPlanDetails.noOfAccounts,
        cause_of_failure: err?.message,
        error: err?.message,
        source: helper.getAppSource(),
        ...(this.paymentCardType && {
          payment_card_type: this.paymentCardType,
        }),
      };
      sendOrderAndCustomerEvent.call(
        this,
        {
          eventName: "payment_failed",
          ...medusaObject,
        },
        false
      );
      bundleEvents.logPaymentFailed(medusaObject);
    },
  },
};
</script>

<style lang="scss" scoped>
.cardDetails {
  width: 600px;
  border: 1px solid var(--lineSepaCol);
  margin: 0 auto;
  padding: 40px;
  .header {
    font-size: 20px;
    padding-top: 20px;
  }
  .backBtn {
    padding-left: 0;
    background: none;
    border: none;
    color: var(--secondaryTextCol);
  }
}
</style>
