<script>
export default {
  name: "LastAccountDeleteDialog",
  props: {
    user: Object,
    view: Object,
    onConfirmCreateAndDeleteEmail: Function,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-overlay">
      <div
        :class="[
          'modal-body-container',
          {
            modalPage: view.showModalAsPage,
          },
        ]"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <button
          type="button"
          class="btn-close"
          @click="close"
          v-if="!view.showModalAsPage"
          aria-label="Close modal"
        >
          &times;
        </button>
        <widget-breadcrumb-nav
          :isModal="true"
          modalName="last-delete-account"
          v-if="view.showModalAsPage"
          @onNavClick="close"
        />
        <div class="modal-content-wrapper">
          <div>
            <h4>{{ $t(`emailAccounts.deleteAndCreateNew.heading`) }}</h4>
            <p class="email">{{ user.email }}</p>
            <p
              class="color-grey custom-margin"
              v-html="
                $t(`emailAccounts.deleteAndCreateNew.deleteAccountQuestion`, [
                  user.email,
                ])
              "
            ></p>
            <div class="input-wrapper">
              <button class="button" @click="onConfirmCreateAndDeleteEmail()">
                {{ $t(`emailAccounts.deleteAndCreateNew.confirmBtn`) }}
              </button>
              <button class="button button__line-blue" @click="close">
                {{ $t(`emailAccounts.deleteAndCreateNew.cancelBtn`) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.custom-margin {
  margin-bottom: 46px;
}
</style>
