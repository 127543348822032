<template>
  <div class="flex titan-list-content" :class="className">
    <slot name="list-icon"></slot>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    className: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss">
.titan-list-content {
  align-items: baseline;
  header {
    font-size: 16px;
  }
  .bullet {
    margin-right: 16px;
  }
}
</style>
