import UAParser from "ua-parser-js";

let cachedDeviceInfo = null;
const parser = new UAParser();

export const getDeviceInfo = () => {
  if (cachedDeviceInfo) return cachedDeviceInfo;
  cachedDeviceInfo = parser.getResult();
  return cachedDeviceInfo;
};

export function getMobileOperatingSystem() {
  const deviceInfo = getDeviceInfo();
  return deviceInfo.os.name;
}

export const isIOSDevice = () => {
  const device = getMobileOperatingSystem();
  return device === "iOS";
};
