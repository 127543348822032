import Vue from "vue";
import VueI18n from "vue-i18n";
import enUS from "./lang-resources/en-us";
import store from "./store";

Vue.use(VueI18n);

const LanguageToFileNameMap = {
  en: "en-us",
  "en-us": "en-us",
  "en-in": "en-in",
  "es-es": "es-es",
  "pt-br": "pt-br",
  "fr-fr": "fr-fr",
  "ru-ru": "ru-ru",
  "sv-se": "sv-se",
  "de-de": "de-de",
  "nl-be": "nl-be",
  "zh-hans-cn": "zh-hans-cn",
  "zh-hant-tw": "zh-hant-tw",
  "zh-hk": "zh-hk",
  "it-it": "it-it",
  es: "es-es",
  fr: "fr-fr",
  nl: "nl-be",
  de: "de-de",
  sv: "sv-se",
  ru: "ru-ru",
  id: "id",
  tr: "tr",
  it: "it-it",
  ko: "ko",
  ja: "ja",
  lt: "lt-lt",
};

const messages = {
  "en-us": enUS,
  en: enUS,
};

const langList = {
  "en-us": "English (US)",
  "en-in": "English (IN)",
  "pt-br": "Português (Brasil)",
  "ru-ru": "русский (Russia)",
  "sv-se": "Swedish (Sweden)",
  "de-de": "German (Germany)",
  "nl-be": "Dutch (Belgium)",
  "zh-hans-cn": "Chinese Simplified (China)",
  "zh-hant-tw": "Chinese Traditional (Taiwan)",
  "zh-hk": "Chinese Traditional (Hong Kong)",
  es: "Español",
  fr: "Française",
  id: "bahasa Indonesia",
  tr: "Türkçe",
  it: "Italiano (Italian)",
  ko: "Korean",
  ja: "Japanese",
  "lt-lt": "Lithuanian",
  "es-es": "Español",
  "fr-fr": "Française",
  "it-it": "Italiano (Italian)",
};

// lang preference as follows
// 1 - lang param in URL
// 2 - lang value from browser
// 3 - en default (Also if any missing translation `en` lang is fallback)
const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "en", // set fallback locale
  messages, // set locale messages
  silentTranslationWarn: true,
});
const loadedLanguages = ["en"];
function setI18nLanguage(lang) {
  document.documentElement.setAttribute("lang", lang);
  i18n.locale = lang;
  store.dispatch("locale", lang);
  return lang;
}

export function loadCurrentLanguageFile(locale) {
  let lang = locale
    .replace(/\_/g, "-") // Replace underscores with dashes
    .toLowerCase();
  // We have also kept the language file names in lowercase only to keep the mapping consistent
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }
  let languageFileName = LanguageToFileNameMap[lang];
  if (languageFileName === undefined) {
    const parentLanguage = lang.split("-")[0];
    languageFileName = LanguageToFileNameMap[parentLanguage];
    if (languageFileName === undefined) {
      languageFileName = "en";
    }
  }
  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "lang-[request]" */ `@/lang-resources/${languageFileName}.js` // Importing files dynamically
  )
    .then((messages) => {
      i18n.setLocaleMessage(lang, messages.default); // setting i18 messages
      loadedLanguages.push(lang);
      return setI18nLanguage(lang);
    })
    .catch((err) => {
      console.error("failed to downlaod language file with error:", err);
    });
}

export const translate = (key, options, defaultValue) => {
  const translation = i18n.t(key, options);
  // If translation is not found, return the default value
  if (translation === key) {
    return defaultValue;
  }
  return translation;
};

export { i18n, langList };
