import { dateDiff, dayDiff, isSizeInGB, toGB } from ".";
import { BILLING_MONTHS, ORDER_STATUS } from "./const";
import { isMailSuiteProduct, isSiteProduct, PRODUCTS } from "./product";
/**
 * Transforms a billing cycle string by replacing underscores with spaces and optionally capitalizing each word.
 *
 * @param {string} billingCycle - The billing cycle string to format.
 * @param {boolean} [shouldCapitalize=false] - Flag indicating whether the first letter of each word should be capitalized.
 * @returns {string} The formatted billing cycle string.
 */
export const getFormattedBillingCycle = (
  billingCycle,
  shouldCapitalize = false
) => {
  let string = billingCycle.replace(/_/g, " ");

  if (shouldCapitalize) {
    string = string.replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  return string;
};

/**
 *
 * @param {String[]} billingCycles
 */
export const sortBillingCycles = (billingCycles) => {
  if (!billingCycles) {
    return billingCycles;
  }

  return billingCycles.sort((a, b) => {
    const a1 = BILLING_MONTHS[a] || Number.MAX_SAFE_INTEGER;
    const a2 = BILLING_MONTHS[b] || Number.MAX_SAFE_INTEGER;
    return a1 - a2;
  });
};

export const getMinEmailAccountCount = (selectedRecord, currentPlan) => {
  if (!currentPlan.mailSuiteDetails?.planId) return 1;

  const upgradeToSamePlan =
    currentPlan.mailSuiteDetails.planDetails.isTrial &&
    selectedRecord.id === currentPlan.mailSuiteDetails.paidPlan.id;
  const minCount = upgradeToSamePlan
    ? currentPlan.noOfAccounts.limit + 1 // +1 to avoid 0 billing total
    : (currentPlan.mailSuiteDetails.planDetails.isPaid
        ? currentPlan.noOfAccounts.purchased
        : currentPlan.noOfAccounts.active) || 1; // default 1
  return minCount;
};

export const getPlanFeatureOrder = (product) => {
  const mailFeatureOrder = {
    storage: 1,
    mobile_apps: 2,
    web_mail: 3,
    calendar: 4,
    read_receipts: 5,
    video_calling: 6,
  };

  // FIXME: set feat order for site
  return isSiteProduct(product) ? mailFeatureOrder : mailFeatureOrder;
};

export const getPlanRemainingDaysAndMonths = (expiresOn) => {
  const remainingDays = dayDiff(expiresOn);
  const remainingMonths = dateDiff(new Date(), expiresOn);

  // < 1.5 month = 2month
  if (remainingMonths.monthDiff >= 1 && remainingMonths.dayDiff > 15) {
    remainingMonths.monthDiff++;
  }

  return {
    remainingDays,
    remainingMonths,
  };
};

export const processFeatureAttributes = (f) => {
  switch (f.type) {
    case "storage":
      f.attrs = { ...f.attrs }; // create new copy of attr and then update, do not update original data
      const quota = parseInt(f.attrs.quota_in_mb, 10);
      const convertedQuota = (
        f.attrs.quota_in_gb || toGB(f.attrs.quota_in_mb)
      ).toString();
      if (isSizeInGB(quota)) {
        f.attrs.quota_in_gb = convertedQuota;
        delete f.attrs.quota_in_mb;
      } else {
        f.attrs.quota_in_mb = convertedQuota;
      }
      break;

    // Future cases for other feature types can be added here
    default:
      break;
  }

  return f;
};

export const isPaidDomain = (currentPlan) => {
  return !!(
    currentPlan?.domainPricingAttr &&
    Object.keys(currentPlan.domainPricingAttr).length
  );
};

export const transformToPlanProvisionRequest = (product, payload) => {
  return {
    bundleId: payload.bundleId,
    orderItems: {
      ...(isSiteProduct(product)
        ? {
            [PRODUCTS.SITE]: {
              planType: payload.provision.planType,
              state: 4,
            },
          }
        : {}),
      ...(isMailSuiteProduct(product)
        ? {
            [PRODUCTS.MAIL_SUITE]: {
              planType: payload.provision.planType,
              state: 4,
              noOfAccounts: payload.purchase.accounts.noOfAccounts,
            },
          }
        : {}),
    },
    domainToBeCreated: payload.domainName,
    billingCycle: payload.billingCycle,
  };
};

// this parser is added to transform new `orderTransitionDetails` object to old/existing calculation object
// calculation - bll is going to deprecate
// since the UI is build with consideration of calculation object and hence to avoid big changes in existing flow, we have introduced this parser
// later we can tweak the UI and start using `orderTransitionDetails` and remove this parser
export const transformOrderTransactionToCalculate = (data) => {
  if (!data.orderTransitionDetails?.length) return data;

  const mailOrder = data.orderTransitionDetails.find(
    (o) => o.orderType === PRODUCTS.MAIL_SUITE
  );
  const siteOrder = data.orderTransitionDetails.find(
    (o) => o.orderType === PRODUCTS.SITE
  );
  const domainOrder = data.orderTransitionDetails.find(
    (o) => o.orderType === PRODUCTS.DOMAIN
  );

  // incase of purchase there will be either mail or site
  // incase of renew and bundle has both products then mail product is considered for planId etc.
  const mainProduct = mailOrder || siteOrder;

  const calculation = {
    beforeUpdate: {
      planId: mainProduct?.beforeUpdate?.planId,
      expiresOn: mainProduct?.beforeUpdate?.expiresOn,
      noOfAccounts: mainProduct?.beforeUpdate?.noOfAccount,
      billingCycle: mainProduct?.beforeUpdate?.billingCycle,
      chargePerMonth: mainProduct?.beforeUpdate?.chargePerMonth,
    },
    afterUpdate: {
      planId: mainProduct?.afterUpdate?.planId,
      expiresOn: mainProduct?.afterUpdate?.expiresOn,
      noOfAccounts: mainProduct?.afterUpdate?.noOfAccount,
      billingCycle: mainProduct?.afterUpdate?.billingCycle,
      chargePerMonth: mainProduct?.afterUpdate?.chargePerMonth,
    },
    addons: [
      ...(mailOrder?.addons || []),
      ...(siteOrder?.addons || []),
      ...(domainOrder?.addons || []),
    ],
    carryForwardAmount: {
      fromPlan: mailOrder?.carryForwardAmount?.fromPlan || 0,
      fromSite: siteOrder?.carryForwardAmount?.fromPlan || 0,
      fromDomain: domainOrder?.carryForwardAmount?.fromPlan || 0,
      fromAddons:
        (mailOrder?.carryForwardAmount?.fromAddons || 0) +
        (siteOrder?.carryForwardAmount?.fromAddons || 0) +
        (domainOrder?.carryForwardAmount?.fromAddons || 0),
      total:
        (mailOrder?.carryForwardAmount?.total || 0) +
        (siteOrder?.carryForwardAmount?.total || 0) +
        (domainOrder?.carryForwardAmount?.total || 0),
    },
    planPurchaseCharges: {
      purchaseCharge: mailOrder?.totalAmount || 0,
    },
    addonPurchaseCharges: [
      ...(mailOrder?.addonPurchaseCharges || []),
      ...(siteOrder?.addonPurchaseCharges || []),
      ...(domainOrder?.addonPurchaseCharges || []),
    ],
    durationBilledFor: data.durationBilledFor,
    totalAmount: data.totalAmount || 0,
    remainingAmountToBePaid: data.amountToBePaid || 0,
    totalDomainCharge: domainOrder?.totalAmount || 0,
    totalSiteCharge: siteOrder?.totalAmount || 0,
  };

  return {
    ...data,
    calculation,
  };
};

export const transformPlanProvisionResponse = (
  product,
  req,
  res,
  orderItems
) => {
  return {
    amountToBePaid: res.totalAmount,
    minimumSupportedAmount: res.minimumSupportedAmount,
    domainPricingAttr: res.orderPricing[PRODUCTS.DOMAIN]?.pricingAttr,
    sitePricingAttr: isSiteProduct(product)
      ? res.orderPricing[PRODUCTS.SITE].pricingAttr
      : null,
    calculation: {
      beforeUpdate: null,
      afterUpdate: {
        noOfAccounts: req.purchase?.accounts?.noOfAccounts,
        expiresOn: res.expiryAfterUpdate,
        billingCycle: req.billingCycle,
      },
      addons: [],
      carryForwardAmount: null,
      durationBilledFor: res.durationBilledFor || {},
      totalAmount: res.totalAmount,
      totalDomainCharge: res.orderPricing[PRODUCTS.DOMAIN]?.totalAmount,
    },
    postPurchaseRenewalCharges: res.postPurchaseRenewalCharges,
    currency: res.currency,
    totalAmount: res.totalAmount, // TODO: ideally this should exclude the coupon discount which BE will do later in future
    orderItems,
  };
};

export const getMailSuiteDetailsFromCurrentPlan = (currentPlan) => {
  const mailSuiteDetails = currentPlan?.orderDetails?.[PRODUCTS.MAIL_SUITE];
  const isLatestAPIResp = currentPlan?.orderDetails;

  if (isLatestAPIResp && !mailSuiteDetails) return { mailSuiteDetails: {} };

  if (!isLatestAPIResp && !mailSuiteDetails) {
    // to handle API backward compatibility
    return {
      mailSuiteDetails: {
        planId: currentPlan.planId,
        noOfAccounts: currentPlan.noOfAccounts,
        amountToBePaidOnRenewal: currentPlan.amountToBePaidOnRenewal,
        flags: currentPlan.domainFlags,
        addons: currentPlan.addons,
        expiry: currentPlan.expiresOn,
        product: PRODUCTS.MAIL_SUITE,
      },
    };
  }

  return {
    mailSuiteDetails: {
      orderId: mailSuiteDetails?.id,
      planId: mailSuiteDetails?.planId,
      noOfAccounts: mailSuiteDetails?.accountQuota,
      amountToBePaidOnRenewal: mailSuiteDetails?.totalRenewalCharge,
      flags: mailSuiteDetails?.flags,
      createdAt: mailSuiteDetails?.createdAt,
      addons: currentPlan.addons,
      isProductSuspended: mailSuiteDetails?.status === ORDER_STATUS.SUSPENDED,
      expiry: mailSuiteDetails?.expiry,
      product: PRODUCTS.MAIL_SUITE,
    },
  };
};

export const getSiteDetailsFromCurrentPlan = (currentPlan) => {
  const siteDetails = currentPlan?.orderDetails?.[PRODUCTS.SITE];

  // no need to check isLatestAPIResp, as in old flow we never had plans and plan id for site, hence empty
  if (!siteDetails) return { siteDetails: {} };

  return {
    siteDetails: {
      orderId: siteDetails?.id,
      planId: siteDetails?.planId,
      amountToBePaidOnRenewal: siteDetails?.totalRenewalCharge,
      flags: siteDetails?.flags,
      createdAt: siteDetails?.createdAt,
      addons: [],
      product: PRODUCTS.SITE,
      isProductSuspended: siteDetails?.status === ORDER_STATUS.SUSPENDED,
      expiry: siteDetails?.expiry,
    },
  };
};

export const getDomainDetailsFromCurrentPlan = (currentPlan) => {
  const domainDetails = currentPlan?.orderDetails?.[PRODUCTS.DOMAIN];
  const isLatestAPIResp = currentPlan?.orderDetails;

  if (isLatestAPIResp && !domainDetails) return { domainDetails: {} };

  if (
    !isLatestAPIResp &&
    !domainDetails &&
    Object.keys(currentPlan.domainPricingAttr || {}).length
  ) {
    // this is for old flow, where we used to derived isPaidDomain based on `domainPricingAttr`
    // if domainPricingAttr that means we need to keep the domain details
    return {
      domainDetails: {
        addons: [],
        amountToBePaidOnRenewal: currentPlan.totalDomainCharge,
        createdAt: null,
        flags: currentPlan.domainFlags,
        orderId: null, // should be domain Id
        planId: currentPlan.planId,
        pricingAttr: currentPlan.domainPricingAttr,
        expiry: currentPlan.expiresOn,
        product: PRODUCTS.DOMAIN,
      },
    };
  }

  return {
    domainDetails: {
      addons: [],
      amountToBePaidOnRenewal: domainDetails.totalRenewalCharge,
      createdAt: domainDetails?.createdAt,
      flags: domainDetails.flags,
      orderId: domainDetails?.id,
      planId: domainDetails.planId,
      pricingAttr: currentPlan.domainPricingAttr,
      isProductSuspended: domainDetails?.status === ORDER_STATUS.SUSPENDED,
      expiry: domainDetails?.expiry,
      product: PRODUCTS.DOMAIN,
    },
  };
};
