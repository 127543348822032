var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.hasForgoPayments)?_c('div',{staticClass:"stripe-payment-card"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showSavedCard),expression:"!showSavedCard"}],ref:"card",attrs:{"id":"card-element"}}),(_vm.showSavedCard)?_c('div',{staticClass:"saved-card"},[_c('span',{staticClass:"card-number"},[_vm._v("XXXX XXXX XXXX "+_vm._s(_vm.savedCard.lastFourDigits)+" ")])]):_vm._e(),(!_vm.hideUpdateCard)?_c('div',{staticClass:"update-card",on:{"click":_vm.showStripCardField}},[_vm._v(" "+_vm._s(_vm.$t("stripPaymentCard.updateCard"))+" ")]):_vm._e(),(_vm.cardValidationError)?_c('span',{staticClass:"error",domProps:{"innerHTML":_vm._s(_vm.cardValidationError)}}):_vm._e(),(!_vm.skipPaymentCharge)?_c('p',{class:['helper-text', _vm.showCheckbox && 'amountText'],domProps:{"innerHTML":_vm._s(_vm.helperText)}}):_vm._e(),(_vm.showCheckbox)?_c('div',{staticClass:"checkbox"},[(_vm.helperSubText)?_c('p',{staticClass:"sub-helper-text",domProps:{"innerHTML":_vm._s(_vm.helperSubText)}}):_vm._e(),(_vm.showAutoRenew)?_c('input',{attrs:{"type":"checkbox","id":"checkboxContent","name":"checkboxContent","value":"checkboxContent","checked":""},on:{"change":_vm.toggleChecked}}):_vm._e(),(_vm.showAutoRenew)?_c('label',{attrs:{"for":"checkboxContent"}},[_vm._v(_vm._s(_vm.checkboxText))]):_vm._e()]):_vm._e(),_c('button',{class:{
      'show-spinner': _vm.showButtonSpinner,
      'mt-16': _vm.skipPaymentCharge,
    },attrs:{"disabled":_vm.showButtonSpinner},domProps:{"innerHTML":_vm._s(
      _vm.buttonText ||
      _vm.$t(
        _vm.skipPaymentCharge
          ? 'stripPaymentCard.payZero'
          : 'stripPaymentCard.payNow',
        { currencyCode: _vm.currencyCode }
      )
    )},on:{"click":function($event){return _vm.onPayNowClicked()}}})]):_c('div',{staticClass:"stripe-payment-card"},[(_vm.hasForgoPayments)?_c('div',{staticClass:"forgo-payments-info"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("billingAndSubscription.paymentMethodWithPartner", [ _vm.partnerCompanyName, ]))+" ")])]):_vm._e(),_c('p',{staticClass:"helper-text",domProps:{"innerHTML":_vm._s(_vm.forgoModeHelperText)}}),_c('button',{class:{ 'show-spinner': _vm.showButtonSpinner },attrs:{"disabled":_vm.showButtonSpinner},on:{"click":function($event){return _vm.onPayNowClicked()}}},[_vm._v(" "+_vm._s(_vm.buttonText || _vm.$t("stripPaymentCard.payNow"))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }