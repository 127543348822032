<template>
  <div class="password-actions">
    <p>
      <span class="font-600">{{ $t("utility.passLbl") }}&nbsp;</span>
      <span>{{
        showPassword
          ? password
          : "&bullet;&bullet;&bullet;&bullet;&bullet;&bullet;&bullet;&bullet;&bullet;&bullet;&bullet;&bullet;"
      }}</span>
      <span
        v-if="showPassword"
        @click="togglePassword"
        class="password-action-btn"
        >{{ $t("utility.hide") }}</span
      >
      <span v-else @click="togglePassword" class="password-action-btn">{{
        $t("utility.show")
      }}</span>
      <span
        :class="[
          'password-action-btn',
          { 'modal-copy-btn': view.showModalAsPage && copySuccess },
        ]"
        @click="copyToClipboard"
        >{{ copyText }}</span
      >
    </p>
  </div>
</template>

<style scoped lang="scss">
.password-action-btn {
  padding: 0 10px 1px;
  border: 1px solid var(--disableCol);
  border-radius: 10px;
  font-size: 11px;
  margin-left: 8px;
  color: var(--primaryTextCol);
  cursor: pointer;

  &:last-of-type {
    margin-left: 4px;
  }
}

.modal-copy-btn {
  color: var(--primaryBtnCol);
  border: 1px solid var(--primaryBtnCol);
}
</style>

<script>
export default {
  name: "PasswordActions",
  data() {
    return {
      showPassword: false,
      copyText: "",
      copySuccess: false,
    };
  },
  props: {
    password: String,
    view: Object,
  },
  created() {
    this.copyText = this.$t("utility.copy");
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    copyToClipboard() {
      if (this.password.length > 5) {
        let input = document.createElement("input");
        document.body.appendChild(input);
        input.setAttribute("id", "password");
        document.getElementById("password").value = this.password;
        input.select();
        try {
          document.execCommand("copy");

          if (this.view.showModalAsPage) {
            this.copyText = this.$t("utility.copied");
            this.copySuccess = true;
            setTimeout(() => {
              this.copyText = this.$t("utility.copy");
              this.copySuccess = false;
            }, 3000);
          } else {
            this.showToast(this.$t("toast.copyClipboard"));
          }
        } catch {
          if (this.view.showModalAsPage) {
            this.copyText = this.$t("utility.unableCopy");
            setTimeout(() => {
              this.copyText = this.$t("utility.copy");
            }, 3000);
          } else {
            this.showToast(this.$t("toast.unableCopy"));
          }
        }
        document.body.removeChild(input);
      }
    },
  },
};
</script>
