const getDefaultState = () => ({
  plans: [],
});

export default {
  namespaced: true,
  state: () => getDefaultState(),
  actions: {
    plans({ commit }, payload) {
      commit("plans", payload);
    },
    resetState({ commit }) {
      commit("resetState");
    },
  },
  mutations: {
    plans(state, payload) {
      state.plans = payload;
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
  getters: {
    plans: (state) => state.plans,
    planId: (state, getters, rootState) =>
      rootState.currentPlanDetails?.mailSuiteDetails?.planId,
    currentProductDetails: (state, getters, rootState) =>
      rootState.currentPlanDetails?.mailSuiteDetails || {},
    currentPlanType: (state, getters) =>
      getters.currentProductDetails?.planDetails?.type,
    isPaidPlan: (state, getters) =>
      getters.currentProductDetails?.planDetails?.isPaid,
    isTrialPlan: (state, getters) =>
      getters.currentProductDetails?.planDetails?.isTrial,
    isFreePlan: (state, getters) => !getters.isPaidPlan && !getters.isTrialPlan,
    canUpgradePlan: (state, getters) => {
      return state.plans.some((plan) => plan.id !== getters.planId);
    },
    orderId: (state, getters) => getters.currentProductDetails?.orderId,
  },
};
