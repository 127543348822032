import TitanLogo from "../assets/titan-logo.svg";
import NeoLogo from "../assets/neo-login-logo.svg";
import PoweredByTitan from "../assets/powered-by-titan-black.svg";
import TitanInvertedLogo from "../assets/titan-logo-inverted.svg";
import TitanDesktop from "../assets/titan-desktop.svg";
import NeoDesktop from "../assets/neo-desktop.svg";
import TitanFavIcon from "@/assets/titan-favicon.png";
import NeoFavIcon from "@/assets/neoFavIcon.svg";
import { isEuRegion } from "./regions";
import { isNeoBrand, isNeoControlPanel } from "@/helpers";
import store from "@/store";
import neoMobileAppQr from "@/assets/neo-mobile-app-qr-code.svg";

const TITAN_DETAILS = {
  name: "Titan",
  logo: TitanLogo,
  // used inverse url as we use this for applogo
  defaultLogoURL: "https://resources.flockmail.com/titanemail_logo_dark.svg",
  invertedLogo: TitanInvertedLogo,
  poweredByLogo: PoweredByTitan,
  loginHeader: "loginToControlPanel",
  appName: "Email control panel",
  siteUrl: "manage.titan.email",
  panelName: "controlPanel",
  defaultDkimKey: "d=*.titan.email",
  favIcon: TitanFavIcon,
  desktopImage: TitanDesktop,
  zendeskKey: process.env.VUE_APP_ZENDESK_TITAN_KEY,
  desktopClient: {
    imap: { name: "imap.titan.email", port: "993" },
    pop: { name: "pop.titan.email", port: "995" },
    smtp: { name: "smtp.titan.email", port: "465" },
    supportLink:
      "https://support.titan.email/hc/en-us/articles/900000215446-Configure-Titan-on-other-apps-using-IMAP-POP",
    configureDesktopArticleLink:
      "https://support.titan.email/hc/en-us/articles/25005307786905",
    configureIMAPPOPBlacknightMonoVM:
      "https://support.titan.email/hc/en-us/articles/4406867379865-Configure-Titan-on-other-apps-using-IMAP-POP-for-EU-users-Blacknight-MonoVM",
  },
  mxItems: [
    {
      Type: "MX",
      Host: "@",
      Point_to: "mx1.titan.email",
      Priority: 10,
      "TTL (Seconds)": 3600,
    },
    {
      Type: "MX",
      Host: "@",
      Point_to: "mx2.titan.email",
      Priority: 20,
      "TTL (Seconds)": 3600,
    },
  ],
  spfItems: [
    {
      Type: "TXT",
      Host: "@",
      Point_to: "v=spf1 include:spf.titan.email ~all",
      "TTL (Seconds)": 3600,
    },
  ],
  viewInstructionLink:
    "https://support.titan.email/hc/en-us/articles/360036853934",
  download: {
    windows:
      "https://bingo.flock.co/mail_dl.php?client=win&stream=production&brand=Titan",
    mac:
      "https://bingo.flock.co/mail_dl.php?client=mac&stream=production&brand=Titan",
    itune: "https://apps.apple.com/us/app/id1486741447",
    play: "https://play.google.com/store/apps/details?id=email.titan.app",
  },
  webmailLink: "https://web.titan.email",
  billing: {
    termsOfUse:
      "https://support.titan.email/hc/en-us/articles/360038024254-Titan-Customer-Terms-of-Use",
    privacyPolicy: "https://support.titan.email/hc/en-us/articles/360038535773",
  },
  supportEmail: "support@titan.email",
  submitRequest: "https://support.titan.email/hc/en-us/requests/new",
  dkimSetup:
    "https://support.titan.email/hc/en-us/articles/900006699683-Set-up-DKIM-Record",
  backupEmailSupportLink:
    "https://support.titan.email/hc/en-us/articles/900001732203-How-to-take-a-backup-of-my-emails-",
};

const NEO_DETAILS = {
  ...TITAN_DETAILS,
  name: "Neo",
  logo: NeoLogo,
  loginHeader: "loginToAdminPanel",
  panelName: "adminPanel",
  supportEmail: "support@neo.space",
  desktopImage: NeoDesktop,
  siteUrl: "admin.neo.space",
  appName: "Neo Admin Panel",
  favIcon: NeoFavIcon,
  showWelcomePopup: true,
  defaultDkimKey: "d=*.neo.space",
  zendeskKey: process.env.VUE_APP_ZENDESK_NEO_KEY,
  domainOwnershipItems: [
    {
      Type: "TXT",
      Host: "@",
      Point_to: "neo-verification:LVybwAoT8Zemhejsjdy82",
      "TTL (Seconds)": 14400,
    },
  ],
  download: {
    windows:
      "https://bingo.flock.co/mail_dl.php?client=win&stream=production&brand=Titan",
    mac:
      "https://bingo.flock.co/mail_dl.php?client=mac&stream=production&brand=Titan",
    itune: "https://apps.apple.com/in/app/neo-mail/id1556396060",
    play: "https://play.google.com/store/apps/details?id=space.neo.app",
  },
  webmailLink: "https://app.neo.space",
  desktopClient: {
    imap: { name: "Imap0001.neo.space", port: "993" },
    pop: { name: "pop0001.neo.space", port: "465" },
    smtp: { name: "smtp0001.neo.space", port: "465" },
    supportLink: "https://support.neo.space/hc/en-us/articles/13617484527001",
  },
  mobileAppQr: neoMobileAppQr,
  showDomainOwnership: true,
  backupEmailSupportLink:
    "https://support.neo.space/hc/en-us/articles/14304871535001-How-to-take-a-backup-of-my-emails-",
  prioritySupportBookingLink: "https://book.neo.space/neo/priority-support/",
};

class Brand {
  constructor() {
    this.TITAN = "titan";
    this.NEO = "neo";
    this.NAMES = {
      TITAN: this.TITAN,
      NEO: this.NEO,
    };
    this.details = {
      [this.TITAN]: { ...TITAN_DETAILS },
      [this.NEO]: { ...NEO_DETAILS },
    };

    if (isEuRegion) {
      const brand = this.getBrandName();
      this.details[brand].desktopClient.imap.name = "imap0101.titan.email";
      this.details[brand].desktopClient.pop.name = "pop0101.titan.email";
      this.details[brand].desktopClient.smtp.name = "smtp0101.titan.email";
      this.details[brand].desktopClient.supportLink =
        "https://support.titan.email/hc/en-us/articles/4406867379865-Configure-Titan-on-other-apps-using-IMAP-POP-for-European-Union-region-users";
      this.details[brand].mxItems[0].Point_to = "mx0101.titan.email";
      this.details[brand].mxItems[1].Point_to = "mx0102.titan.email";
      this.details[brand].spfItems[0].Point_to =
        "v=spf1 include:spf0101.titan.email ~all";
      this.details[brand].viewInstructionLink =
        "https://support.titan.email/hc/en-us/articles/4406866483737-Setup-Titan-for-your-domain-European-Union-users";
    }

    if (process.env.VUE_APP_ENV_FLAG === "stage") {
      const brand = this.getBrandName();
      this.details[brand].desktopClient.imap.name =
        "imap.ops.flock-staging.com";
      this.details[brand].desktopClient.pop.name = "pop.ops.flock-staging.com";
      this.details[brand].desktopClient.smtp.name =
        "smtp.ops.flock-staging.com";
      this.details[brand].mxItems = [
        {
          Type: "MX",
          Host: "@",
          Point_to: "mx1.ops.flock-staging.com",
          Priority: 10,
          "TTL (Seconds)": 3600,
        },
      ];
      this.details[brand].spfItems[0].Point_to =
        "v=spf1 include:spf.ops.flock-staging.com ~all";
    }
  }

  getDesktopDetails() {
    let res = this.details[this.getBrandName()];
    return res.desktopClient;
  }
  getBrandName() {
    if (isNeoBrand() || isNeoControlPanel) return this.NEO;
    return this.TITAN;
  }
  getDetails() {
    const brand = this.getBrandName();
    return this.details[brand];
  }
  setDetails(data = {}) {
    // if u want to add/update any details
    // later we might move all this config to server
    const brand = this.getBrandName();
    this.details[brand] = {
      ...this.details[brand],
      ...data,
    };
    store.dispatch("brandDetails", this.details[brand]);

    return this.details[brand];
  }
  deriveBrandFromURL() {
    return this.getBrandName();
  }
}

const brandInstance = new Brand(); // keep only once instance throughout

export default brandInstance;
