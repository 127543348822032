// these are from commont helper,
// but to avoid circular dep,
// we need to keep copy of these helpers

export function isAppLoadedInsideIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function clearStorage(keysToSkip = []) {
  const storedKeys = Object.keys(storageInstance);
  storedKeys.forEach((key) => {
    if (key.indexOf("preserve__") === -1 && !keysToSkip.includes(key)) {
      storageInstance.removeItem(key);
    }
  });
}

export function checkStringSurroundedWithQuotes(str) {
  str = str && str.toString();
  return str ? str.startsWith('"') || str.endsWith('"') : false;
}

export function getQueryParam(name, url) {
  if (!url) url = decodeURIComponent(location.href);
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(url);
  return results == null ? null : results[1];
}

export function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
}

// ref: https://stackoverflow.com/a/1634841
export function removeQueryParam(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split("?");
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + "=";
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
  }

  return url;
}

export function hasAccess() {
  let _hasAccess = true;

  try {
    // temp key, to check support
    localStorage.setItem("__test_key", "__test_val");
    localStorage.removeItem("__test_key");
  } catch (error) {
    _hasAccess = false;
  }

  return _hasAccess;
}

// keep limited data in URL
const _keysToPreserve = [
  "loginEmail",
  "authToken",
  "partnerId",
  "domain",
  "lang",
  "hideIFrameHelpButton",
  "showIFrameHomeHeader",
  "accountId",
  "isAdmin",
];
const _separator = ":~:";

export function SmartStorage() {
  let _store = this;

  if (!hasAccess()) {
    let cachedState = getQueryParam("cachedState");
    cachedState = cachedState ? cachedState.split(_separator) : [];

    if (cachedState.length) {
      _keysToPreserve.forEach((key, index) => {
        _store[key] = cachedState[index];
      });
    }

    removeQueryParam(window.location.href, "cachedState");
    console.warn("Failed to store data");
    console.warn(
      "Activating cache mode from smart storage, make sure you call preserve before reload"
    );
  } else {
    _store = isAppLoadedInsideIframe() ? sessionStorage : localStorage;
  }

  return _store;
}

SmartStorage.prototype.hasAccess = hasAccess;

SmartStorage.prototype.preserve = function (url) {
  if (this.hasAccess()) {
    return url;
  }

  let data = "";
  _keysToPreserve.forEach((key, index) => {
    data += `${index ? _separator : ""}${this[key]}`;
  });

  const cachedState = encodeURIComponent(JSON.stringify(data));
  const newUri = updateQueryStringParameter(url, "cachedState", cachedState);
  return newUri;
};

SmartStorage.prototype.getItem = function (key) {
  if (this.hasAccess()) {
    return isAppLoadedInsideIframe()
      ? sessionStorage.getItem(key)
      : localStorage.getItem(key);
  }

  return (
    (checkStringSurroundedWithQuotes(this[key])
      ? this[key].replace(/['"]+/g, "")
      : this[key]) || null
  );
};

SmartStorage.prototype.clear = function () {
  if (this.hasAccess()) {
    isAppLoadedInsideIframe() ? sessionStorage.clear() : localStorage.clear();
  }
};

SmartStorage.prototype.setItem = function (key, val) {
  if (this.hasAccess()) {
    isAppLoadedInsideIframe()
      ? sessionStorage.setItem(key, val)
      : localStorage.setItem(key, val);
  } else {
    this[key] = JSON.stringify(val);
  }
};

SmartStorage.prototype.removeItem = function (key) {
  if (this.hasAccess()) {
    isAppLoadedInsideIframe()
      ? sessionStorage.removeItem(key)
      : localStorage.removeItem(key);
  } else {
    delete this[key];
  }
};

// TODO: this is coming as undefined
// SmartStorage.prototype.removeItems = function (keys = []) {
//   if (!Array.isArray(keys)) return false;
//   keys.forEach((key) => this.removeItem(key));
//   return true;
// };

const storageInstance = new SmartStorage();

export default storageInstance;
