const removeSingleQuotesOnBothEnds = (queryParamValue) => {
  const queryParamValueWithoutQuotes = queryParamValue.replace(
    /^'?([^']*)'?$/,
    "$1"
  );
  return queryParamValueWithoutQuotes;
};

export const getLastQueryParamValue = (query = {}, queryParamKey = "") => {
  if (!query.hasOwnProperty(queryParamKey)) return "";
  let queryParamValueList = query[queryParamKey];

  // when there is only one value for the query param key
  if (typeof queryParamValueList === "string") {
    return removeSingleQuotesOnBothEnds(queryParamValueList);
  }

  // decodes the query param key and make 'home' to home
  const lastQueryParamValue = queryParamValueList.slice(-1)[0];
  let decodedLastQueryParamValue = decodeURIComponent(lastQueryParamValue);
  return removeSingleQuotesOnBothEnds(decodedLastQueryParamValue);
};
