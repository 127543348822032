<template>
  <div class="titan-checkbox">
    <input
      :disabled="disabled"
      :id="defaultId"
      :checked="checked"
      type="checkbox"
      @change="update"
    />
    <label
      :style="labelStyles"
      :class="[type, { styled: !!labelStyles }]"
      :for="defaultId"
    >
      <span class="box-slider" />
      <slot></slot>
    </label>
  </div>
</template>
<script>
import { getRandomId } from "@/helpers";

export default {
  model: {
    prop: "checked",
    event: "change",
  },
  data() {
    return {
      defaultId: this.id || getRandomId("Checkbox"),
    };
  },
  props: {
    id: String,
    type: {
      type: String,
      default: "checkbox",
      validator(value) {
        return ["checkbox", "switch"].indexOf(value) > -1;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    labelStyles: {
      type: Object,
      default() {},
    },
  },
  methods: {
    update(event) {
      this.$emit("change", event.target.checked);
    },
  },
};
</script>
<style lang="scss" scoped>
$switchWidth: 34px;
$switchHeight: 18px;
$circleDiameter: calc((0.75 * #{$switchHeight}));
$gap: 2px;
$checkedCirclePos: calc(#{$switchWidth} - #{$circleDiameter} - #{$gap});
.switch-circle {
  background: var(--primaryBgCol);
  width: $circleDiameter;
  height: $circleDiameter;
  border-radius: 50%;
  top: 2px;
  border: none;
  position: absolute;
}
.titan-checkbox {
  text-align: left;
  display: inline-block;
  input[type="checkbox"] {
    position: absolute;
    left: -999999px;
    & + label {
      margin: 0;
      color: var(--primaryTextCol);
      position: relative;
      font-weight: normal;
      line-height: normal;
      z-index: 2;
      display: flex;
      align-items: center;
      @include ellipsis();
      min-height: $switchHeight;
      width: unset;
      cursor: pointer;
      .content {
        padding-left: 2em;
      }
      & > .box-slider {
        position: relative;
        content: "";
        box-sizing: border-box;
        width: 1em;
        height: 1em;
        display: inline-block;
        background-color: var(--primaryBgCol);
        @include border();
        border-radius: 3px;
        transition: 0.2s background cubic-bezier(0.22, 0.61, 0.36, 1);
      }
      &.switch {
        & > .box-slider {
          width: $switchWidth;
          height: $switchHeight;
          border-radius: 12px;
          @include border();
          border: none;
          background: var(--inputBorderCol);
          &:after {
            transition: 0.2s left cubic-bezier(0.22, 0.61, 0.36, 1);
            content: "";
            left: 3px;
            @extend .switch-circle;
          }
        }
      }
    }
    &:disabled {
      & + label > .box-slider {
        background: #f2f2f2;
        border-color: var(--inputBorderCol);
      }
      &:checked + label > .box-slider {
        background: var(--inputBorderCol);
      }
    }
  }
  input[type="checkbox"]:checked + label {
    &.switch {
      & > .box-slider {
        &:after {
          left: $checkedCirclePos;
          @extend .switch-circle;
        }
      }
    }
    &.checkbox > .box-slider:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    & > .box-slider {
      border: none;
      background: var(--primaryBtnCol);
      &:after {
        content: "";
        position: absolute;
        top: 40%;
        left: 50%;
        width: 9px;
        height: 4px;
        border: 1px solid var(--checkboxTickCol);
        border-top: none;
        border-right: none;
        background: transparent;
      }
    }
  }
}
</style>
