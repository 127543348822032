import { PASSWORD_SET, SPECIAL_CHARACTERS } from "./const";

// Shuffle a string
// Why 0.5 ?
// Equal probability of Math.random() - 0.5 to be +ve or -ve
function shuffleString(inputString) {
  return inputString
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");
}

// Get a random integer in an interval
function getRandomIntegerInAnInterval(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function getRandomPattern(inputString, length) {
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomChar =
      inputString[getRandomIntegerInAnInterval(0, inputString.length - 1)];
    result = result + randomChar;
  }
  return shuffleString(result);
}

/**
 *
 * @param {Number} passwordTotalLength
 * @returns A random generated password of a given length based on the password Regex specified in const.js
 */
export function getRandomGeneratedPassword(passwordTotalLength) {
  // Password rule:
  // 1. Minimum 8 and maximum 100 characters (Length)
  // 2. At least one special character
  // 3. Alphanumeric
  let password =
    SPECIAL_CHARACTERS[
      getRandomIntegerInAnInterval(0, SPECIAL_CHARACTERS.length - 1)
    ];
  const passwordRemainingLength = passwordTotalLength - password.length; // 14
  password = shuffleString(
    password + getRandomPattern(PASSWORD_SET, passwordRemainingLength)
  );
  return password;
}

/**
 * Calculates the number of days between today's date and a given expiry date.
 *
 * @param {string} dateParam - The date in 'YYYY-MM-DD' format or epoch millis.
 * @returns {number} The number of days between today and the provided date. Returns positive number if the date is in the future, negative if it's in the past and 0 if it's today.
 */
export const calculateDateDifference = (dateParam) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Ignore time part to compare only dates

  const date = new Date(dateParam);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = date - today;

  // Convert the difference to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  const differenceInDays = differenceInMilliseconds / (24 * 60 * 60 * 1000);

  return Math.round(differenceInDays);
};

export const calculatePlanPricingCharge = (monthlyCharge, noOfMonth = 12) => {
  return (monthlyCharge * noOfMonth).toFixed(2);
};
