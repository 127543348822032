export const ENTRI_SETUP_TYPE = {
  AUTOMATIC: "Automatic",
  MANUAL: "Manual",
};

export const APPLICATION_ID = {
  dev: "neo_staging",
  stage: "neo_staging",
  preprod: "neo",
  prod: "neo",
};

/**
 * function to parse DNS records in format which entri supports
 * @param {[object]} records
 * @returns [{object}]
 * @link https://developers.entri.com/docs/install#4-create-the-configuration-object
 */
export const parseDnsRecords = (records) => {
  const parser = {
    mx: (r) => ({
      type: "MX",
      host: "@",
      value: r,
      priority: 20,
      ttl: 3600,
    }),
    spf: (r) => ({
      type: "TXT",
      host: "@",
      value: r,
      ttl: 3600,
    }),
    dkim: (r) => ({
      type: "TXT",
      host: r.selector,
      value: r.publicKey,
      ttl: 3600,
    }),
    domainOwnershipTxt: (r) => ({
      type: "TXT",
      host: "@",
      value: r,
      ttl: 14400,
    }),
    a: (r) => ({
      type: "A",
      host: "@",
      value: r,
      ttl: 3600,
    }),
    cname: (r) => ({
      type: "CNAME",
      host: "www",
      value: r,
      ttl: 3600,
    }),
  };

  return Object.keys(records).reduce(
    (pv, cv) => [
      ...pv,
      ...(parser[cv] ? records[cv] : []).map((r) => parser[cv](r)),
    ],
    []
  );
};

/**TODO: We can move this to partner file in future */

export const getEntriConfig = (authToken, expectedRecords) => {
  return {
    applicationId:
      APPLICATION_ID[process.env.VUE_APP_ENV_FLAG] || APPLICATION_ID["stage"],
    token: authToken,
    dnsRecords: parseDnsRecords(expectedRecords),
  };
};
