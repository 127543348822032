import smartStorage from "./smart-storage";

/**
 * Generates a unique identifier using the `createObjectURL` method of the `URL` interface
 * and returning the last 36 characters of the resulting string.
 *
 * @return {string} The generated unique identifier.
 */
export const generateUniqueId = () => {
  return URL.createObjectURL ? URL.createObjectURL(new Blob()).slice(-36) : "";
};

export const getDeviceId = (() => {
  let id = smartStorage.getItem("deviceId");

  if (!id) {
    id = generateUniqueId();
    smartStorage.setItem("deviceId", id);
  }
  return () => id;
})();
