<template>
  <TitanToggle>
    <template v-slot="{ on, toggle }">
      <div class="titan-accordion" role="accordion">
        <header @click="toggle">
          <div class="chevron" :class="[{ open: on }, position]" />
          <slot :toggle="toggle"></slot>
        </header>
        <slot v-if="on" name="content" />
      </div>
    </template>
  </TitanToggle>
</template>

<script>
import TitanToggle from "./TitanToggle";

export default {
  props: {
    position: {
      type: String,
      default: "left",
      validator(prop) {
        return ["left", "right"].indexOf(prop) > -1;
      },
    },
  },
  components: {
    TitanToggle,
  },
  methods: {
    onItemClick(item, toggle) {
      this.$emit("click", item, toggle);
    },
  },
};
</script>

<style lang="scss" scoped>
.titan-accordion {
  background: var(--tabBgCol);
  border: solid 1px var(--noteBorderCol);
  padding: 1.25em 1.5em;
  position: relative;
  width: 100%;
}

header {
  display: flex;
  align-items: center;
  padding: 0 1em;
  cursor: pointer;
}
.chevron {
  height: 24px;
  width: 24px;
  background: url("./assets/chevron.svg") no-repeat;
  transition: transform 0.1s ease-in-out;
  margin-right: 1em;
  &.open {
    transform: rotateZ(90deg);
  }
}
</style>
