<template>
  <button
    class="btn"
    :disabled="disabled"
    :style="computedStyles"
    :type="buttonType"
    tabindex="0"
    :class="[
      type,
      size,
      shape,
      {
        invert,
        small,
        loader: loading,
        'link-button-loader': linkButtonLoader,
      },
      className,
    ]"
    @click="$emit('click', $event)"
  >
    <slot>Submit</slot>
  </button>
</template>
<script>
export default {
  props: {
    styles: {
      type: Object,
      default: () => ({}),
    },
    shape: {
      type: String,
      default: "default",
      validator(prop) {
        return ["default", "flat"].indexOf(prop) > -1;
      },
    },
    fontSize: {
      type: String,
      default: "14px",
    },
    className: {
      type: String,
      default: "",
    },
    small: {
      type: Boolean,
      default: false,
    },
    size: {
      default: "auto",
      type: String,
      validator(prop) {
        return ["auto", "full", "half"].indexOf(prop) > -1;
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    linkButtonLoader: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      default: "button",
      type: String,
    },
    type: {
      default: "primary",
      type: String,
      validator(prop) {
        return (
          [
            "primary",
            "secondary",
            "destructive",
            "link",
            "transparent",
            "success-text",
            "secondary-cancel",
          ].indexOf(prop) > -1
        );
      },
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    invert: {
      default: false,
      type: Boolean,
    },
    minWidth: {
      type: Number,
      default: 100,
    },
  },
  computed: {
    computedStyles() {
      return {
        ...this.styles,
        fontSize: this.fontSize,
        minWidth: `${this.minWidth}px`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  --btnColor: var(--primaryBtnCol);
  --textColor: var(--primaryBtnTextCol);
  background: var(--btnColor);
  color: var(--textColor);
  @include borderRadius();
  border: none;
  font-weight: 600;
  padding: 0.625em 1.25em;
  text-align: center;
  // transition: all 0.1s ease-in-out;
  outline: none;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  &:hover {
    color: var(--textColor);
  }
  &.loader {
    @include button-loader();
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
  &.link-button-loader {
    @include link-button-loader();
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    padding-top: 0;
  }
  &.link {
    font-weight: normal;
    background: none;
    color: var(--linkBtnCol);
    padding: 0;
    border: none;
    text-decoration: none;
  }
  &.full {
    width: 100%;
  }
  &.small {
    padding: 4px 0.75em;
  }
  &.primary {
    div,
    p,
    span,
    th,
    tr,
    input,
    li,
    p,
    h1,
    h2,
    h3,
    h4 {
      color: var(--tabBgCol);
    }
  }
  &.secondary {
    --btnColor: var(--lineSepaCol);
    --textColor: var(--secondaryTextCol);
  }
  &.destructive {
    --btnColor: var(--errorPrimaryTextCol);
    --textColor: white;

    &.loader {
      background-image: var(--loadingSpinnerWhite);
    }
  }
  &.success-text {
    div,
    span {
      color: var(--modalHeadCol);
    }
  }
  &.transparent {
    --btnColor: transparent;
    --textColor: var(--primaryBtnCol);
    border: solid 1px var(--primaryBtnCol);
  }
  &.secondary-cancel {
    --btnColor: transparent;
    --textColor: var(--cancelButtonCol);
    border: solid 1px var(--cancelButtonCol);
    color: var(--cancelButtonCol);
  }
  &:focus:not(.link) {
    outline: var(--btnColor) auto 2px;
  }
  &:active:not(.link) {
    background: var(--btnColor);
  }
  &.invert {
    background: var(--textColor);
    color: var(--btnColor);
    border: solid 1px var(--btnColor);
    &:active {
      color: var(--textColor);
      background: var(--btnColor);
    }
    &:disabled {
      background: white;
      border-color: var(--btnColor);
      color: var(--btnColor);
      opacity: 1;
    }
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
  &.no-outline {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
</style>
