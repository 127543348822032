import { getUTCDDHHmmss } from "./date";
import { getDeviceId } from "./device";

const CRID_COUNTER_THRESHOLD = 1000;

// https://docs.google.com/document/d/13C5IbIloxdHaQaqpvWEmo4IgQi3ocFo5-1PWJWvMFBE/edit#
export const generateCrid = (function () {
  let counter = 0;
  return function (name) {
    if (counter >= CRID_COUNTER_THRESHOLD) counter = 0;
    const randomChars = Math.random().toString(36).substring(2, 6);
    const deviceId = getDeviceId().slice(0, 3);
    const crid = `w_${getUTCDDHHmmss()}_${name}_${counter++}_${deviceId}_${randomChars}`;
    return crid;
  };
})();
