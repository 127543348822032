import { scaleUpToTwoDecimals } from "@/helpers";

const addonDisplayMap = {
  storage_addon: `billingAndUpgrade.addons.storage_addon.features`,
  titan_max: `billingAndUpgrade.addons.titan_max`,
};

const featureMap = {
  storage_addon: "storage",
};

export default {
  methods: {
    scaleUpToTwoDecimals,
    /**
     * common function to return feature attribute
     * @param {Object} feature
     */
    getRespectiveAttr: function (feature) {
      const attrs = Object.keys(feature.attrs || {});

      switch (feature.type) {
        case "storage":
          return feature.attrs.quota_in_gb ? "quota_in_gb" : "quota_in_mb";

        default:
          return attrs[0] || "text";
      }
    },
    /**
     * common function to return feature (storage) details
     * @param {Array} features
     * @param {String} featureToFind
     */
    getFeatureDetails: function (features, featureToFind) {
      const respFeature = features.find((f) => f.type === featureToFind);
      const respAttr = this.getRespectiveAttr(respFeature);
      const attrValue = respFeature.attrs[respAttr];

      return { respFeature, respAttr, attrValue };
    },
    getDisplayProperty: function ({ type, attr, key, value, feature }) {
      return this.$t(
        `${addonDisplayMap[type]}${feature ? "." + feature : ""}${
          attr ? "." + attr : ""
        }.${key}`,
        [value]
      );
    },
    getAddonDetails: function ({ type, features } = {}, key) {
      if (!type) {
        // empty placehoder, incase of get all addons return empty but calc charge returns some addon
        return this.$t("billingAndUpgrade.orderSummarySection.addon");
      }
      if (featureMap[type]) {
        const { respAttr, attrValue, respFeature } = this.getFeatureDetails(
          features,
          featureMap[type]
        );
        return this.getDisplayProperty({
          type,
          attr: respAttr,
          key,
          value: attrValue,
          feature: respFeature.type,
        });
      } else {
        return this.getDisplayProperty({ type, key });
      }
    },
  },
};
