import { render, staticRenderFns } from "./PurchaseFlow.vue?vue&type=template&id=6fade0ca"
import script from "./PurchaseFlow.vue?vue&type=script&lang=js"
export * from "./PurchaseFlow.vue?vue&type=script&lang=js"
import style0 from "./PurchaseFlow.vue?vue&type=style&index=0&id=6fade0ca&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports