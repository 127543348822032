<template>
  <div class="header">
    <img src="../../assets/titan-logo-inverted.svg" alt="" />
    <span>{{ $t("purchaseFlow.secure") }} </span>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="scss" scoped>
.header {
  padding: 20px 24px;
  background-color: var(--headerBgCol);
  img {
    height: 20px;
    padding-right: 16px;
  }
  span {
    color: var(--tabBgCol);
    font-size: 16px;
  }
}
</style>
