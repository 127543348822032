export const TITAN_MAX = "titan_max";
export const STORAGE = "storage_addon";

export const ADDONS_ON_TRIAL = [TITAN_MAX];

/**
 * Number of days within which accounts are eligible for trial extension/purchase.
 */
export const EXPIRY_ELIBILITY = {
  titan_max: 5,
};
