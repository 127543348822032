import { loadStripe } from "@stripe/stripe-js";

let stripeInstance = null;

loadStripe(process.env.VUE_APP_STRIPE_KEY)
  .then((data) => {
    stripeInstance = data;
  })
  .catch(() => console.error("Failed to load stripe"));

// function to export stripeInstance
// due to promise we would need this helper to make sure latest value is returned
export function Stripe() {
  return stripeInstance;
}
