<template>
  <div class="bullet font-600 font-14px flex-center">
    {{ count }}
  </div>
</template>
<script>
export default {
  props: {
    count: {
      type: Number,
      default: 1,
    },
  },
};
</script>
<style lang="scss">
.bullet {
  width: 20px;
  height: 20px;
  padding: 6px;
  border-radius: 50%;
  background: var(--primaryTextCol);
  color: var(--tabBgCol);
}
</style>
