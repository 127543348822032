import merge from "lodash/merge";
import partnerApi from "./partner.api";
import Logger from "@/helpers/logger";

class PartnerService {
  async getPartnerInfo(config) {
    try {
      const domainId = smartStorage.getItem("domainID");
      const brandParam = domainId
        ? { domainId }
        : { brandedHost: window.location.host };
      const mergedConfig = merge(config, { params: brandParam });
      const data = await partnerApi.getPartnerInfo(mergedConfig);
      return data;
    } catch (e) {
      Logger.error("`getPartnerInfo` service", e);
      return Promise.reject(e);
    }
  }
}

const partnerService = new PartnerService();

export default partnerService;
