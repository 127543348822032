import store from "@/store";
import { clearStorage, smartStorage } from ".";
import { ADMIN_ROLE } from "./const";

export const getLoggedInUserRole = () => smartStorage.getItem("role");

export const getAccountId = () => smartStorage.getItem("accountId");

export const getDomainName = () =>
  store.getters["domain/domainName"] || smartStorage.getItem("domain");

export const getDomainId = () =>
  store.getters["domain/domainID"] || smartStorage.getItem("domainID");

export const getBundleId = () =>
  store.getters["bundleId"] || smartStorage.getItem("bundleId");

export const getPartnerId = () =>
  store.getters["partnerId"] || smartStorage.getItem("partnerId");

export const getLoggedInUserType = () => smartStorage.getItem("adminType");

export const getCurrentUserEmail = () =>
  smartStorage.getItem("loginEmail") || "";

export const isLoginViaPartnerPanel = () => {
  const role = getLoggedInUserRole();
  return [ADMIN_ROLE.TITAN, ADMIN_ROLE.TITAN_SUPPORT].includes(role);
};

export const clearAuthSession = (keysToSkip) => {
  store.dispatch("domain/currentDomainDetails", {});
  store.dispatch("domain/domainName", "");
  store.dispatch("domain/domainID", "");
  clearStorage(keysToSkip);
};
