/**
 * This Vue Mixin contains all the billing related methods.
 */
import { isNeoBrand } from "@/helpers";
import { isFreeBetaPlan, isTrialPlan } from "@/helpers/plansHelpers";
import addon from "./addon";
import { mapGetters } from "vuex";
import {
  isDomainProduct,
  getAllProductsDetails,
  hasMailSuiteProduct,
  hasSiteProduct,
  hasDomainProduct,
  isMailSuiteProduct,
  isSiteProduct,
} from "@/helpers/product";
import {
  getPlanFeatureOrder,
  getPlanRemainingDaysAndMonths,
  processFeatureAttributes,
} from "@/helpers/billingHelpers";

export default {
  created() {
    if (!this.plans || !this.currentPlan || !this.addons) {
      console.error(
        "The mixin needs plans, currentPlan and addons as a scoped property."
      );
    }
  },
  mixins: [addon],
  methods: {
    isHighestOrLowestPlan(product, planDetails, mode, planCategory = "paid") {
      if (isMailSuiteProduct(product) || isSiteProduct(product)) {
        const plans = (isMailSuiteProduct(product)
          ? this.mailPlans
          : this.sitePlans
        ).filter((p) => {
          if (planCategory === "paid") {
            return !isTrialPlan(p) && !isFreeBetaPlan(p);
          } else if (planCategory === "trial") {
            return isTrialPlan(p) && !isFreeBetaPlan(p);
          } else {
            // Default to include all plans
            return true;
          }
        });
        if (mode === "highest") {
          return !plans.some((plan) => plan.zIndex > planDetails.zIndex);
        } else if (mode === "lowest") {
          return !plans.some((plan) => plan.zIndex < planDetails.zIndex);
        }
      }
      return false;
    },

    isHighestPaidPlan(product, planDetails) {
      return this.isHighestOrLowestPlan(
        product,
        planDetails,
        "highest",
        "paid"
      );
    },

    isLowestPaidPlan(product, planDetails) {
      return this.isHighestOrLowestPlan(product, planDetails, "lowest", "paid");
    },

    isLowestTrialPlan(product, planDetails) {
      return this.isHighestOrLowestPlan(
        product,
        planDetails,
        "lowest",
        "trial"
      );
    },

    isHighestTrialPlan(product, planDetails) {
      if (
        isMailSuiteProduct(product) ||
        (isSiteProduct(product) && planDetails.isTrial)
      ) {
        return this.isHighestOrLowestPlan(
          product,
          planDetails,
          "highest",
          "trial"
        );
      }
      return false;
    },

    updatedRecords(records) {
      return records
        .map((r) => {
          r.features = r.features.map(processFeatureAttributes);

          return {
            ...r,
            pricing: r.pricing || {
              monthly: 0,
              yearly: 0,
              semesterly: 0,
              quarterly: 0,
              two_yearly: 0,
              four_yearly: 0,
            },
          };
        })
        .sort((a, b) => a.zIndex > b.zIndex);
    },
    getPlansToRender() {
      if (this.hasProductDetails) return this.plans || [];

      const plans = [];

      if (hasMailSuiteProduct(this.products) && this.mailPlans?.length) {
        plans.push(this.mailPlans);
      }

      if (hasSiteProduct(this.products) && this.sitePlans?.length) {
        plans.push(this.sitePlans);
      }

      if (hasDomainProduct(this.products) && this.domainPlans?.length) {
        plans.push(this.domainPlans);
      }

      return plans.flat();
    },
    getProductDetails() {
      if (this.hasProductDetails) return [this.currentProductDetails];

      return getAllProductsDetails(this.currentPlan) || [];
    },
  },
  computed: {
    ...mapGetters({
      domainName: "domain/domainName",
      isDomainPaid: "domain/isPaid",
      products: "products",
      mailPlans: "mail/plans",
      sitePlans: "site/plans",
      domainPlans: "domain/plans",
      currentPlan: "currentPlanDetails",
    }),
    // flag to decide whether we are in specific flow or general flow
    // in specific flow we expect product, plans and currentProductDetails to be present
    // in general flow we only expect plans for all relevant products
    hasProductDetails() {
      return this.product && this.currentProductDetails && this.plans?.length;
    },
    updatedPlans() {
      const featureOrder = getPlanFeatureOrder(this.product);
      const plansToRender = this.getPlansToRender()
        // .filter(p => (p.type.indexOf('trial') === -1)) // filter out trial plans
        .map((p) => ({
          ...p,
          title: p.displayName
            ? p.displayName.toUpperCase()
            : p.type.toUpperCase(),
          features: (p.features || []).sort(
            (f1, f2) => featureOrder[f1.type] - featureOrder[f2.type]
          ),
          // rearrange/sort features based on featureOrder
        }))
        .sort((p1, p2) => p1.zIndex - p2.zIndex); // rearrange/sort plans based on zIndex
      return this.updatedRecords(plansToRender);
    },
    /**
     * If the mixin is used for a specific flow (e.g., purchasing a plan), the `billingDetails` will contain relevant product details and applicable addons.
     * In more general flows (e.g., billing or subscription management), `billingDetails` will include details for all products and applicable addons.
     */
    billingDetails() {
      const addonsMap = {};
      const billingDetails = [];
      const productDetails = this.getProductDetails();
      const { remainingDays, remainingMonths } = getPlanRemainingDaysAndMonths(
        this.currentPlan.expiresOn
      );

      // set product details
      productDetails.forEach((currentProductDetails) => {
        const curPlan = this.updatedPlans.find(
          (p) => p.id == currentProductDetails.planId
        );

        if (curPlan) {
          let paidPlan = { displayName: "", pricing: {} }; // default value to avoid script break
          if (curPlan.isTrial && curPlan.paidPlanId) {
            paidPlan =
              this.updatedPlans.find((p) => p.id == curPlan.paidPlanId) ||
              paidPlan;
            paidPlan = { ...paidPlan };
            paidPlan.title = this.$t(
              "billingAndUpgrade.plans.billingDetailsName",
              [paidPlan.displayName.toUpperCase()]
            );
          }
          const _isMailSuiteProduct = isMailSuiteProduct(
            currentProductDetails.product
          );

          billingDetails.push({
            ...this.currentPlan,
            ...curPlan,
            subType: "plan",
            title: this.$t("billingAndUpgrade.plans.billingDetailsName", [
              curPlan.displayName.toUpperCase(),
            ]),
            subHeading: _isMailSuiteProduct
              ? this.$tc(
                  `billingAndUpgrade.billingSection.noOfMailboxes`,
                  currentProductDetails?.noOfAccounts?.active
                )
              : "", // no need of sub heading for site product
            isTrial: curPlan.isTrial,
            isFreeBetaPlan: currentProductDetails?.isFreeBetaPlan,
            canUpgradePlan:
              !this.isHighestPaidPlan(
                currentProductDetails.product,
                currentProductDetails.planDetails
              ) &&
              !this.isHighestTrialPlan(
                currentProductDetails.product,
                currentProductDetails.planDetails
              ),
            canDowngradePlan:
              isNeoBrand() &&
              (curPlan.isTrial
                ? !this.isLowestTrialPlan(
                    currentProductDetails.product,
                    currentProductDetails.planDetails
                  )
                : !this.isLowestPaidPlan(
                    currentProductDetails.product,
                    currentProductDetails.planDetails
                  )),
            domainName: this.domainName,
            accounts: currentProductDetails?.noOfAccounts?.purchased,
            amountToBePaidOnRenewal:
              curPlan.isPaid || curPlan.isTrial
                ? currentProductDetails.amountToBePaidOnRenewal
                : 0,
            paidPlan,
            product: currentProductDetails.product,
            productDetails: currentProductDetails,
            planDetails: currentProductDetails.planDetails,
            purchaseStatus: currentProductDetails.status,
            isProductSuspended: currentProductDetails.isProductSuspended,
            expiry: currentProductDetails.expiry,
            remainingMonths: remainingMonths.monthDiff,
            remainingDays:
              !curPlan.isPaid && !curPlan.isTrial
                ? null
                : curPlan.isTrial
                ? remainingDays
                : remainingDays < 7 // for non trial plan show badge when its < 7 days remaining
                ? remainingDays
                : null,
            // included: !curPlan.isPaid ? this.$t(`billingAndUpgrade.billingSection.hostingIncluded`) : null
          });
        }
      });

      // set domain details
      if (
        this.currentPlan.domainDetails?.planId &&
        (!this.hasProductDetails || // in generic flows (renew / billing and sub) where we need all product, in that list also return domain
          isDomainProduct(this.product)) // like mail and site, if domain has its own purchase flow then set product as domain, currently we don't have this case
      ) {
        billingDetails.push({
          ...this.currentPlan,
          ...this.currentPlan.domainDetails,
          subType: "domain",
          productDetails: this.currentPlan.domainDetails,
          planPricing: this.currentPlan.domainDetails.planPricing,
          purchaseStatus: this.currentPlan.domainDetails.status,
          remainingMonths: remainingMonths.monthDiff,
          remainingDays: remainingDays < 7 ? remainingDays : null, // FIXME: revisit this logic
        });
      }

      // derive addons map and set addon details
      (this.currentPlan.addons || []).forEach((a) => {
        addonsMap[a.addonId] = addonsMap[a.addonId] || {
          activeAddons: [],
          suspendedAddons: [],
          renewAddons: [],
          amountToBePaidOnRenewal: 0,
          purchaseStatus: null,
        };

        addonsMap[a.addonId]["purchaseStatus"] = a.status;
        addonsMap[a.addonId]["amountToBePaidOnRenewal"] +=
          a.amountToBePaidOnRenewal || 0;
        if (a.status === "suspended") {
          addonsMap[a.addonId]["suspendedAddons"].push(a.attachedTo);
        } else {
          addonsMap[a.addonId]["activeAddons"].push(a.attachedTo);
        }

        if (a.renewOnExpiry) {
          addonsMap[a.addonId]["renewAddons"].push(a.attachedTo);
        }
      });

      Object.keys(addonsMap).forEach((a) => {
        const addon = this.addons.find((ad) => ad.id == a); // dont compare type
        if (addon) {
          const title = this.getAddonDetails(addon, "title");
          billingDetails.push({
            ...addon,
            subType: "addon",
            id: addon.id,
            title,
            isPaid: true, // currently we don't have trial addons
            subHeading: this.$t(
              `billingAndUpgrade.billingSection.noOfAccounts`,
              [
                addonsMap[a].renewAddons.length,
                addonsMap[a].renewAddons.length > 1 ? "s" : "",
              ]
            ),
            accounts: addonsMap[a].renewAddons.length,
            amountToBePaidOnRenewal: addonsMap[a].amountToBePaidOnRenewal,
            pricing: addon.pricing,
            planPricing: addon.pricing,
            included: null,
            attachedTo: addonsMap[a].activeAddons,
            productDetails: addon,
            suspendedAddons: addonsMap[a]["suspendedAddons"],
            purchaseStatus:
              !addonsMap[a].activeAddons.length &&
              addonsMap[a].suspendedAddons.length
                ? "suspended"
                : "active",
            remainingDays:
              !this.currentPlan.isPaid &&
              !this.currentPlan.isTrial &&
              remainingDays < 7
                ? // for non trial plan show badge when its < 7 days remaining
                  remainingDays
                : null,
          });
        }
      });

      return billingDetails;
    },
  },
};
