import { getTime, isNeoBrand } from "@/helpers";

const getDefaultState = () => ({
  domainID: null,
  domainName: null,
  plans: [],
  domainConnectData: {},
  currentDomainDetails: {},
  mail: {}, // dns headers
  site: {}, // dns headers
});

export default {
  namespaced: true,
  state: () => getDefaultState(),
  actions: {
    plans({ commit }, payload) {
      commit("plans", payload);
    },
    domainID({ commit }, payload) {
      commit("domainID", payload);
    },
    domainName({ commit }, payload) {
      commit("domainName", payload);
    },
    domainConnectData({ commit }, payload) {
      const {
        // mail props
        mxVerified,
        spfVerified,
        domainOwnershipVerified,
        dkimVerified,

        // site props
        aVerified,
        cNameVerified,
      } = payload;

      commit("mail", {
        mxVerified,
        spfVerified,
        domainOwnershipVerified,
        dkimVerified,
      });

      commit("site", {
        aVerified,
        cNameVerified,
      });

      commit("domainConnectData", {
        ...(payload || {}),
        lastUpdated: getTime(),
      });
    },
    currentDomainDetails({ commit }, payload) {
      commit("currentDomainDetails", payload);
    },
    resetState({ commit }) {
      commit("resetState");
    },
  },
  mutations: {
    plans(state, payload) {
      state.plans = payload;
    },
    domainID(state, payload) {
      state.domainID = payload;
    },
    domainName(state, payload) {
      state.domainName = payload;
    },
    domainConnectData(state, payload) {
      state.domainConnectData = payload;
    },
    currentDomainDetails(state, payload) {
      state.currentDomainDetails = payload;
    },
    mail(state, payload) {
      state.mail = {
        ...state.mail,
        ...payload,
      };
    },
    site(state, payload) {
      state.site = {
        ...state.site,
        ...payload,
      };
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
  getters: {
    plans: (state) => state.plans,
    domainID: (state) => state.domainID,
    domainName: (state) => state.domainName,
    currentDomainDetails: (state) => state.currentDomainDetails,
    domainConnectData: (state) => state.domainConnectData,
    domainOwnershipVerified: (state) => {
      const { domainOwnershipVerified } = state.mail || {};
      if (!isNeoBrand()) return true;
      return !!domainOwnershipVerified;
    },
    domainMXRecordVerified: (state) => {
      const { mxVerified } = state.mail;
      return mxVerified;
    },
    domainSpfRecordVerified: (state) => {
      const { spfVerified } = state.mail;
      return spfVerified;
    },
    domainVerifiedAndConnected: (state, getters) => {
      return (
        getters.domainOwnershipVerified &&
        getters.domainMXRecordVerified &&
        getters.domainSpfRecordVerified
      );
    },
    domainDkimVerified: (state) => {
      const { dkimVerified } = state.mail;
      return dkimVerified;
    },
    domainARecordVerified: (state) => {
      const { aVerified } = state.site;
      return aVerified;
    },
    domainCNameRecordVerified: (state) => {
      const { cNameVerified } = state.site;
      return cNameVerified;
    },
    siteVerifiedAndConnected: (state, getters) => {
      return getters.domainARecordVerified && getters.domainCNameRecordVerified;
    },
    currentProductDetails: (state, getters, rootState) =>
      rootState.currentPlanDetails?.domainDetails || {},
    isPaid: (state, getters) => getters.currentProductDetails.isPaid,
    isTrialPlan: (state, getters) => getters.currentProductDetails?.isTrial,
    planId: (state, getters, rootState) =>
      rootState?.currentPlanDetails?.domainDetails?.planId,
    orderId: (state, getters) => getters.currentProductDetails?.orderId,
  },
};
