<template>
  <span class="parent-container">
    <b-popover
      :target="user.id.toString()"
      triggers="hover"
      placement="right"
      v-if="!suspensionData.suspensions.length"
      customClass="pop-over-class"
      :content="$t('widgetApp.loading') + '...'"
    ></b-popover>
    <b-popover
      :target="user.id.toString()"
      triggers="hover"
      placement="right"
      v-if="suspensionData.suspensions.length"
    >
      <div class="modal-text" v-if="yourSuspension.length">
        <div class="suspension-list">
          <div class="suspended-by">
            {{ $t("emailAccounts.suspensionList.yourSuspension") }}
          </div>
          <div
            class="suspension-list"
            v-for="(item, index) in yourSuspension"
            :key="index"
          >
            <ul>
              <li class="suspension-type">
                {{
                  item.suspensionType ||
                  $t("emailAccounts.suspensionList.notSpecified")
                }}
              </li>
            </ul>
            <div class="suspension-notes">
              {{ $t("emailAccounts.suspensionList.suspendedBy") }}
              {{ item.userEmail || $t("emailAccounts.suspensionList.admin") }}
            </div>
            <button class="unsuspend-button" @click="openUnsuspendModal()">
              {{ $t("emailAccounts.suspensionList.unsuspend") }}
            </button>
          </div>
        </div>
      </div>
      <div class="modal-text" v-if="otherSuspension.length">
        <div
          class="suspended-by"
          :class="yourSuspension.length && 'partition-border'"
        >
          {{
            !yourSuspension.length
              ? $t("emailAccounts.suspensionList.suspensions")
              : $t("emailAccounts.suspensionList.otherSuspensions")
          }}
        </div>
        <div
          class="suspension-list"
          v-for="(item, index) in otherSuspension"
          :key="index"
        >
          <ul>
            <li class="suspension-type">
              {{
                item.suspensionType ||
                $t("emailAccounts.suspensionList.notSpecified")
              }}
            </li>
          </ul>
          <div class="suspension-notes">
            {{ $t("emailAccounts.suspensionList.suspendedBy") }}
            {{ item.role }}
          </div>
        </div>
        <div class="suspended-by contact-support">
          {{ $t("emailAccounts.suspensionList.contactSupport") }}
        </div>
      </div>
    </b-popover>
  </span>
</template>

<script>
import { BPopover } from "bootstrap-vue";

export default {
  name: "SuspensionList",
  props: {
    user: Object,
    openUnsuspendModal: Function,
    suspensionData: Object,
    otherSuspension: Array,
    yourSuspension: Array,
  },
  components: {
    BPopover,
  },
};
</script>

<style lang="scss" scoped>
.modal-text {
  max-height: 223px;
  padding: 10px;
  overflow: auto;
  width: 216px;
  .suspended-by {
    font-size: 12px;
    color: var(--secondaryTextCol);
  }
  ul {
    margin: 0;
    .suspension-type {
      font-size: 12px;
      color: var(--primaryTextCol);
      list-style-position: outside;
      position: relative;
      left: -25px;
    }
  }
  .contact-support {
    padding-left: 15px;
    margin-top: 4px;
  }
  .unsuspend-button {
    padding-left: 15px;
    background: inherit;
    border: none;
    font-weight: 600;
    font-size: 12px;
    color: var(--primaryBtnCol);
    cursor: pointer;
    padding-bottom: 0 !important;
  }
  .partition-border {
    border-top: 1px solid var(--lineSepaCol);
    padding-top: 9px !important;
  }
  .suspension-notes {
    font-size: 12px;
    color: var(--secondaryTextCol);
    padding: 2px 0 2px 15px;
    line-height: 1.33;
  }
}
.pop-over-class {
  z-index: 1;
}
</style>
