<template>
  <div class="errorContainer">
    <img
      src="../../assets/purchaseError.svg"
      class="errorImg"
      alt=""
      width="200px"
      height="129px"
    />
    <div class="errorContent">
      <div class="error-line-1">
        {{ $t(`purchaseFlow.${errorType}.errorMsg1`, [domainName]) }}
      </div>
      <div></div>
      <div class="error-line-2">
        {{ $t(`purchaseFlow.${errorType}.mainMsg1`, [domainName]) }}
      </div>
      <div class="contactSupportContainer">
        <button
          v-if="errorType === 'cycleAndAccountsError'"
          class="contactSupport"
          @click="reloadPage()"
        >
          {{ $t("purchaseFlow.cycleAndAccountsError.reload") }}
        </button>
        <a v-else class="contactSupport" :href="supportURL" target="_blank">
          {{ $t("purchaseFlow.purchaseDenied.contactSupport") }}
        </a>
      </div>
      <div
        class="writeEmail"
        v-html="$t(`purchaseFlow.purchaseDenied.writeEmail`, [emailURL])"
      ></div>
    </div>
  </div>
</template>

<script>
import brand from "../../helpers/brand";
import { clearStoredData } from "./storedData";
import * as helper from "../../helpers";
export default {
  name: "ErrorScreen",
  data() {
    return {
      supportURL: brand.getDetails("titan").submitRequest, // static as of now
      emailURL: brand.getDetails("titan").supportEmail,
    };
  },
  methods: {
    reloadPage() {
      helper.hardReload();
    },
  },
  props: {
    domainName: String,
    errorType: String,
  },
  created() {
    clearStoredData();
  },
};
</script>

<style lang="scss">
.errorContainer {
  padding-top: 83px;
  max-width: 700px;
  margin: 0 auto;
  .errorImg {
    display: block;
    text-align: center;
    margin: 0 auto;
  }
  .errorContent {
    .error-line-1,
    .error-line-2,
    .contactSupport,
    .writeEmail {
      text-align: center;
      margin: 20px 0 30px 0;
    }
    .error-line-1 {
      font-size: 20px;
    }
    .error-line-2 {
      color: var(--secondaryTextCol);
      font-size: 14px;
    }
    .contactSupportContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      .contactSupport {
        display: inline-block;
        text-align: center;
        margin: 0 auto;
        width: auto;
        background: var(--primaryBtnCol);
        border: none;
        padding: 10px;
        border-radius: 5px;
        color: var(--tabBgCol);
        font-weight: 600;
      }
    }
    .writeEmail {
      a {
        color: var(--linkBtnCol) !important;
      }
    }
  }
}
</style>
