<template>
  <tr
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    class="email-accounts-row"
  >
    <td>
      {{ user.email }}
      <span class="email-flag">
        {{ user.isAdmin ? $t("emailAccounts.emailList.admin") : "" }}
        {{ user.isAdmin && catchAllEmail === user.email ? " | " : "" }}
        {{
          catchAllEmail === user.email
            ? $t("emailAccounts.emailList.catchAllAccount")
            : ""
        }}
      </span>
    </td>
    <td>
      <span
        :class="{
          active: user.status === 'active',
          suspended: user.status === 'suspended',
        }"
        class="status"
        :id="user.id.toString()"
        @mouseover="
          () => {
            user.status === 'suspended' && openSuspensionList(user);
          }
        "
      >
        {{
          user.status === "active"
            ? $t("emailAccounts.emailList.active")
            : $t("emailAccounts.emailList.suspended")
        }}
        <img
          alt="suspended icon"
          v-if="user.status === 'suspended'"
          src="../../assets/suspension-info.svg"
          class="info-icon"
        />
      </span>
      <suspension-list
        v-if="user.status === 'suspended' && user.id === activeUserId"
        :user="user"
        :openUnsuspendModal="() => openUnsuspendModal(user)"
        :suspensionData="suspensionData"
        :yourSuspension="yourSuspension"
        :otherSuspension="otherSuspension"
      />
    </td>
    <td>
      <div class="usage-status">
        {{
          $t("emailAccounts.emailList.used", [
            Math.min(
              ((user.quotaUsed * 100) / user.quotaLimit).toFixed(2),
              100
            ),
          ])
        }}
      </div>
      <div class="usage-status usage-available">
        {{
          $t("emailAccounts.emailList.storageUsed", {
            used: getFormattedStorageSize(user.quotaUsed),
            total: getFormattedStorageSize(user.quotaLimit),
          })
        }}
      </div>
    </td>
    <td style="position: relative; text-align: right">
      <button
        v-if="showShareLoginInfoBtn"
        class="share-login-info-btn"
        @click="handleShareLoginInfoClick(user.email)"
      >
        {{ $t("emailAccounts.emailList.share_login_info") }}
      </button>
      <TitanContextMenu
        width="215px"
        class="manage-email-options"
        position="bottom-left"
      >
        <!-- Default slot for the toggle-able text content -->
        <template v-slot="{ toggle, on }">
          <img
            src="../../assets/overflow-grey.svg"
            class="overflow-grey"
            alt="overflow"
            @click="prefetchDataAndToggle(on, toggle)"
          />
        </template>
        <!-- Named slot for rendering each item in the list -->
        <template v-slot:content>
          <li @click="openWebmail">
            {{ $t("emailAccounts.emailList.accessWebmail") }}
          </li>
          <li @click="onResetPassword(user.email)">
            {{ $t("emailAccounts.emailList.resetPassword") }}
          </li>
          <li @click="onManageAliasClick">
            {{ $t("emailAccounts.emailList.manageAlias") }}
          </li>
          <li
            v-if="!isNeoBrand || !user.isAdmin || !isLastAdminAccount"
            @click="toggleManageAdminRights"
          >
            {{
              $t(
                `emailAccounts.emailList.${
                  user.isAdmin ? "disableAdmin" : "makeAdmin"
                }`
              )
            }}
          </li>
          <li v-if="user.status === 'active'" @click="suspendAccount">
            {{ $t("emailAccounts.emailList.suspendAccount") }}
          </li>
          <li @click="deleteAccount" v-if="isNeoBrand || !isLastAccount">
            {{ $t("emailAccounts.emailList.deleteAccount") }}
          </li>
          <li
            v-if="isTwoFactorAuthEnabled(user)"
            @click="toggleDisable2FAModal"
          >
            {{ $t("emailAccounts.emailList.disable2FA") }}
          </li>
        </template>
      </TitanContextMenu>
    </td>
    <reset-password
      :email="user.email"
      :mailboxAge="this.getDaysSinceDomainCreation(user.createdOnEpochMillis)"
      v-if="showResetPassword"
      @close="showResetPassword = false"
      :view="view"
    />
    <manage-alias
      :email="user.email"
      :aliases="this.aliases"
      @onAddAlias="onAddAlias"
      @onRemoveAlias="onRemoveAlias"
      v-if="showManageAlias"
      @close="showManageAlias = false"
      :view="view"
      :getAliasList="getAliasList"
      @onEdit="onEditAlias"
      :manageAliasMedusaParams="manageAliasMedusaParams"
    />
    <suspend-account
      :user="user"
      v-if="showSuspendAccount"
      @close="showSuspendAccount = false"
      :view="view"
    />
    <unsuspend-account
      :multiSuspension="multiSuspension"
      :user="user"
      v-if="showUnsuspendAccount"
      @close="showUnsuspendAccount = false"
      :suspensionType="yourSuspension[0].suspensionType"
      :view="view"
    />
    <delete-account
      :isCatchAllEmail="catchAllEmail === user.email"
      :user="user"
      :on-remove="onRemove"
      v-if="showDeleteAccount"
      @close="showDeleteAccount = false"
      :view="view"
      :isLastAdminAccount="isLastAdminAccount"
    />
    <last-account-delete-dialog
      v-if="showLastDeleteAccountModal"
      :user="user"
      :onConfirmCreateAndDeleteEmail="onConfirmCreateAndDeleteEmail"
      @close="showLastDeleteAccountModal = false"
      :view="view"
    />
    <!--
      The hasAdmin prop is hardcoded as false because we are deleting the last admin account.
      When hasAdmin is false, the create-email component's checkbox for "Make this email account an admin"
      will be default checked. This ensures that there will always be at least one admin account in the system.
    -->
    <create-email
      v-if="showCreateEmail"
      @close="showCreateEmail = false"
      :hasAdmin="false"
      :view="view"
      :emailToDeleteDetails="{
        accountId: user.id,
        emailId: user.email,
      }"
    />
    <manage-admin-rights
      :user="user"
      v-if="showManageAdminRights"
      @close="toggleManageAdminRights"
      :view="view"
    />
    <disable-2FA
      :user="user"
      v-if="showDisable2FAModal"
      @close="toggleDisable2FAModal"
      :view="view"
    />
  </tr>
</template>

<script>
import ShareLoginInfoModal from "@/components/EmailAccounts/ShareLoginInfoModal.vue";
import { COMMON_MODAL_EMITTERS } from "@/helpers/const";
import eventBus from "@/helpers/event-bus";
import { mapGetters } from "vuex";
import { calculateDateDifference } from "@/helpers";
import ResetPassword from "./ResetPassword";
import ManageAlias from "./ManageAlias";
import SuspendAccount from "./SuspendAccount";
import UnsuspendAccount from "./UnsuspendAccount";
import DeleteAccount from "./DeleteAccount";
import ManageAdminRights from "./ManageAdminRights";
import Disable2FA from "./Disable2FA";
import * as helper from "../../helpers";
import SuspensionList from "./SuspensionList";
import LastAccountDeleteDialog from "./LastAccountDeleteDialog.vue";
import CreateEmail from "./CreateEmail.vue";

const DAYS_TO_SHOW_SHARE_LOGIN_BTN = 30;

export default {
  name: "EmailList",
  props: {
    catchAllEmail: String,
    user: Object,
    onRemove: Function,
    activeUserId: Number,
    setActiveUserId: Function,
    view: Object,
    isLastAccount: Boolean,
    isLastAdminAccount: Boolean,
  },
  components: {
    ResetPassword,
    ManageAlias,
    SuspendAccount,
    UnsuspendAccount,
    DeleteAccount,
    ManageAdminRights,
    SuspensionList,
    Disable2FA,
    LastAccountDeleteDialog,
    CreateEmail,
  },
  data() {
    return {
      getFormattedStorageSize: helper.getFormattedStorageSize,
      getAvailableSpace: helper.getAvailableSpace,
      isNeoBrand: helper.isNeoBrand(),
      showManageAdminRights: false,
      showResetPassword: false,
      showManageAlias: false,
      showSuspendAccount: false,
      showUnsuspendAccount: false,
      showDeleteAccount: false,
      showLastDeleteAccountModal: false,
      showCreateEmail: false,
      inFocus: false,
      isSent: false,
      suspensionData: { suspensions: [] },
      multiSuspension: false,
      yourSuspension: [],
      otherSuspension: [],
      aliases: [],
      showDisable2FAModal: false,
      showShareLoginInfoBtn: false,
    };
  },
  computed: {
    ...mapGetters(["accountId", "partnerId", "currentDomainMailboxDetails"]),
    ...mapGetters({
      currentDomainDetails: "domain/currentDomainDetails",
      domainName: "domain/domainName",
      domainID: "domain/domainID",
    }),
    manageAliasMedusaParams() {
      return {
        partner_id: this.partnerId,
        admin_user:
          smartStorage.getItem("loginEmail") ||
          smartStorage.getItem("tempUserAltEmail") ||
          null,
        order_id: this.domainID,
        account_id: +this.accountId,
      };
    },
  },
  methods: {
    isTwoFactorAuthEnabled(user) {
      const { flags } = user;
      if (!flags?.length) return false;
      return Boolean(~flags?.indexOf("TWO_FA_ENABLED"));
    },
    prefetchDataAndToggle(on, toggle) {
      toggle();
      if (!on) this.getAliasList(); // List of data to be prefetched
    },
    onManageAliasClick() {
      this.showManageAlias = true;
      const eventObj = {
        eventName: "manage_alias_button_click",
        no_of_alias_existing: this.aliases.length,
        primary_account: this.user.email,
        ...this.manageAliasMedusaParams,
      };
      this.logMedusa(eventObj);
    },
    formatListdata(data) {
      this.yourSuspension = [];
      this.otherSuspension = [];
      data.suspensions.forEach((el) => {
        if (el.unsuspendable) {
          this.yourSuspension.push(el);
        } else {
          this.otherSuspension.push(el);
        }
      });
    },
    openUnsuspendModal() {
      this.multiSuspension = this.suspensionData.suspensions.some(
        (el) => !el.unsuspendable
      );
      this.showUnsuspendAccount = true;
    },
    openSuspensionList(user) {
      this.setActiveUserId(user.id);
      this.suspensionData = { suspensions: [] };
      this.http.getData(
        `/panel/account/email/get-all-suspensions?accountId=${user.id}&domainId=${this.domainID}`,
        this.$root.headers,
        (data) => {
          this.suspensionData = data;
          if (!data.suspensions.length) {
            this.user.status = "active";
            return;
          }
          this.formatListdata(this.suspensionData);
        },
        () => {
          this.setActiveUserId(-1);
          this.showToast(
            this.$t("emailAccounts.suspensionList.suspensionAPIError"),
            1000
          );
        }
      );
    },
    deleteAccount() {
      // This modification is specific to the 'Neo' flow, allowing the deletion of the last mailbox and simultaneous email creation. The 'Titan' flow will remain unchanged.
      if (this.isLastAccount && this.isNeoBrand) {
        this.showLastDeleteAccountModal = true;
        return;
      }
      this.showDeleteAccount = true;
    },
    suspendAccount() {
      this.showSuspendAccount = true;
    },
    toggleDisable2FAModal() {
      this.showDisable2FAModal = !this.showDisable2FAModal;
    },
    toggleManageAdminRights() {
      this.showManageAdminRights = !this.showManageAdminRights;
    },
    onMouseEnter: function () {
      this.inFocus = true;
    },
    onResetPassword: function (email) {
      this.showResetPassword = true;
      const eventObj = {
        email,
        eventName: "reset_password_initiated",
        mailbox_age_in_days: this.getMailboxAge(email),
        source_hook: "Reset password CTA",
      };
      this.logMedusa(eventObj);
    },
    onMouseLeave: function () {
      this.inFocus = false;
    },
    sortAliases() {
      this.aliases.sort((a, b) => {
        let cmpDisplayName = a.displayName.localeCompare(b.displayName);
        let cmpEmail = a.email.localeCompare(b.email);
        if (cmpDisplayName > 0) return 1;
        else if (cmpDisplayName === 0) return cmpEmail > 0 ? 1 : -1;
        else return -1;
      });
    },
    onAddAlias(alias) {
      this.aliases = [alias, ...this.aliases];
      this.sortAliases();
    },
    onRemoveAlias(alias) {
      this.aliases = this.aliases.filter((item) => item.email !== alias.email);
    },
    onEditAlias(userEmail, newDisplayName) {
      this.aliases = this.aliases.map((item) => {
        if (item.email === userEmail) {
          item.displayName = newDisplayName;
        }
        return item;
      });
      this.sortAliases();
    },
    getAliasList() {
      if (!this.isSent) {
        this.isSent = true;
        return this.http.getData(
          "panel/account/email/alias/list",
          this.$root.headers,
          this.onSuccess,
          this.onError,
          {
            email: this.user.email,
            domainName: this.domainName,
          }
        );
      }
    },
    openWebmail() {
      helper.openWebmail(this.user.email);
      var eventObj = {
        eventName: "access_webmail_hook_click",
        source_hook: "cloud_email_listing",
      };
      this.logMedusa(eventObj);
    },
    getEmailList() {
      return this.$parent.getEmailList();
    },
    onSuccess(data) {
      if (data) {
        this.aliases = data.aliases;
        this.sortAliases();
        this.isSent = false;
      }
    },
    onError(response) {
      if (response && response.data && response.data.desc) {
        this.showToast(response.data.desc);
        this.isSent = false;
      }
    },
    getDaysSinceDomainCreation(createdAt) {
      return Math.abs(calculateDateDifference(createdAt));
    },
    shouldShowShareLogin() {
      return (
        this.getDaysSinceDomainCreation(this.currentDomainDetails.createdAt) <=
        DAYS_TO_SHOW_SHARE_LOGIN_BTN
      );
    },
    getMailboxAge(email) {
      const mailboxClicked = this.currentDomainMailboxDetails.find(
        (mailbox) => email === mailbox.email
      );
      return this.getDaysSinceDomainCreation(
        mailboxClicked.createdOnEpochMillis
      );
    },
    handleShareLoginInfoClick(email) {
      const mailboxAge = this.getMailboxAge(email);
      const eventObj = {
        email,
        eventName: "reset_password_initiated",
        mailbox_age_in_days: mailboxAge,
        source_hook: "Share login info CTA",
      };
      this.logMedusa(eventObj);

      const { OPEN_MODAL } = COMMON_MODAL_EMITTERS;
      eventBus.$emit(OPEN_MODAL, {
        component: ShareLoginInfoModal,
        props: {
          email,
          mailboxAge,
        },
        modalWidth: "500px",
      });
    },
    logMedusa(eventObj) {
      this.medusaEvents.send(
        this.medusaEvents.createEventObj(
          eventObj,
          this.user.email,
          "flock_account"
        )
      );
    },
    onConfirmCreateAndDeleteEmail() {
      this.showLastDeleteAccountModal = false;
      this.showCreateEmail = true;
    },
  },
  created() {
    this.showShareLoginInfoBtn = this.shouldShowShareLogin();
  },
};
</script>
<style lang="scss" scoped>
.share-login-info-btn {
  font-size: 14px;
  margin-right: 30px;
  font-weight: 600;
  color: var(--primaryBtnCol);
  border: 1px solid var(--primaryBtnCol);
  background-color: transparent;
  padding: 5px 10px;
  border-radius: 4px;
}

.overlay-for-click {
  position: fixed;
  z-index: 9999;
  display: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.email-flag {
  display: block;
  font-size: 12px;
}
.info-icon {
  padding-right: 5px;
}
.suspended:hover {
  cursor: pointer;
}
.reset-password {
  color: var(--secondaryBtnCol) !important;
  border: 1px solid var(--secondaryBtnCol) !important;
}
</style>
