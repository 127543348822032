<template>
  <transition name="modal-fade">
    <div class="modal-overlay">
      <div
        class="modal-body-container transaction-failed-modal"
        :class="{ 'iframe-mode': isIframe }"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="Transaction Failed Modal"
      >
        <div class="modal-content-wrapper">
          <img src="../../assets/billing-transaction-failed.svg" />
          <h4>
            {{ $t("billingAndUpgrade.transactionFailed.title") }}
          </h4>
          <p>
            {{ $t("billingAndUpgrade.transactionFailed.message") }}
          </p>
          <button @click="close" class="button">
            {{ $t("billingAndUpgrade.transactionFailed.ok") }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
  name: "TransactionFailedModal",
  mixins: [clickaway],
  data() {
    return {
      isIframe: document.documentElement.getAttribute("view") === "widget",
    };
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
  },
};
</script>
<style lang="scss">
.transaction-failed-modal {
  width: calc(min(450px, 90vw));
  text-align: center;
  color: var(--primaryTextCol);

  img {
    display: inline-block;
    width: 100%;
    margin-bottom: 28px;
  }

  p {
    margin: 8px 0 24px;
  }
}
</style>
