<template>
  <div class="modal-container" :style="computedStyles" v-if="showModal">
    <img
      src="@/assets/modal-close.svg"
      class="cross-close pointer"
      @click="handleCloseModal"
      v-if="showClose"
    />
    <slot name="header" v-if="$scopedSlots.header" />
    <slot name="content" v-if="$scopedSlots.content" />
    <slot name="submit" v-if="$scopedSlots.submit">
      <titan-button
        @click="handleSubmitAndCloseModal"
        :class="submitBtnClass"
        >{{ submitBtnText || $t("welcomeScreen.ok") }}</titan-button
      >
    </slot>
    <slot name="close" v-if="$scopedSlots.close">
      <titan-button @click="handleCloseModal" :class="closeBtnClass">{{
        closeBtnText || $t("welcomeScreen.cancel")
      }}</titan-button>
    </slot>
    <slot name="footer" v-if="$scopedSlots.footer" />
  </div>
</template>
<script>
import TitanButton from "./TitanButton.vue";
export default {
  components: { TitanButton },
  data() {
    return {
      showModal: true,
    };
  },
  props: {
    showClose: {
      type: Boolean,
      default: true,
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
    maxHeight: {
      type: Number,
      default: 500,
    },
    maxWidth: {
      type: Number,
      default: 500,
    },
    handleSubmit: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    handleClose: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    submitBtnText: {
      type: String,
    },
    submitBtnClass: {
      type: String,
      default: "",
    },
    closeBtnClass: {
      type: String,
      default: "",
    },
    closeBtnText: {
      type: String,
    },
  },
  computed: {
    computedStyles() {
      return {
        ...this.styles,
        maxWidth: this.maxWidth + "px",
        maxHeight: this.maxHeight + "px",
      };
    },
  },
  methods: {
    handleCloseModal() {
      this.showModal = false;
      this.handleClose();
    },
    handleSubmitAndCloseModal() {
      this.handleCloseModal();
      this.handleSubmit();
    },
  },
};
</script>
<style lang="scss">
.modal-container {
  padding: 40px 50px;
  display: inline-block;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  background-color: var(--tabBgCol);
  z-index: 200;
  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
  .cross-close {
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
</style>
