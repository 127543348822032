<template>
  <div>
    <div
      :class="[
        config.showModal ? 'overlay' : 'hide',
        { 'widget-modal-background': view.showModalAsPage },
      ]"
    ></div>
    <div
      :class="[
        'modal-container',
        config.className,
        {
          hide: !config.showModal,
          fixed: config.keepFixed,
          leftAlignItems: view.showModalAsPage,
          removeTopPadding: view.showModalAsPage,
        },
      ]"
    >
      <widget-breadcrumb-nav
        :isModal="true"
        :modalName="config.action || 'action'"
        v-if="view.showModalAsPage"
        @onNavClick="hide"
      />
      <img
        :src="closeBtnImg"
        @click="hide"
        class="closeBtnImg"
        v-if="!view.showModalAsPage"
      />
      <img
        :width="config.imgWidth || 'auto'"
        :height="config.imgHeight || 'auto'"
        :src="getImageUrl()"
      />
      <h3>{{ config.modalHeading }}</h3>
      <span
        class="subHeading"
        v-if="config.subHeading"
        v-html="config.subHeading"
      ></span>
      <span
        :class="['btn']"
        @click="config.btnCB(hide)"
        v-if="config.btnTxt && !this.flags.hideIFrameBillingSection"
        >{{ config.btnTxt }}</span
      >
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Close from "../../assets/close-success-modal.png";
export default {
  name: "Modal",
  props: ["config", "view"],
  data() {
    return {
      closeBtnImg: Close,
    };
  },
  methods: {
    hide() {
      this.config.showModal = false;
    },
    getImageUrl() {
      return this.config.themeImg
        ? this.$options.filters.themeImage(
            this.config.themeImg,
            this.config.themeImgExt
          )
        : this.config.imgUrl;
    },
  },
  computed: {
    ...mapGetters(["flags"]),
  },
};
</script>
<style scoped lang="scss">
.disableButton {
  pointer-events: none;
}
.hide {
  display: none;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background: var(--boxShadowCol10);
}
.widget-modal-background {
  background: var(--primaryBgCol);
}
.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 500px;
  background-color: var(--tabBgCol);
  text-align: center;
  padding: 40px;
  border-radius: 4px;
  &.fixed {
    position: fixed;
  }
  &.error-modal {
    width: 400px;
    img {
      margin-bottom: 45px;
    }
    .subHeading {
      margin-bottom: 27px;
    }
  }
  img {
    margin-bottom: 25px;
  }
  h3 {
    font-size: 20px;
    font-weight: normal !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    color: var(--primaryTextCol);
  }
  .subHeading {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .btn {
    color: var(--primaryBtnTextCol);
    background: var(--primaryBtnCol);
    font-size: 14px;
    padding: 7px 20px;
    cursor: pointer;
  }
  .closeBtnImg {
    position: absolute;
    right: 16px;
    top: 16px;
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
}
.removeTopPadding {
  padding-top: 0;
}
</style>
