<template>
  <div class="mobile-home-container">
    <div class="wrapper">
      <plan-card />
      <mobile-nav-bar :sections="getVisibleSections" />
      <change-billing-cycle
        @close="toggleBillingCycleModal"
        v-if="showChangeBillingCycleModal"
        :addons="addons"
        :billingDetails="billingDetails"
        :currency="currency"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PlanCard from "./PlanCard.vue";
import MobileNavBar from "../MobileNavBar/MobileNavBar.vue";
import ChangeBillingCycle from "../BillingAndSubscription/ChangeBillingCycle.vue";
import { billing, currency as currencyMixin } from "@/mixins";
import { FLOW_TYPES } from "@/helpers/const";
import { removeQueryParamFromRoute } from "@/helpers/router";
import { measurePagePerformance } from "@/analytics/performance/pageTimings";
import { getSections } from "../MobileNavBar/config";
import { isTrialPlan } from "@/helpers/plansHelpers";

const { CHANGE_BILLING_CYCLE } = FLOW_TYPES;

export default {
  components: {
    PlanCard,
    MobileNavBar,
    ChangeBillingCycle,
  },
  props: { addons: Array },
  mixins: [billing, currencyMixin],
  computed: {
    ...mapGetters(["products"]),
    ...mapGetters({
      domainID: "domain/domainID",
      domainName: "domain/domainName",
      mailProductDetails: "mail/currentProductDetails",
      siteProductDetails: "site/currentProductDetails",
    }),
    getVisibleSections() {
      return getSections(this.products, this.domainName, this.domainID, {
        mail: isTrialPlan(this.mailProductDetails),
        site: isTrialPlan(this.siteProductDetails),
      });
    },
    ...mapGetters({
      plans: "mail/plans",
    }),
    showChangeBillingCycleModal() {
      return this.$route.query[CHANGE_BILLING_CYCLE] === "true" || false;
    },
  },
  methods: {
    toggleBillingCycleModal(refetchCurrentPlan) {
      if (this.showChangeBillingCycleModal) {
        this.removeQueryParam();
      } else {
        this.$route.query[CHANGE_BILLING_CYCLE] = "true";
      }
      if (refetchCurrentPlan) {
        this.$emit("fetchCurrentPlan");
      }
    },
    removeQueryParam() {
      removeQueryParamFromRoute(
        this.$route,
        this.$router,
        CHANGE_BILLING_CYCLE
      );
    },
  },
  mounted() {
    measurePagePerformance();
  },
};
</script>

<style scoped>
.wrapper {
  padding: 20px 16px;
}
</style>
