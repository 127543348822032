<template>
  <div>
    <b-form class="parentContainer" @submit.stop.prevent="onSubmit">
      <button class="backBtn" @click="() => changeIndex(-1)">
        <img src="../../assets/back-arrow.svg" alt="" />
        {{ $t("purchaseFlow.goBack") }}
      </button>
      <div class="header">
        {{ $t("purchaseFlow.billingAndPayment.heading") }}
      </div>
      <b-form-group
        class="emailAddr"
        :label="$t('purchaseFlow.billingAndPayment.sections.email.label')"
        label-for="emailAddr"
      >
        <b-form-input
          type="email"
          id="emailAddr"
          aria-describedby="emailAddr"
          v-model="$v.form.emailAddr.$model"
          :state="validateState('emailAddr')"
          required
        />
        <b-form-invalid-feedback id="emailAddr">{{
          $t("purchaseFlow.billingAndPayment.sections.email.error")
        }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-row>
        <b-form-group
          class="userName col-md-6"
          :label="$t('purchaseFlow.billingAndPayment.sections.name.label')"
          label-for="userName"
        >
          <b-form-input
            id="userName"
            aria-describedby="userName"
            v-model="$v.form.userName.$model"
            :state="validateState('userName')"
            required
          />
          <b-form-invalid-feedback id="userName">{{
            $t("purchaseFlow.billingAndPayment.sections.name.error")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="companyName col-md-6"
          :label="$t('purchaseFlow.billingAndPayment.sections.company.label')"
          label-for="companyName"
        >
          <b-form-input
            id="companyName"
            v-model="form.companyName"
            aria-describedby="companyName"
          />
        </b-form-group>
      </b-form-row>
      <b-form-group
        class="companyAddress"
        :label="$t('purchaseFlow.billingAndPayment.sections.address.label')"
        label-for="companyAddress"
      >
        <b-form-input
          id="companyAddress"
          v-model="$v.form.companyAddress.$model"
          aria-describedby="companyAddress"
          :state="validateState('companyAddress')"
        />
        <b-form-invalid-feedback id="companyAddress">{{
          $t("purchaseFlow.billingAndPayment.sections.address.error")
        }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-row>
        <b-form-group
          class="country col-md-6"
          :label="$t('purchaseFlow.billingAndPayment.sections.country.label')"
          label-for="country"
        >
          <b-form-select
            value-field="code"
            text-field="name"
            id="country"
            v-model="$v.form.country.$model"
            :options="countryOptions"
            aria-describedby="country"
            :state="validateState('country')"
          />
          <b-form-invalid-feedback id="country">{{
            $t("purchaseFlow.billingAndPayment.sections.country.error")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="country col-md-6"
          :label="$t('purchaseFlow.billingAndPayment.sections.city.label')"
          label-for="city"
        >
          <b-form-input
            id="city"
            v-model="$v.form.city.$model"
            required
            aria-describedby="city"
            :state="validateState('city')"
          />
          <b-form-invalid-feedback id="city">{{
            $t("purchaseFlow.billingAndPayment.sections.city.error")
          }}</b-form-invalid-feedback>
        </b-form-group>
      </b-form-row>
      <!-- we will required this later in 7 days. so commenting it for now -->
      <b-form-row v-if="form.country === 'US'">
        <b-form-group
          class="state col-md-6"
          :label="$t('purchaseFlow.billingAndPayment.sections.state.label')"
          label-for="userName"
        >
          <b-form-select
            value-field="code"
            text-field="name"
            id="state"
            v-model="$v.form.state.$model"
            :options="stateOptions"
            aria-describedby="state"
            :state="validateState('state')"
          />
          <b-form-invalid-feedback id="state">{{
            $t("purchaseFlow.billingAndPayment.sections.state.error")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="postalCode col-md-6"
          :label="
            $t('purchaseFlow.billingAndPayment.sections.postalCode.label')
          "
        >
          <b-form-input
            id="postalCode"
            v-model="$v.form.postalCode.$model"
            aria-describedby="postalCode"
            required
            :state="validateState('postalCode')"
          />
          <b-form-invalid-feedback id="postalCode">{{
            $t("purchaseFlow.billingAndPayment.sections.postalCode.error")
          }}</b-form-invalid-feedback>
        </b-form-group>
      </b-form-row>
      <div v-bind:key="item" v-for="item in currCountryTaxDetails.taxIds">
        <b-form-group
          class="taxTypes"
          :label="$t(`purchaseFlow.billingAndPayment.sections.${item}.label`)"
          :key="item"
        >
          <b-form-input
            class="taxField"
            :id="`${item}`"
            v-model="$v.form[item].$model"
            :aria-describedby="`${item}`"
            :state="validateState(item)"
          />
          <b-form-invalid-feedback :id="`${item}`">{{
            $t(`purchaseFlow.billingAndPayment.sections.${item}.error`)
          }}</b-form-invalid-feedback>
        </b-form-group>
      </div>
      <button
        :class="{ 'show-spinner': showButtonSpinner }"
        class="submitBtn"
        type="submit"
        formnovalidate
        :disabled="isDisabled"
      >
        {{ $t("purchaseFlow.proceedToPayment") }}
      </button>
    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRow,
  BFormSelect,
} from "bootstrap-vue";

import { validationMixin } from "vuelidate";
import { required, email, requiredIf, helpers } from "vuelidate/lib/validators";
import { COUNTRY, STATES_IN_US, REGX } from "../../helpers/const";

const mustBeAValidUSPostalCode = (value) =>
  !helpers.req(value) || REGX.USPostalCode.test(value);
export default {
  name: "BillingForm",
  mixins: [validationMixin],
  mounted() {
    this.isDisabled = true;
    this.http.getData(
      `/internal/get-tax-details`,
      this.$root.headers,
      this.onGetTaxDetailsSuccess,
      this.onGetTaxDetailsFailure
    );
    this.checkForBillAddr();
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRow,
    BFormSelect,
  },
  props: {
    changeIndex: Function,
    purchaseAuthData: Object,
    purchaseFlowJWTPayload: Object,
  },
  validations: {
    form: {
      emailAddr: {
        required,
        email,
      },
      userName: {
        required,
      },
      companyAddress: {
        required,
      },
      country: {
        required,
      },
      city: {
        required,
      },
      state: {
        required: requiredIf((data) => {
          return data.country === "US";
        }),
      },
      postalCode: {
        required: requiredIf((data) => {
          return data.country === "US";
        }),
        mustBeAValidUSPostalCode,
      },
      vat_id: {},
      GSTIN: {},
      CSID: {},
    },
  },
  watch: {
    "form.country": {
      handler: function () {
        this.setTaxDetails(true);
      },
      deep: true,
    },
  },
  data() {
    const billingInfoObject =
      JSON.parse(smartStorage.getItem("billingAddress")) || {};
    const storedPrimaryDetails =
      billingInfoObject && billingInfoObject.billingAddress
        ? billingInfoObject.billingAddress
        : {};
    const storedTaxDetails = {};
    (billingInfoObject && billingInfoObject.customerTaxDetails
      ? billingInfoObject.customerTaxDetails.taxDetails
      : []
    ).forEach((el) => {
      storedTaxDetails[el.taxId] = el.value;
    });
    return {
      isDisabled: false,
      form: {
        emailAddr: storedPrimaryDetails.email,
        companyAddress: storedPrimaryDetails.companyAddress,
        city: storedPrimaryDetails.city,
        country: storedPrimaryDetails.country,
        userName: storedPrimaryDetails.name,
        companyName: storedPrimaryDetails.companyName,
        state: storedPrimaryDetails.state || null,
        vat_id: storedTaxDetails.vat_id,
        GSTIN: storedTaxDetails.GSTIN,
        CSID: storedTaxDetails.CSID,
        postalCode: storedPrimaryDetails.zipCode || null,
      },
      JWTcountryData: { name: "Select", code: null },
      countryOptions: [{ name: "Select country", code: null }, ...COUNTRY],
      stateOptions: [{ name: "Select state", code: null }, ...STATES_IN_US],
      showButtonSpinner: false,
      allCountryTaxDetails: [],
      currCountryTaxDetails: { taxIds: [] },
    };
  },
  updated() {
    this.isDisabled = false;
  },
  methods: {
    checkForTaxField(taxField) {
      return this.currCountryTaxDetails.taxIds.includes(taxField);
    },
    setTaxDetails(emptyFlag) {
      this.state = null;
      this.currCountryTaxDetails.taxIds = [];
      const taxDetails =
        this.allCountryTaxDetails.filter(
          (details) => details.countryCode === this.form.country
        ) || [];
      this.currCountryTaxDetails.taxIds =
        taxDetails && taxDetails[0] ? taxDetails[0].taxIds : [];
      this.currCountryTaxDetails.taxIds.forEach((element) => {
        if (emptyFlag) this.form[element] = "";
      });
    },
    onGetTaxDetailsSuccess(res) {
      this.allCountryTaxDetails = [...res.taxDetails];
      this.setTaxDetails(false);
      this.isDisabled = false;
    },
    onGetTaxDetailsFailure() {
      this.$emit("handleAPIFailure");
      this.isDisabled = false;
    },
    checkForBillAddr() {
      const {
        customerName,
        customerEmailAddress,
        customerCountry,
      } = this.purchaseFlowJWTPayload;
      const existingBillingInfo =
        this.purchaseAuthData.params.billingAddress || {};
      this.JWTcountryData =
        COUNTRY.filter((el) => el.code === customerCountry)[0] || {};

      if (!this.form.emailAddr)
        this.form.emailAddr = customerEmailAddress || existingBillingInfo.email;
      if (!this.form.userName)
        this.form.userName = customerName || existingBillingInfo.name;
      if (!this.form.country)
        this.form.country =
          this.JWTcountryData.code || existingBillingInfo.country || null;
      if (!this.form.city) this.form.city = existingBillingInfo.city;
      if (!this.form.companyName)
        this.form.companyName = existingBillingInfo.companyName;
      if (!this.form.companyAddress)
        this.form.companyAddress = existingBillingInfo.companyAddress;
      if (!this.form.state) {
        this.form.state = existingBillingInfo.state || null;
      }
      if (!this.form.postalCode) {
        this.form.postalCode = existingBillingInfo.zipCode;
      }
      if (!this.purchaseAuthData.params.customerTaxDetails) {
        return;
      }
      const taxDetailsObj = this.purchaseAuthData.params.customerTaxDetails || {
        taxDetails: [],
      };
      taxDetailsObj.taxDetails.forEach((field) => {
        if (!this.form[field.taxId]) this.form[field.taxId] = field.value;
      });
    },
    getTaxDetailsData() {
      const taxArray = [];
      this.currCountryTaxDetails.taxIds.forEach((tax) => {
        this.form[tax] &&
          taxArray.push({
            taxId: tax,
            value: this.form[tax],
          });
      });
      return taxArray;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.showButtonSpinner = true;
      if (this.isDisabled) {
        return;
      }
      this.isDisabled = !this.isDisabled;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.showButtonSpinner = false;
        this.isDisabled = false;
        return;
      }
      let billingInfoObject = {
        billingAddress: {},
      };
      let primaryAddress = {
        email: this.form.emailAddr,
        name: this.form.userName,
        companyName: this.form.companyName,
        companyAddress: this.form.companyAddress,
        city: this.form.city,
        country: this.form.country,
      };
      if (this.form.country === "US") {
        primaryAddress = {
          ...primaryAddress,
          state: this.form.state,
          zipCode: this.form.postalCode,
        };
      } else if (this.currCountryTaxDetails.taxIds.length) {
        const customerTaxDetails = {
          taxDetails: this.getTaxDetailsData(),
        };
        if (customerTaxDetails.taxDetails.length)
          billingInfoObject = {
            ...billingInfoObject,
            customerTaxDetails,
          };
      }
      billingInfoObject.billingAddress = { ...primaryAddress };
      this.$root.headers = {
        headers: { Authorization: this.purchaseAuthData.params.token },
      };
      this.http.postData(
        "/panel/purchase/upsert-customer",
        billingInfoObject,
        this.$root.headers,
        () => {
          // this must be needed to pre-populate the form for next time
          smartStorage.setItem(
            "billingAddress",
            JSON.stringify(billingInfoObject)
          );
          this.changeIndex(1);
          this.showButtonSpinner = false;
        },
        () => {
          this.$emit("handleAPIFailure");
          this.showButtonSpinner = false;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.parentContainer {
  width: 577px;
  margin: 0 auto;
  border: 1px solid var(--lineSepaCol);
  padding: 44px;
  .header {
    font-size: 20px;
    padding: 20px 0 40px;
  }
  .backBtn {
    padding-left: 0;
    background: none;
    border: none;
    color: var(--secondaryTextCol);
  }
  .emailAddr,
  .userName,
  .companyName,
  .country,
  .city,
  .companyAddress,
  .state,
  .postalCode,
  .taxTypes {
    font-weight: 600 !important;
    margin-bottom: 20px;
  }
  .emailAddrVal {
    background: var(--tabBgCol);
  }
  .submitBtn {
    background: var(--primaryBtnCol);
    border-radius: 5px;
    padding: 12px 15px;
    color: var(--tabBgCol);
    border: none;
    font-weight: bold;
    margin-top: 20px;
    outline: none;
  }
  .show-spinner {
    @include button-loader();
  }
}
.form-control,
.form-control.is-valid,
.custom-select,
.custom-select.is-valid {
  border: 1px solid var(--inputBorderCol);
}
.form-control.is-valid,
.form-control.is-invalid {
  background-image: none;
}
.custom-select,
.custom-select.is-invalid,
.custom-select.is-valid {
  background-image: url("../../assets/dropdown-arrow.svg");
  background-size: 30px 35px;
  cursor: pointer;
}
</style>
