var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-fade"}},[_c('div',{staticClass:"modal-overlay"},[_c('div',{class:[
        'modal-body-container',
        'suspend-account-modal',
        {
          modalPage: _vm.view.showModalAsPage,
        },
      ],attrs:{"role":"dialog","aria-labelledby":"modalTitle","aria-describedby":"modalDescription"}},[(!_vm.view.showModalAsPage)?_c('button',{staticClass:"btn-close",attrs:{"type":"button","aria-label":"Close modal"},on:{"click":_vm.close}},[_vm._v(" × ")]):_vm._e(),(_vm.view.showModalAsPage)?_c('widget-breadcrumb-nav',{attrs:{"isModal":true,"modalName":"suspend-account"},on:{"onNavClick":_vm.close}}):_vm._e(),_c('div',{staticClass:"modal-content-wrapper"},[_c('div',[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.suspendAccount.heading"))+" ")]),_c('p',{staticClass:"email"},[_vm._v(_vm._s(_vm.user.email))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.suspendAccount.reasonHeader"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.suspensionReason),expression:"suspensionReason"}],staticClass:"reason-input",attrs:{"type":"text","placeholder":this.$t(`emailAccounts.suspendAccount.reasonPlaceholder`)},domProps:{"value":(_vm.suspensionReason)},on:{"input":function($event){if($event.target.composing)return;_vm.suspensionReason=$event.target.value}}}),_c('p',{staticClass:"color-grey"},[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.suspendAccount.onceSuspend"))+" "),_c('br'),_vm._v(" • "+_vm._s(_vm.$t("emailAccounts.suspendAccount.firstPoint"))),_c('br'),_vm._v(" • "+_vm._s(_vm.$t("emailAccounts.suspendAccount.secondPoint"))),_c('br'),_vm._v(" • "+_vm._s(_vm.$t("emailAccounts.suspendAccount.thirdPoint"))+" ")]),_c('div',{staticClass:"input-wrapper"},[_c('button',{staticClass:"button",on:{"click":_vm.suspendAccount}},[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.deleteAccount.confirmBtn"))+" ")]),_c('button',{staticClass:"button button__line-blue",on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.deleteAccount.cancelBtn"))+" ")])])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }