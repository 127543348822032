/**
 * Return UTC dateTime in DDHHmmss format
 * @returns {string}
 */
export const getUTCDDHHmmss = () => {
  const now = new Date();
  const utcDate = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

  // Format UTC date in "DDHHmmss" format
  const formattedDate =
    utcDate.getUTCDate().toString().padStart(2, "0") +
    utcDate.getUTCHours().toString().padStart(2, "0") +
    utcDate.getUTCMinutes().toString().padStart(2, "0") +
    utcDate.getUTCSeconds().toString().padStart(2, "0");
  return formattedDate; // e.g. "17093345" for April 17, 2023 at 09:33:45 UTC
};
