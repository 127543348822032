var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-fade"}},[_c('div',{staticClass:"modal-overlay"},[_c('div',{class:[
        'modal-body-container',
        'manage-alias-modal',
        {
          modalPage: _vm.view.showModalAsPage,
        },
      ],attrs:{"role":"dialog","aria-labelledby":"modalTitle","aria-describedby":"modalDescription"}},[(!_vm.view.showModalAsPage)?_c('button',{staticClass:"btn-close",attrs:{"type":"button","aria-label":"Close modal"},on:{"click":_vm.close}},[_vm._v(" × ")]):_vm._e(),(_vm.view.showModalAsPage)?_c('widget-breadcrumb-nav',{attrs:{"modalName":"manage-alias","isModal":true},on:{"onNavClick":_vm.close}}):_vm._e(),_c('div',{staticClass:"modal-content-wrapper"},[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("emailAccounts.manageAlias.heading")))]),_c('p',{staticClass:"color-grey email-info alias-email-info"},[_vm._v(" "+_vm._s(_vm.$t(`emailAccounts.manageAlias.description`, [_vm.email]))+" ")]),_c('ul',{staticClass:"alias-listing"},[_c('li',{staticClass:"open"},[_c('div',{staticClass:"input-wrapper add-new-alias-wrapper"},[_c('span',{class:{
                    'disable-cursor': _vm.aliases.length === _vm.maxAliasCount,
                  }},[_c('span',{class:[
                      {
                        'disable-pointer':
                          _vm.aliases.length === _vm.maxAliasCount || _vm.addAliasShown,
                        'add-alias': !_vm.addAliasShown,
                      },
                    ],on:{"click":_vm.showAddAlias}},[_vm._v("+ "+_vm._s(_vm.$t("emailAccounts.manageAlias.addAlias")))])]),_c('span',[_vm._v(_vm._s(_vm.$t(`emailAccounts.manageAlias.maxAliasCount`, [ _vm.maxAliasCount, ])))])]),(_vm.addAliasShown)?_c('div',[_c('div',{staticClass:"input-wrapper"},[_c('div',{staticClass:"add-alias-data"},[_c('label',[_vm._v(_vm._s(_vm.$t("emailAccounts.manageAlias.displayName")))]),_c('div',{staticClass:"input-addon-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.displayName),expression:"displayName",modifiers:{"trim":true}}],staticClass:"input",attrs:{"type":"text","placeholder":_vm.$t(
                            'emailAccounts.manageAlias.displayNamePlaceholder'
                          )},domProps:{"value":(_vm.displayName)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.isNonEmptyDisplayNameAndAliasName && _vm.addAlias()},"input":function($event){if($event.target.composing)return;_vm.displayName=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"add-alias-data"},[_c('label',[_vm._v(_vm._s(_vm.$t("emailAccounts.manageAlias.emailAddress")))]),_c('div',{staticClass:"input-addon-wrapper alias-domain-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.aliasName),expression:"aliasName",modifiers:{"trim":true}}],staticClass:"input email-input",attrs:{"type":"text","placeholder":_vm.$t('emailAccounts.manageAlias.emailPlaceholder'),"autocomplete":"new-password"},domProps:{"value":(_vm.aliasName)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.isNonEmptyDisplayNameAndAliasName && _vm.addAlias()},"input":function($event){if($event.target.composing)return;_vm.aliasName=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"addon domain-name",attrs:{"id":"alias-domain-name"},on:{"mouseover":_vm.handleTooltip}},[_vm._v("@"+_vm._s(_vm.domainName)+" ")]),(_vm.showTooltip)?_c('b-popover',{attrs:{"target":"alias-domain-name","triggers":"hover","placement":"top","custom-class":"alias-domain-tooltip"}},[_vm._v(" @"+_vm._s(_vm.domainName)+" ")]):_vm._e()],1),_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.serverError)+" ")])])]),_c('div',{staticClass:"input-wrapper"},[_c('span',{class:{
                      'disable-cursor': !_vm.isNonEmptyDisplayNameAndAliasName,
                    }},[_c('button',{class:[
                        'button',
                        {
                          invalid: !_vm.isNonEmptyDisplayNameAndAliasName,
                          'show-disabled-spinner':
                            _vm.showButtonLoader && _vm.aliasActionType === 'add',
                          'disable-pointer': !_vm.isNonEmptyDisplayNameAndAliasName,
                        },
                      ],on:{"click":_vm.addAlias}},[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.manageAlias.createAlias"))+" ")])]),_c('button',{staticClass:"button button__transparent color-grey",on:{"click":_vm.hideAddAlias}},[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.manageAlias.cancel"))+" ")])])]):_vm._e()]),_vm._l((_vm.aliases),function(alias){return _c('li',{class:{
                dimmed:
                  (_vm.showActionForm && _vm.currentAlias !== alias.email) ||
                  _vm.addAliasShown,
                noHover: _vm.isEditOrDeleteFormOpenForAlias(alias),
                removeTopPadding: _vm.isEditOrDeleteFormOpenForAlias(alias),
              },attrs:{"value":alias},on:{"mouseenter":function($event){!_vm.showActionForm && _vm.onMouseEnter(alias)}}},[_c('span',{class:[
                  'alias-list-items',
                  {
                    dimmed: _vm.showActionForm,
                    hide: _vm.isEditOrDeleteFormOpenForAlias(alias),
                  },
                ]},[_vm._v(_vm._s((_vm.isEditOrDeleteFormOpenForAlias(alias) ? _vm.displayName : alias.displayName) + " " + _vm.openingTag + alias.email + _vm.closingTag))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showActionForm && !_vm.addAliasShown),expression:"!showActionForm && !addAliasShown"}]},[_c('span',{staticClass:"trash",on:{"click":function($event){return _vm.actionOnAliasData('delete', alias)}}}),_c('span',{staticClass:"edit",on:{"click":function($event){return _vm.actionOnAliasData('edit', alias)}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.isEditOrDeleteFormOpenForAlias(alias) &&
                  _vm.aliasActionType === 'delete'
                ),expression:"\n                  isEditOrDeleteFormOpenForAlias(alias) &&\n                  aliasActionType === 'delete'\n                "}],staticClass:"action-form-container removeTopMargin"},[_c('p',[_vm._v(" "+_vm._s((_vm.isEditOrDeleteFormOpenForAlias(alias) ? _vm.displayName : alias.displayName) + " " + _vm.openingTag + alias.email + _vm.closingTag)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.manageAlias.sureWantToDelete"))+" ")]),_c('div',{staticClass:"btn-wrapper"},[_c('button',{class:[
                      'button',
                      {
                        'show-disabled-spinner':
                          _vm.showButtonLoader && _vm.aliasActionType === 'delete',
                      },
                    ],on:{"click":function($event){return _vm.removeAlias(alias)}}},[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.manageAlias.delete"))+" ")]),_c('button',{staticClass:"button button__transparent color-grey",on:{"click":function($event){_vm.showActionForm = false}}},[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.manageAlias.no"))+" ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.isEditOrDeleteFormOpenForAlias(alias) &&
                  _vm.aliasActionType === 'edit'
                ),expression:"\n                  isEditOrDeleteFormOpenForAlias(alias) &&\n                  aliasActionType === 'edit'\n                "}],staticClass:"action-form-container removeTopMargin"},[_c('div',{staticClass:"alias-edit-form-flex-wrapper"},[_c('div',{staticClass:"input-addon-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.displayName),expression:"displayName",modifiers:{"trim":true}}],staticClass:"input",attrs:{"type":"text","placeholder":_vm.$t(
                          'emailAccounts.manageAlias.smallDisplayNamePlaceholder'
                        )},domProps:{"value":(_vm.displayName)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.displayName && _vm.editAlias(alias)},"input":function($event){if($event.target.composing)return;_vm.displayName=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"alias-email"},[_vm._v(" "+_vm._s(_vm.openingTag + alias.email + _vm.closingTag)+" ")])]),_c('div',{staticClass:"btn-wrapper"},[_c('span',{class:{
                      'disable-cursor': !_vm.displayName,
                    }},[_c('button',{staticClass:"button",class:{
                        invalid: !_vm.displayName,
                        'disable-pointer': !_vm.displayName,
                        'show-disabled-spinner': _vm.showButtonLoader,
                      },on:{"click":function($event){return _vm.editAlias(alias)}}},[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.manageAlias.save"))+" ")])]),_c('button',{staticClass:"button button__transparent color-grey",on:{"click":_vm.hideEditAlias}},[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.manageAlias.cancel"))+" ")])])])])})],2)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.addAliasShown && _vm.aliases && _vm.aliases.length === 0),expression:"!addAliasShown && aliases && aliases.length === 0"}],staticClass:"no-alias"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/no-alias.svg"),"alt":"no-alias"}})]),_c('p',{staticClass:"color-grey"},[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.manageAlias.noAlias"))+" ")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }