<template>
  <div class="hoverable-container">
    <div :class="className" class="bordered-container">
      <div class="titan-row">
        <div
          v-for="(key, index) in displayKeys"
          :key="key"
          class="titan-col"
          :style="getWidth(index)"
        >
          <slot :name="key" :extra="extra" :data="data[key]">{{
            data[key]
          }}</slot>
        </div>
      </div>
      <div class="titan-row">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      displayKeys: [],
    };
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    className: String,
    extra: {
      type: Object,
      default() {
        return {};
      },
    },
    keys: Array,
    columnWidths: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  created() {
    this.displayKeys = this.keys || Object.keys(this.data);
  },
  methods: {
    getWidth(index) {
      if (this.columnWidths[index]) {
        return {
          flexBasis: this.columnWidths[index],
        };
      }
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
.titan-col {
  display: flex;
  vertical-align: middle;
}
.hoverable-container {
  &:hover {
    background: var(--tableDataHoverCol);
  }
  &:last-child .bordered-container {
    border: none;
  }
}
.bordered-container {
  padding: 1.25em 10px;
  margin: 0 1em;
  border-bottom: 1px solid var(--lineSepaCol);
}
.titan-row {
  display: flex;
  justify-content: space-between;
  position: relative;
  &.selected {
    .col {
      background-color: rgba(242, 242, 242, 0.5);
      position: relative;
      user-select: none;
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 10px;
        background-color: white;
        width: 100%;
        left: 0;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
    }
  }
}
</style>
