<template>
  <div v-if="isAppLoadedInsideIframe" class="login-failure-screen">
    <img src="../../assets/purchaseError.svg" />
    <div>{{ $t("widgetApp.pageFlowItems.reload") }}</div>
  </div>
  <div v-else class="login-failure-screen">
    <img src="../../assets/purchaseError.svg" />
    <div>{{ $t("widgetApp.pageFlowItems.loginFailureInStandaloneMode") }}</div>
    <p class="m-4">
      <a href="/">{{ $t("widgetApp.pageFlowItems.backToLogin") }}</a>
    </p>
  </div>
</template>

<script>
import { sentryCaptureException } from "@/helpers/sentry";
import { measurePagePerformance } from "@/analytics/performance/pageTimings";
import { isAppLoadedInsideIframe } from "@/helpers";

export default {
  name: "LoginFailureScreen",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from?.name;
    });
  },
  data() {
    return {
      isAppLoadedInsideIframe: isAppLoadedInsideIframe(),
    };
  },
  mounted() {
    measurePagePerformance();
    sentryCaptureException(
      new Error("LoginFailureScreen"),
      { logType: "routing_issue" },
      {
        prevRoute: this.prevRoute,
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.login-failure-screen {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  div {
    padding-top: 50px;
  }
}
</style>
