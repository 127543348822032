<template>
  <div :class="['WidgetBreadcrumbNav', { marginFromTop: isModal }]">
    <span
      class="currentFlowHeader"
      v-for="(header, index) in currentFlowHeader"
      :key="index"
    >
      <span class="pageName" @click="changeRoute(header)">{{
        $t(`widgetApp.pageFlowItems.${header}`)
      }}</span>
      <span v-if="index !== currentFlowHeader.length - 1"> > </span>
    </span>
    <div class="modalFooterGap" v-if="isModal"></div>
  </div>
</template>

<script>
export default {
  name: "WidgetBreadcrumbNav",
  data() {
    return {
      currentFlowHeader: ["home"],
      mappedValuesOfCurrentFlow: {},
    };
  },
  props: {
    modalName: String,
    isModal: Boolean,
  },
  mounted() {
    this.updateCurrentFlowHeader();
  },
  computed: {},
  methods: {
    updateCurrentFlowHeader() {
      this.currentFlowHeader = [
        ...this.currentFlowHeader,
        this.$route.path.slice(1).split("_").join("-"),
      ];
      if (this.isModal && this.modalName) {
        this.currentFlowHeader.push(this.modalName);
      }
    },
    changeRoute(header) {
      this.$router.push("/" + header);
      this.$emit("onNavClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.WidgetBreadcrumbNav {
  .pageName {
    color: var(--widget-breadcrumb-active-text);
    cursor: pointer;
    &:last-child {
      color: var(--sectionTitleColor);
      pointer-events: none;
    }
  }
}
.marginFromTop {
  padding-top: 42px !important;
}
.modalFooterGap {
  padding-bottom: 30px;
}
</style>
