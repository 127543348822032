import { BILLING_FREQUENCY, BILLING_MONTHS } from "./const";
import { getAllProductsDetails } from "./product";

/**
 * lite => lite
 * pro => starter
 * premium => standard
 * ultra => max
 */
export const hasLitePlan = (plans) => {
  return !!plans.find((p) => p?.type?.startsWith("lite"));
};

export const LITE_PLAN_UNSUPPORTED_BILLING_CYCLES = [
  BILLING_FREQUENCY.MONTHLY,
  BILLING_FREQUENCY.QUARTERLY,
];

// incase of bundle has lite plan product then show `LITE_PLAN_UNSUPPORTED_BILLING_CYCLES` billing cycle only if current has one from this array
export const getSupportedBillingCyclesForNeo = (billingCycles, currentPlan) => {
  if (!billingCycles) {
    return [];
  }

  const bundleProductsPlan = getAllProductsDetails(currentPlan).map(
    (p) => p.planDetails
  );
  if (!hasLitePlan(bundleProductsPlan)) {
    return billingCycles;
  }

  return billingCycles.filter((billingCycle) => {
    if (LITE_PLAN_UNSUPPORTED_BILLING_CYCLES.indexOf(billingCycle) !== -1) {
      return billingCycle === currentPlan.billingCycle;
    }

    return true;
  });
};

export const getFreeDomainPeriodForNeo = (billingCycle) => {
  const freeDomainPeriods = [
    BILLING_FREQUENCY.YEARLY,
    BILLING_FREQUENCY.QUARTERLY,
    BILLING_FREQUENCY.MONTHLY,
  ];
  return (
    freeDomainPeriods.find(
      (period) => BILLING_MONTHS[billingCycle] >= BILLING_MONTHS[period]
    ) || BILLING_FREQUENCY.YEARLY
  );
};
