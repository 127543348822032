// function to read query param from url
export function getQueryParam(name, url) {
  if (!url) url = decodeURIComponent(location.href);
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(url);
  return results == null ? null : results[1];
}

export function replaceQueryParam(url, paramNameToSearch, valueToReplace) {
  // Create a regular expression to match the specified query parameter
  const regex = new RegExp(`([?&])${paramNameToSearch}=[^&]*`);

  // Use the regular expression to replace the parameter with the dummy value
  return url.replace(regex, `$1${paramNameToSearch}=${valueToReplace}`);
}

/**
 * function to get source from url
 * source is cached during application load
 * this basically query param added during application load (from external source like email, etc.)
 */
export const getAppSource = (() => {
  const source = getQueryParam("source") || sessionStorage.getItem("source");

  if (source) {
    // just to keep source available across page refresh
    sessionStorage.setItem("source", source);
  }

  // take cached value
  return () => source || "";
})();

/**
 * function to get source hook from url
 * source added during internal navigation is considered as internal navigation - source hook (sourceHook)
 */
export const getSourceHook = () => getQueryParam("source_hook");
