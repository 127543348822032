import { isNeoBrand } from ".";
import { openUrlInNewTab } from "./native";
import { isIOSDevice } from "./userAgent";

const APP_REDIRECTION_BASE_URL = "https://app.titan.email/links";
const APP_REDIRECTION_BASE_URL_NEO = "https://app.neo.space/links";
const IOS = "ios";
const ANDROID = "android";

export const openMailApp = () => {
  let device = ANDROID;
  if (isIOSDevice()) {
    device = IOS;
  }
  const baseUrl = isNeoBrand()
    ? APP_REDIRECTION_BASE_URL_NEO
    : APP_REDIRECTION_BASE_URL;
  const url = `${baseUrl}/${device}`;
  if (url) openUrlInNewTab(url);
};
